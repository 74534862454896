import actionCreatorFactory, { Action } from 'typescript-fsa'
import { Category, CategoryType } from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Categories')

/* An example action with more types that can be used to use typing with this
action elsewhere in the code.
 */
export interface CategorySelectedActionPayload {
	categoryId: string
	categoryTitle: string
	type: 'category' | 'subcategory' | 'subsubcategory' | 'subsubsubcategory'
}
export type CategorySelectedAction = Action<CategorySelectedActionPayload>
export const selectCategoryAction = actionCreator<CategorySelectedActionPayload>('SELECT_CATEGORY')

export interface LoadCategoriesPayload {
	categoryId: string | undefined
	type: CategoryType // load top level categories, subCategories or subSubCategories
	branchId?: number
}
export type LoadCategoriesAction = Action<LoadCategoriesPayload>
export const loadCategories = actionCreator.async<LoadCategoriesPayload, ReadonlyArray<Category>, Error>('LOAD_CATEGORIES')
