// import { CSSProperties } from 'react'
import { Theme } from '@mui/material'
// import { AutocompleteClassKey } from '@mui/material'

import colorProvider from '../../common-ui/utils/colorProvider'

// Type definitions for MUI Lab components
// https://github.com/mui-org/material-ui/issues/12164#issuecomment-564041219
// interface MuiOverrides extends Overrides {
// 	MuiAutocomplete?: Partial<Record<AutocompleteClassKey, CSSProperties | object>>
// }

// interface MuiTheme extends Theme {
// 	overrides?: MuiOverrides
// }

export const theme: Theme = {
	// @ts-ignore
	palette: {
		primary: {
			main: colorProvider.juicyGreen,
			light: colorProvider.juicyGreenLight,
			dark: colorProvider.juicyGreenDark,
			contrastText: colorProvider.white,
		},
		secondary: {
			main: colorProvider.starDust,
			dark: colorProvider.darkGrey,
			light: colorProvider.lightGrey,
			contrastText: colorProvider.black,
		},
		error: {
			main: colorProvider.alizarin,
			dark: colorProvider.fireEngineRed,
			light: colorProvider.azalea,
			contrastText: colorProvider.white,
		},
		info: {
			main: colorProvider.black,
			dark: colorProvider.black,
			light: colorProvider.pineTree,
			contrastText: colorProvider.white,
		},
	},
	// @ts-ignore
	typography: {
		fontFamily: '"CooperHewitt-Book", "Arial", sans-serif',
	},
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					boxShadow: 'none',
					borderRadius: 5,
					color: colorProvider.black,
					backgroundColor: colorProvider.white,
					'&:hover, &$focused': {
						backgroundColor: colorProvider.white,
					},
				},
			}
		},
		MuiInput: {
			styleOverrides: {
				input: {
					padding: 20,
					borderRadius: 5,
					fontSize: '14px',
					lineHeight: '18px',
				},
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					fontSize: '14px',
					lineHeight: '18px',
					'&$disabled': {
						backgroundColor: colorProvider.whiteSmoke,
						color: colorProvider.black,
					},
				},
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: 5,
				},
				input: {
					color: colorProvider.black,
					// paddingLeft: 20,
				},
				multiline: {
					padding: 20,
				},
			}
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					backgroundColor: 'transparent',
					'&:hover, &$focused': {
						backgroundColor: 'transparent',
					},
				},
				input: {
					backgroundColor: colorProvider.white,
					'&:hover, &$focused': {
						backgroundColor: colorProvider.white,
					},
					borderRadius: 5,
					fontSize: 16,
					paddingLeft: 20,
				},
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: colorProvider.lightGrey,
				},
				filled: {
					fontSize: 14,
					paddingLeft: 10,
				},
			}
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontSize: '14px',
					borderRadius: 4,
					'&$focused': {
						color: colorProvider.starDust,
					},
					'&[data-shrink=true]': {
						fontFamily: '"CooperHewitt-SemiBold", "Arial", sans-serif',
					},
				},
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					boxShadow: 'none',
					textTransform: 'none',
					fontSize: '16px',
					lineHeight: '20px',
					padding: '6px 12px',
					height: '50px',
					'&:hover, &:active': {
						boxShadow: 'none',
					},
					fontFamily: '"CooperHewitt-Bold", "Arial", sans-serif',
				},
				outlined: {
					color: colorProvider.black,
					borderColor: colorProvider.black,
					backgroundColor: colorProvider.white,
					'&:hover': {
						borderColor: colorProvider.black,
						backgroundColor: colorProvider.black,
						color: colorProvider.white,
						'@media (hover: none)': {
							borderColor: colorProvider.black,
							backgroundColor: colorProvider.black,
							color: colorProvider.white,
						},
					},
				},
				contained: {
					boxShadow: 'none',
					'&:hover, &:active': {
						boxShadow: 'none',
					},
				},
			}
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					'&$checked': {
						color: colorProvider.juicyGreen,
					},
				},
			}
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					// backgroundColor: colorProvider.black, // now uses border color
				},
			}
		},
		MuiBreadcrumbs: {
			styleOverrides: {
				root: {
					marginTop: 30,
					fontSize: 11,
				},
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					marginLeft: 23,
					fontFamily: '"CooperHewitt-Bold", "Arial", sans-serif',
					fontSize: 11,
					color: colorProvider.juicyGreen,
					'&$error': {
						color: colorProvider.alizarin,
					},
				},
			}
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					textAlign: 'center',
					fontSize: 20,
					fontFamily: '"CooperHewitt-SemiBold", "Arial", sans-serif',
				},
			}
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					width: '-webkit-fill-available'
				}
			}
		},
		MuiDialogContentText: {
			styleOverrides: {
				root: {
					fontSize: 12,
					lineHeight: '15px',
					fontFamily: '"CooperHewitt-SemiBold", "Arial", sans-serif',
					fontStyle: 'italic',
					color: colorProvider.delta,
				},
			}
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: colorProvider.black,
					'&:hover': {
						backgroundColor: 'transparent',
					},
				},
				colorPrimary: {
					'&$checked': {
						'&:hover': {
							backgroundColor: 'transparent',
						},
					},
				},
				colorSecondary: {
					'&$checked': {
						'&:hover': {
							backgroundColor: 'transparent',
						},
					},
				},
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontFamily: '"CooperHewitt-Book", "Arial", sans-serif',
				},
			}
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					borderBottom: `2px solid ${colorProvider.black}`,
					color: colorProvider.black,
					fontFamily: '"CooperHewitt-Medium", "Arial", sans-serif',
					padding: '10px 16px',
				},
				body: {
					fontFamily: '"CooperHewitt-SemiBold", "Arial", sans-serif',
					fontSize: 16,
				},
			}
		},
		MuiCircularProgress: {
			styleOverrides: {
				root: {
					width: 24,
					height: 24,
				},
			}
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					marginRight: 0,
				},
				label: {
					fontFamily: '"CooperHewitt-Book", "Arial", sans-serif',
					fontSize: 14,
				},
			}
		},
		MuiSnackbar: {
			styleOverrides: {
				root: {
					fontFamily: '"CooperHewitt-Book", "Arial", sans-serif',
					fontSize: 12,
				},
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontFamily: '"CooperHewitt-Book", "Arial", sans-serif',
					fontSize: '14px',
					lineHeight: '18px',
					color: colorProvider.starDust,
					'&:hover': {
						backgroundColor: colorProvider.juicyGreenLight,
					},
				},
			}
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					'&:focus': {
						backgroundColor: 'transparent',
					},
				},
				outlined: {
					fontFamily: '"CooperHewitt-SemiBold", "Arial", sans-serif',
					fontSize: '14px',
					lineHeight: '18px',
				},
			}
		},
		MuiListSubheader: {
			styleOverrides: {
				root: {
					fontFamily: '"CooperHewitt-SemiBold", "Arial", sans-serif',
					fontSize: 12,
					textTransform: 'uppercase',
				},
			}
		},
		MuiPaper: {
			styleOverrides: {
				outlined: {
					borderColor: colorProvider.black,
				},
			}
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					'&$selected': {
						backgroundColor: colorProvider.juicyGreen,
						color: colorProvider.white,
						'&:hover': {
							backgroundColor: colorProvider.juicyGreenLight,
							color: colorProvider.lightGrey,
						},
					},
				},
			}
		},
		MuiAutocomplete: {
			styleOverrides: {
				option: {
					fontFamily: '"CooperHewitt-Book", "Arial", sans-serif',
					fontSize: 16,
					color: colorProvider.starDust,
					'&[aria-selected=true]': {
						backgroundColor: colorProvider.white,
					},
					'&:hover, &[data-focus=true]': {
						backgroundColor: colorProvider.juicyGreenLight,
					},
				},
			}
		},
		MuiStepper: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			}
		},
		MuiStepLabel: {
			styleOverrides: {
				label: {
					fontFamily: '"CooperHewitt-SemiBold", "Arial", sans-serif',
					'&.MuiStepLabel-label': {
						marginTop: 10,
						color: colorProvider.black,
					}
				},
			}
		},
		MuiStepConnector: {
			styleOverrides: {
				alternativeLabel: {
					top: '30%',
				},
				root: {
					'&.Mui-active': {
						'& $line': {
							borderColor: colorProvider.black,
						},
					},
					'&.Mui-completed': {
						'& $line': {
							borderColor: colorProvider.black,
						},
					}
				},
				line: {
					borderColor: colorProvider.black,
				},
				lineHorizontal: {
					borderTopWidth: 2,
				},
			}
		},
	},
}