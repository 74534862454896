import { SagaIterator } from 'redux-saga'
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { AccountsEmailOptIn, GetAccountPrincipalEmailRequest, NewAccountRegistration } from 'typescript-fetch-api'

import * as actions from './actions'
import * as rootActions from '../root/actions'
import * as profileActions from '../profile/actions'
import { ApiErrorMessage, getUserApi } from '../api'
import { authTokenSelector, userIdSelector } from '../auth/selectors'
import { handleUpdateUserPassword } from '../profile/sagas'
import { callApi } from '../api/functions'
import { UserAccount } from './types'

/**
 * When redux is ready we want to load the accounts.
 */
function* handleLoadAccountsOnReady(): SagaIterator {
	// check user is logged in
	const isLoggedIn: boolean = (yield select(authTokenSelector)) !== undefined
	if (isLoggedIn) {
		yield put(actions.getAccounts.started({}))
	}
}

/**
 * Performs a get accounts api call and calls the appropriate actions on the getAccounts action
 * @param action The action containing the get accounts payload
 */
export function* handleGetAccounts(action: actions.GetAccountsRequestAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.getAccounts.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		// @ts-ignore API
		yield call(callApi, action, actions.getAccounts, () => {
			return getUserApi().getAccounts()
				.then(response => ({
					...response,
					accounts: response.accounts?.filter(account => !!account.customerId),
				}))
		})
	}
}

/**
 * Gets fired whenever an edit account action is done.
 * Reloading of accounts should only happen when the edited account matches the current user's logged in account.
 * @param action The action containing the edit account success payload
 */
function* handleReloadUserAccounts(action: actions.EditAccountRequestSuccessAction): SagaIterator {
	const userId = yield select(userIdSelector)
	if (userId) {
		const hasEditedOwnAccount: boolean = userId === action.payload.result.userId
		if (hasEditedOwnAccount) {
			yield put(actions.getAccounts.started({}))
		}
	}
}

/**
 * Fetch accounts by account id. This is an admin endpoint used to manage users/tradingAccounts in a business.
 * @param action The action containing the get admin accounts payload
 */
export function* handleGetAdminAccounts(action: actions.GetAdminAccountsRequestAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.getAdminAccounts.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.getAdminAccounts,
			(payload: actions.GetAdminAccountsPayload) => {
				return getUserApi().getCustomerAccounts({ customerId: payload.accountId, page: 0, pageSize: 1000 })
			})
	}
}

/**
 * Peforms an add account api call and calls the appropriate actions on the addAccount action
 * @param action The action containing the add account payload
 */
export function* handleAddAccount(action: actions.AddAccountRequestAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.addAccount,
		(payload: actions.AddAccountRequestPayload) => {
			const newAccountRegistration: NewAccountRegistration = {
				id: payload.customerId,
				name: payload.nickname,
				branchId: payload.branchId,
				accountRegistrationType: payload.accountRegistrationType,
			}
			return getUserApi().addAccount({ newAccountRegistration }).catch((error) => {
				let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
				if (error instanceof Response) {
					switch (error.status) {
						case 406:
							errorText = 'Invalid account number'
							break
						case 409:
							errorText = 'Account ID already exists'
							break
						default:
							// @ts-ignore
							if (error.error_description) {
								// @ts-ignore
								errorText = error.error_description
							}
					}
				}
				throw new Error(errorText)
			})

		})
}

/**
 * Peforms an edit account api call and calls the appropriate actions on the editAccount action
 * @param action The action containing the edit account payload
 */
export function* handleEditAccount(action: actions.EditAccountRequestAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.editAccount,
		(payload: actions.EditAccountRequestPayload) => {
			return getUserApi().updateTradingAccount({ account: payload })
				.catch((error) => {
					let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
					if (error instanceof Response) {
						switch (error.status) {
							case 403:
								errorText = 'Not authorized to edit this account'
								break
							case 406:
								// map response body to get specific error message
								return error.json().then(body => {
									const message = body.message ? body.message : errorText
									throw new Error(message)
								})
							case 409:
								errorText = 'User with the same credentials already exists'
								break
							default:
								// @ts-ignore
								if (error.error_description) {
									// @ts-ignore
									errorText = error.error_description
								}
						}
					}
					throw new Error(errorText)
				})
		})
}

/**
 * Peforms a create account api call and calls the appropriate actions on the createAccount action
 * @param action The action containing the create account payload
 */
export function* handleCreateAccount(action: actions.CreateAccountRequestAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.createAccount,
		(payload: actions.CreateAccountRequestPayload) => {
			return getUserApi().createTradingAccount({ createAccountRequest: payload }).catch((error) => {
				let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
				if (error instanceof Response) {
					switch (error.status) {
						case 403:
							errorText = 'Not authorized to create this account'
							break
						case 406:
							// map response body to get specific error message
							return error.json().then(body => {
								const message = body.message ? body.message : errorText
								throw new Error(message)
							})
						default:
							// @ts-ignore
							if (error.error_description) {
								// @ts-ignore
								errorText = error.error_description
							}
					}
				}
				throw new Error(errorText)
			})
		})
}

/**
 * Peforms a delete account api call and calls the appropriate actions on the deleteAccount action
 * @param action The action containing the account to delete
 */
export function* handleDeleteAccount(action: actions.DeleteAccountRequestAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.deleteAccount,
		(payload: UserAccount) => {
			return getUserApi().deleteUserTradingAccount({ customerId: payload.id }).catch((error) => {
				let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
				if (error instanceof Response) {
					switch (error.status) {
						case 403:
							errorText = 'Not authorized to delete this account'
							break
						case 406:
							errorText = 'Invalid account details'
							break
						default:
							// @ts-ignore
							if (error.error_description) {
								// @ts-ignore
								errorText = error.error_description
							}
					}
				}
				throw new Error(errorText)
			})
		})
}

/**
 * Performs an update email opt-in account api call
 * @param action The action containing the list of accounts to update the email opt-in flag with
 */
function* handleUpdateAccountsEmailOptIn(action: actions.UpdateAccountsEmailOptInAction): SagaIterator {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		actions.updateAccountsEmailOptIn,
		(payload: AccountsEmailOptIn, options: RequestInit) => {
			return getUserApi().updateAccountsEmailOptIn({ accountsEmailOptIn: payload }, options)
		},
		false
	)
}

/**
 * Reloads the user's accounts after successfully updating the email opt-in flags on selected accounts.
 */
function* handleReloadAccountsOnUpdateAccountsEmailOptInSuccess(): SagaIterator {
	yield put(actions.getAccounts.started({}))
}

/**
 * Performs a chain of calls to allow the user to update their account and password.
 * This is used specifically in cases where a user has updated their login ID.
 * @param action The action containing the updated account details
 */
function* handleEditLoginId(action: actions.EditLoginIdAction): SagaIterator {
	const { password, ...rest } = action.payload
	const editAccountPayload = { ...rest }
	try {
		if (password) {
			// perform the password update request first so the token is still valid
			const updateUserPasswordAction: profileActions.UpdateUserPasswordAction = {
				type: profileActions.updateUserPassword.type,
				payload: password,
			}
			yield call(handleUpdateUserPassword, updateUserPasswordAction)
		}

		// on successful edit, this should trigger `handleReloadUserAccounts`.
		// the current tokens are no longer valid, so an attempted refresh will fail and redirect the user to login.
		yield put(actions.editAccount.started(editAccountPayload))
	} catch (error) {
		yield put(actions.editAccount.failed({ params: editAccountPayload, error: error as Error }))
	}
}

/**
 * Waits for 1s before performing the `getAccountPrincipalEmail` call.
 * If the user types something during this period, we'll get more `getAccountPrincipalEmail.started` actions.
 * Since `handleGetAccountPrincipalEmail` will still be blocked in the delay call, it'll be cancelled by `takeLatest` before it can start performing its logic.
 * https://redux-saga.js.org/docs/recipes/#debouncing
 * 
 * @param action the action containing the customer id to fetch the principal email for
 */
function* handleGetAccountPrincipalEmail(action: actions.GetAccountPrincipalEmailAction): SagaIterator {
	// debounce by 1s
	yield delay(1000)

	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		actions.getAccountPrincipalEmail,
		(payload: GetAccountPrincipalEmailRequest, options: RequestInit) => {
			return getUserApi().getAccountPrincipalEmail(payload, options)
				.then(response => response.obfuscatedEmail)
		},
	)
}

export default function* (): SagaIterator {
	yield takeEvery(rootActions.readyAction, handleLoadAccountsOnReady)
	yield takeEvery(actions.getAccounts.started, handleGetAccounts)
	yield takeEvery(actions.getAdminAccounts.started, handleGetAdminAccounts)
	yield takeEvery(actions.addAccount.started, handleAddAccount)
	yield takeEvery(actions.editAccount.started, handleEditAccount)
	yield takeEvery(actions.editAccount.done, handleReloadUserAccounts)
	yield takeEvery(actions.createAccount.started, handleCreateAccount)
	yield takeEvery(actions.deleteAccount.started, handleDeleteAccount)
	yield takeEvery(actions.updateAccountsEmailOptIn.started, handleUpdateAccountsEmailOptIn)
	yield takeEvery(actions.updateAccountsEmailOptIn.done, handleReloadAccountsOnUpdateAccountsEmailOptInSuccess)
	yield takeEvery(actions.editLoginId, handleEditLoginId)
	yield takeLatest(actions.getAccountPrincipalEmail.started, handleGetAccountPrincipalEmail)
}