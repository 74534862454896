import actionCreatorFactory, { Action } from 'typescript-fsa'
import { ApproveAccountRequest } from 'typescript-fetch-api'

import { CategorySelectedActionPayload } from '../../common/categories/actions'
import { Category, CategoryType } from '../../common/categories/types'
import { DrawerType } from './types'

const actionCreator = actionCreatorFactory('Platform')

export const updatePaginationSize = actionCreator<number>('UPDATE_PAGINATION_SIZE')

export const hideBrowserBanner = actionCreator<boolean>('HIDE_BROWSER_BANNER')

export type RegisterNotificationTokenAction = Action<string>
export const registerNotificationToken = actionCreator.async<string, Response, Error>('REGISTER_NOTIFICATION_TOKEN')

export type DeregisterNotificationTokenAction = Action<string>
export const deregisterNotificationToken = actionCreator.async<string, Response, Error>('DEREGISTER_NOTIFICATION_TOKEN')

export const updateLastDisplayedFeatureDialog = actionCreator.async<void, string, Error>('UPDATE_LAST_DISPLAYED_FEATURE_DIALOG')

export type TabOptions = 'home' | 'categories' | 'admin' | 'lists' | 'signin' | 'register' | 'financials' | 'profile'
export interface NavigateToTabActionPayload {
    tab: TabOptions
    userId?: number // used when displaying the user profile for a trade user
}
export type NavigateToTabAction = Action<NavigateToTabActionPayload>
export const navigateToTab = actionCreator<NavigateToTabActionPayload>('ON_TAB_SELECTED')

export type OrdersTabOptions = 'current-orders'
export interface NavigateToOrdersTabActionPayload {
    tab?: OrdersTabOptions
}
export type NavigateToOrdersTabAction = Action<NavigateToOrdersTabActionPayload>
export const navigateToOrdersTab = actionCreator<NavigateToOrdersTabActionPayload>('NAVIGATE_TO_ORDERS_TAB')

export type NavigateToCategoryFromProductBreadcrumbsAction = Action<CategorySelectedActionPayload>
export const navigateToCategoryFromProductBreadcrumbs = actionCreator<CategorySelectedActionPayload>('NAVIGATE_TO_CATEGORY_FROM_PRODUCT_BREADCRUMBS')

export type ToggleDrawerAction = Action<DrawerType | undefined>
export const toggleDrawer = actionCreator<DrawerType | undefined>('TOGGLE_DRAWER')

export interface LoadSubcategoriesPayload {
    categoryId: string
    type: CategoryType // load subCategories or subSubCategories
}
export type LoadSubcategoriesForMenuAction = Action<LoadSubcategoriesPayload>
export const loadSubcategoriesForMenu = actionCreator.async<LoadSubcategoriesPayload, ReadonlyArray<Category>, Error>('LOAD_SUBCATEGORIES_FOR_MENU')

export type ApproveAccountAction = Action<ApproveAccountRequest>
export const approveAccount = actionCreator.async<ApproveAccountRequest, Response, Error>('APPROVE_ACCOUNT')

// location change action is dispatched by us when the url changes, for analytics page view reporting
export interface LocationChangePayload {
    pathname: string
    search: string
}
export type LocationChangeAction = Action<LocationChangePayload>
export const locationChange = actionCreator<LocationChangePayload>('LOCATION_CHANGE')