import { SagaIterator } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'

import { navigateToSignIn } from '../../common/myorders/actions'
import * as NavigationManager from '../navigation/NavigationManager'

export function handleNavigateToSignIn() {
	NavigationManager.navigateToSignIn()
}

export default function* (): SagaIterator {
	yield takeEvery(navigateToSignIn, handleNavigateToSignIn)
}