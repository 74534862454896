import { UserAccount } from '../../common/accounts/types'
import { RootStoreState } from '../../common/root'
import { StoreState as PlatformStoreState } from './reducer'

export type AccountSelectType = { value: UserAccount, label: string }

export interface Breadcrumb {
	title: string
	isClickable?: boolean
	type?: 'home' | 'products' | 'product' | 'orders' | 'order' | 'quotes' | 'quote' | 'financials' | 'lists' | 'admin' | 'account-list'
}

export interface WebStoreState extends RootStoreState {
	readonly platform: PlatformStoreState
}

export type PageSize = { value: number, label: string }
export const PAGE_SIZES: PageSize[] = [
	{ value: 12, label: '12' },
	{ value: 24, label: '24' },
	{ value: 36, label: '36' },
	{ value: 48, label: '48' },
	{ value: 60, label: '60' },
]

export enum RouteNames {
	HOME = 'Home',
	SEARCH = 'Search',
	CHANGE_PASSWORD = 'Change Password',
	ADD_ACCOUNT = 'Add Account',
	CUSTOMER_ORDERS = 'Customer Orders',
	CUSTOMER_QUOTES = 'Customer Quotes',
	PRODUCTS_LIST = 'Products List',
	PRODUCT = 'Product',
	CHECKOUT = 'Checkout',
	CHECKOUT_ORDER_DETAILS = 'Checkout Order Details',
	CHECKOUT_CONFIRM = 'Checkout Confirm',
	CHECKOUT_COMPLETE = 'Checkout Complete',
	REGIONS = 'Regions',
	LISTS = 'Lists',
	ADD_LIST = 'Add List',
	CONTACT_US = 'Contact Us',
	SIGN_IN = 'Sign In',
	REGISTER = 'Register',
	CONFIRM_EMAIL = 'Confirm Email',
	CONFIRM_PHONE = 'Confirm Phone',
	CONFIRM_RESET_PASSWORD = 'Confirm Reset Password',
	RESET_PASSWORD = 'Reset Password',
	VERIFY_ACCOUNT = 'Verify Account',
	ADMIN_USER_LIST = 'Admin User List',
	ADMIN_ADD_USER = 'Admin Add User',
	ADMIN_EDIT_USER = 'Admin Edit User',
	INVOICES = 'Invoices',
	STATEMENTS = 'Statements',
	ACCOUNT_BALANCE = 'Account Balance',
	PROFILE = 'Profile',
	IMPERSONATE = 'Impersonate',
}

// we exclude this from the route list since we are setting the path comparison manually on the analytics saga
type ExcludedRoutes = RouteNames.HOME | RouteNames.CHECKOUT_ORDER_DETAILS | RouteNames.CHECKOUT_CONFIRM | RouteNames.CHECKOUT_COMPLETE

// A list of routes to help identify which screen the user is on
export const RouteList: Record<Exclude<RouteNames, ExcludedRoutes>, string> = {
	[RouteNames.SEARCH]: '/search',
	[RouteNames.CHANGE_PASSWORD]: '/changePassword',
	[RouteNames.ADD_ACCOUNT]: '/accounts/add',
	[RouteNames.CUSTOMER_ORDERS]: '/orders/customer',
	[RouteNames.CUSTOMER_QUOTES]: '/quotes/branch',
	[RouteNames.PRODUCTS_LIST]: '/products',
	[RouteNames.PRODUCT]: '/product',
	[RouteNames.CHECKOUT]: '/checkout',
	[RouteNames.REGIONS]: '/regions',
	[RouteNames.LISTS]: '/lists',
	[RouteNames.ADD_LIST]: '/lists/add',
	[RouteNames.CONTACT_US]: '/contact',
	[RouteNames.SIGN_IN]: '/signin',
	[RouteNames.REGISTER]: '/register',
	[RouteNames.CONFIRM_EMAIL]: '/register/comfirmEmail',
	[RouteNames.CONFIRM_PHONE]: '/register/confirmPhone',
	[RouteNames.CONFIRM_RESET_PASSWORD]: '/register/confirmResetPassword',
	[RouteNames.RESET_PASSWORD]: '/resetPassword',
	[RouteNames.VERIFY_ACCOUNT]: '/verifyAccount',
	[RouteNames.ADMIN_USER_LIST]: '/admin',
	[RouteNames.ADMIN_ADD_USER]: '/admin/add',
	[RouteNames.ADMIN_EDIT_USER]: '/admin/edit',
	[RouteNames.INVOICES]: '/financials/invoices',
	[RouteNames.STATEMENTS]: '/financials/statements',
	[RouteNames.ACCOUNT_BALANCE]: '/financials/account-balance',
	[RouteNames.PROFILE]: '/profile',
	[RouteNames.IMPERSONATE]: '/impersonate',
}

export interface SubheaderMenuProps {
	anchorElement: HTMLElement | null
	isSmallScreen?: boolean
	onClickAway: () => void
	onCloseSubmenu?: () => void
}

export type FileExportOptionType<T> = { value: T, label: string, combined?: boolean }

export interface NewFeatureDialogProps {
	isUnseen: boolean
	onRemoveFromUnseenList: () => void
}

export enum ChangeBranch {
	AVAILABILITY = 'AVAILABILITY',
	SELECT_BRANCH = 'SELECT_BRANCH',
}

export enum DrawerType {
	BRANCH, ACCOUNT, PROFILE, MENU, CART
}

export type CustomOption<T> = {
	label: string
	value: T
}