import { SagaIterator } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'

import { navigateToPrivacyPolicy, navigateToSignIn, navigateToTermsAndConditions } from '../../common/profile/actions'
import { handleNavigateToSignIn } from '../auth/sagas'
import * as NavigationManager from '../navigation/NavigationManager'

function handleNavigateToPrivacyPolicy() {
	NavigationManager.navigateToPrivacyPolicy()
}

function handleNavigateToTermsAndConditions() {
	NavigationManager.navigateToTermsAndConditions()
}

export default function* (): SagaIterator {
	yield takeEvery(navigateToPrivacyPolicy, handleNavigateToPrivacyPolicy)
	yield takeEvery(navigateToTermsAndConditions, handleNavigateToTermsAndConditions)
	yield takeEvery(navigateToSignIn, handleNavigateToSignIn)
}