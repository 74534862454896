import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { BranchProductDetail, ProductPrices, SearchFilter } from 'typescript-fetch-api'

import * as actions from './actions'
import * as authActions from '../auth/actions'
import { PlatformProduct } from '../../modules/platform'
import { TotalProductsCount } from './types'
import { updateTotalProductsCount, updateTotalProductsCountLoadingState } from './functions'
import { getPlatformSupportImplementation } from '../platform'

export interface StoreState {
	readonly products: ReadonlyArray<PlatformProduct>
	readonly productPrices: ProductPrices[]
	readonly fetchingProductPrices: boolean
	readonly productStockCounts: BranchProductDetail[]
	readonly productStockCountBatches?: number
	readonly fetchingProductStockCounts: boolean
	readonly productCategory?: string
	readonly filters: SearchFilter[]
	readonly savedFilter: actions.ProductFilter | undefined
	readonly totalProductPagesCount?: number
	readonly totalProductsCount?: number
	readonly loadingMessage?: string
	readonly error?: Error
	readonly productsCount: TotalProductsCount
}

const INITIAL_STATE: StoreState = {
	products: [] as ReadonlyArray<PlatformProduct>,
	productPrices: [],
	fetchingProductPrices: false,
	productStockCounts: [],
	productStockCountBatches: undefined,
	fetchingProductStockCounts: false,
	productCategory: undefined,
	filters: [],
	savedFilter: undefined,
	totalProductPagesCount: undefined,
	totalProductsCount: undefined,
	loadingMessage: undefined,
	error: undefined,
	productsCount: {
		allProductsCount: undefined,
		branchProductsCount: undefined,
		allProductsParams: undefined,
		branchProductsParams: undefined,
		loadingAllProductsCount: false,
		loadingBranchProductsCount: false,
	},
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

/** Reducer function for the exampleAction that returns a new state using an implicit return. */
reducer.case(actions.loadProducts.started, (state, payload): StoreState => {
	const loadingMessage: string = 'Products loading...'
	// only clear the products in cases where the category ids are different so we keep a copy of the previously loaded products
	if (payload.categoryId !== state.productCategory) {
		return {
			...INITIAL_STATE,
			productCategory: payload.categoryId, // todo beware that clearing the products list here can make empty list appear for split second after setting new supplier filters!
			loadingMessage,
		}
	}
	return {
		...state,
		loadingMessage,
		error: INITIAL_STATE.error,
	}
})

/** Reducer function for examplePrimitiveAction that returns a new state using an explicit return. */
reducer.case(actions.loadProducts.done, (state, payload): StoreState => ({
	...state, products: payload.result.products || [], totalProductPagesCount: payload.result.totalProductPagesCount, totalProductsCount: payload.result.totalProductsCount, loadingMessage: undefined
}))

/** Reducer function for examplePrimitiveAction that returns a new state using an explicit return. */
reducer.case(actions.loadProducts.failed, (state, { error }): StoreState => ({
	...state, error, totalProductPagesCount: undefined, totalProductsCount: undefined, loadingMessage: undefined
}))

reducer.case(actions.loadProductPrices.started, (state): StoreState => ({
	...state, fetchingProductPrices: true,
}))
reducer.case(actions.loadProductPrices.done, (state, { params, result }): StoreState => {
	const productPrices = result.prices || []

	// loop through products and append pricing (on web only)
	const products: ReadonlyArray<PlatformProduct> = getPlatformSupportImplementation().appendPricingToProducts ? getPlatformSupportImplementation().appendPricingToProducts!(state.products, productPrices) : state.products

	if (params.appendToList) {
		return {
			...state,
			productPrices: [...state.productPrices, ...productPrices],
			fetchingProductPrices: false,
			products,
		}
	}
	return {
		...state,
		productPrices,
		fetchingProductPrices: false,
		products,
	}
})
reducer.case(actions.loadProductPrices.failed, (state): StoreState => ({
	...state, fetchingProductPrices: false,
}))

reducer.case(actions.getProductsAvailability.started, (state, { batchCount }): StoreState => ({
	...state, productStockCountBatches: batchCount, fetchingProductStockCounts: true,
}))
reducer.case(actions.getProductsAvailability.done, (state, { params, result }): StoreState => {
	const productStockCounts = result.products || []

	// loop through products and append availability (on web only)
	const products: ReadonlyArray<PlatformProduct> = getPlatformSupportImplementation().appendAvailabilityToProducts ? getPlatformSupportImplementation().appendAvailabilityToProducts!(state.products, productStockCounts) : state.products

	if (params.batchCount !== undefined) {
		let productStockCountBatches
		// check if still going through other batches after a request has finished
		if (state.productStockCountBatches && state.productStockCountBatches > 0) {
			const updatedBatchCount = state.productStockCountBatches - 1
			if (updatedBatchCount > 0) {
				productStockCountBatches = updatedBatchCount
			}
		}
		return {
			...state,
			productStockCounts: params.clearOldStockCounts ? productStockCounts : [...state.productStockCounts, ...productStockCounts],
			productStockCountBatches,
			fetchingProductStockCounts: false,
			products,
		}
	}
	return {
		...state,
		productStockCounts,
		fetchingProductStockCounts: false,
		products,
	}
})
reducer.case(actions.getProductsAvailability.failed, (state, { params }): StoreState => {
	if (params.batchCount !== undefined) {
		let productStockCountBatches
		// check if still going through other batches after a request has finished
		if (state.productStockCountBatches && state.productStockCountBatches > 0) {
			const updatedBatchCount = state.productStockCountBatches - 1
			if (updatedBatchCount > 0) {
				productStockCountBatches = updatedBatchCount
			}
		}
		return {
			...state,
			productStockCountBatches,
			fetchingProductStockCounts: false,
		}
	}
	return { ...state, fetchingProductStockCounts: false, }
})

reducer.case(actions.clearSelectedCategory, (state): StoreState => ({
	...state, productCategory: undefined // clear selected category so that if user taps Home tab we only reload products if user viewing the ProductsList screen
}))

reducer.case(actions.loadFiltersForProducts.started, (state): StoreState => ({
	...state, filters: INITIAL_STATE.filters,
}))

reducer.case(actions.loadFiltersForProducts.done, (state, { result }): StoreState => ({
	...state, filters: result,
}))

reducer.case(actions.saveProductsFilter, (state, payload): StoreState => ({
	...state, savedFilter: payload,
}))

// The user has been logged out remove products from the state.
// This is particularly important for web so product prices will be reset depending on the type of permissions a user's account has.
reducer.case(authActions.loggedOut, (): StoreState => {
	return INITIAL_STATE
})

reducer
	.case(actions.loadProductsCount.done, (state, { params, result }): StoreState => {
		if (params.categoryId) {
			const updatedResult = updateTotalProductsCount(params, result, state.productsCount)
			if (updatedResult) {
				return ({
					...state, productsCount: updatedResult,
				})
			}
		}
		return state
	})
	.case(actions.loadProductsCount.started, (state, params): StoreState => {
		if (params.categoryId) {
			const updatedResult = updateTotalProductsCountLoadingState(params, true, state.productsCount)
			if (updatedResult) {
				return ({
					...state, productsCount: updatedResult,
				})
			}
		}
		return state
	})
	.case(actions.loadProductsCount.failed, (state, { params }): StoreState => {
		if (params.categoryId) {
			const updatedResult = updateTotalProductsCountLoadingState(params, false, state.productsCount)
			if (updatedResult) {
				return ({
					...state, productsCount: updatedResult,
				})
			}
		}
		return state
	})

reducer.case(actions.setLoadingProductsState, (state): StoreState => ({
	...state,
	products: INITIAL_STATE.products,
	totalProductPagesCount: INITIAL_STATE.totalProductPagesCount,
	totalProductsCount: INITIAL_STATE.totalProductsCount,
	loadingMessage: 'Products loading...',
	error: INITIAL_STATE.error,
}))