import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { ClosureType, ProductPrices, CreateOrderDespatchMethodEnum, OrderDespatchMethodEnum, ClickAndCollectTimeslot, ProductPriceSummaryResponse } from 'typescript-fetch-api'

import * as actions from './actions'
import * as cartActions from '../cart/actions'
import * as authActions from '../auth/actions'
import { OrderItem } from './types'
import { AccountStatus, UserAccount } from '../accounts/types'
import { readyAction } from '../root/actions'
import { getPlatformSupportImplementation } from '../platform'
import { OrderInputs } from '../cart/types'

export interface StoreState {
	readonly orderItems: OrderItem[]
	readonly orderNumber: string,
	readonly name: string | undefined,
	readonly email: string,
	readonly mobileNumber: string,
	readonly [OrderInputs.SUBURB]: string,
	readonly [OrderInputs.ADDRESS]: string,
	readonly [OrderInputs.CITY]: string,
	readonly [OrderInputs.STATE]: string,
	readonly [OrderInputs.POSTCODE]: string,
	readonly branchName: string | undefined,
	readonly branchId: string | undefined,
	readonly branchContactNumber: string | undefined,
	readonly despatchMethod: CreateOrderDespatchMethodEnum,
	readonly despatchDate: string
	readonly despatchSlot?: ClosureType // mainly used for delivery and in-store orders
	readonly despatchClickAndCollectTimeslot?: ClickAndCollectTimeslot
	readonly recipientName?: string | undefined
	readonly notes: string | undefined,
	readonly onSiteContactNumber?: string | undefined
	readonly [OrderInputs.LATITUDE]: string
	readonly [OrderInputs.LONGITUDE]: string
	readonly selectedAccount?: UserAccount | undefined
	readonly successSendOrder: boolean
	readonly loadingMessageSendOrder?: string
	readonly priceSummary?: ProductPriceSummaryResponse
}

const INITIAL_STATE: StoreState = {
	orderItems: [],
	orderNumber: '',
	name: undefined,
	email: '',
	mobileNumber: '',
	[OrderInputs.SUBURB]: '',
	[OrderInputs.ADDRESS]: '',
	[OrderInputs.CITY]: '',
	[OrderInputs.STATE]: '',
	[OrderInputs.POSTCODE]: '',
	[OrderInputs.LATITUDE]: '',
	[OrderInputs.LONGITUDE]: '',
	branchName: undefined,
	branchId: undefined,
	branchContactNumber: undefined,
	despatchMethod: CreateOrderDespatchMethodEnum.CPU,
	despatchDate: '',
	despatchSlot: undefined,
	despatchClickAndCollectTimeslot: undefined,
	notes: undefined,
	successSendOrder: true,
	loadingMessageSendOrder: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(cartActions.addProductToCart, (state, payload): StoreState => {
	let itemExists: boolean = false
	// create full clone of existing items in cart
	const updatedOrders = state.orderItems.map((item) => {
		if (item.product.sku !== payload.product.sku) {
			// This isn't the item we care about - keep it as-is
			return item
		} else {
			// Otherwise, this is the one we want - return an updated value
			itemExists = true
			const updatedQuantity = item.quantity + payload.count
			return {
				...item,
				quantity: updatedQuantity <= 0 ? 0 : updatedQuantity
			}
		}
	})
	// if item was not found, create and add to end of list
	if (!itemExists) {
		const newOrderItem = {
			product: payload.product,
			quantity: payload.count
		}
		updatedOrders.push(newOrderItem)
	}
	return {
		...state,
		// only return items with valid quantities
		orderItems: updatedOrders.filter(item => item.quantity > 0),
	}
})

reducer.case(cartActions.removeProductFromCart, (state, payload): StoreState => {
	// create a new array of the current order items
	const currentOrderItems = Array.from(state.orderItems)
	// find the OrderItem by the product sku
	let currentOrderItem = currentOrderItems.find((element) => {
		return element.product.sku === payload.product.sku
	})
	// remove the order item from the order items if found
	if (currentOrderItem) {
		currentOrderItems.splice(currentOrderItems.indexOf(currentOrderItem), 1)
	}
	// checks if there are no order items left in the cart and clears the order details previously inputted
	const orderDetails = currentOrderItems.length === 0
		? {
			orderNumber: '',
			email: '',
			mobileNumber: '',
			suburb: '',
			address: '',
			city: '',
			state: '',
			postCode: '',
			branchContactNumber: undefined,
			despatchMethod: CreateOrderDespatchMethodEnum.CPU,
			despatchDate: '',
			despatchSlot: undefined,
			notes: undefined,
		}
		: {}

	return {
		...state, orderItems: currentOrderItems, ...orderDetails
	}
})

reducer.case(cartActions.updateProductQuantityInCart, (state, payload): StoreState => {
	let orderItems: OrderItem[] = []
	// find the OrderItem by the product sku
	const currentOrderItem: OrderItem | undefined = state.orderItems.find(item => item.product.sku === payload.product.sku)

	if (currentOrderItem) {
		orderItems = payload.quantity > 0
			// update the order item quantity
			? state.orderItems.map(item => item.product.sku !== payload.product.sku ? item : { ...item, quantity: payload.quantity })
			// if the quantity is zero or below we filter out the OrderItem from the current order items
			: state.orderItems.filter(item => item.product.sku !== payload.product.sku)
	} else {
		if (payload.quantity > 0) {
			// add the payload OrderItem to the current list of order items
			orderItems = [...state.orderItems, payload]
		} else {
			orderItems = state.orderItems
		}
	}

	return { ...state, orderItems }
})

reducer.case(cartActions.updateProductPricesInCart.done, (state, payload): StoreState => {
	const { prices } = payload.result
	if (prices && prices.length > 0) {
		const updatedOrderItems: OrderItem[] = state.orderItems.map(order => {
			const productPrices: ProductPrices | undefined = prices.find(item => item.sku === order.product.sku)
			// append the updated prices if the product was found, if not then return as is
			if (productPrices) {
				const updatedProduct = getPlatformSupportImplementation().mapProductPricesToProductInfo(order.product, productPrices) // TODO consider removing this mapper (only copy account pricing for mobile client)
				return {
					...order,
					product: updatedProduct,
				}
			}
			return order

		})
		return { ...state, orderItems: updatedOrderItems }
	}
	return { ...state }
})

reducer.case(cartActions.updateProductAvailabilityInCart.done, (state, payload): StoreState => {
	const { products } = payload.result
	if (products && products.length > 0) {
		const updatedOrderItems: OrderItem[] = state.orderItems.map(order => {
			const productDetails = products.find(item => item.productId === order.product.sku)
			// append the updated availability if the product was found, if not then return as is
			if (productDetails) {
				return {
					...order,
					product: { ...order.product, quantityAvailable: productDetails.stockAvailable },
				}
			}
			return order

		})
		return { ...state, orderItems: updatedOrderItems }
	}
	return state
})

reducer.case(cartActions.navigateToConfirmOrder, (state, payload): StoreState => {
	let deliveryDetails
	if (payload.despatchDetails.despatchMethod === CreateOrderDespatchMethodEnum.DIR_D) {
		deliveryDetails = {
			name: payload.deliveryDetails?.recipientName,
			recipientName: payload.deliveryDetails?.recipientName,
			onSiteContactNumber: payload.deliveryDetails?.onSiteContactNumber,
		}
	} else {
		// clear out any delivery details
		deliveryDetails = {
			name: undefined,
			recipientName: undefined,
			onSiteContactNumber: undefined,
		}
	}

	return {
		...state,
		orderNumber: payload.orderNumber,
		branchName: payload.branchName,
		branchId: payload.branchId,
		branchContactNumber: payload.branchContactNumber,
		address: payload.despatchDetails.address,
		suburb: payload.despatchDetails.suburb,
		city: payload.despatchDetails.city,
		state: payload.despatchDetails.state,
		postCode: payload.despatchDetails.postCode,
		email: payload.despatchDetails.email,
		mobileNumber: payload.despatchDetails.mobileNumber,
		notes: payload.notes,
		despatchMethod: payload.despatchDetails.despatchMethod,
		despatchDate: payload.despatchDetails.despatchDate,
		despatchSlot: payload.despatchDetails.despatchSlot,
		despatchClickAndCollectTimeslot: undefined,
		successSendOrder: false,
		latitude: payload.despatchDetails.latitude,
		longitude: payload.despatchDetails.longitude,
		...deliveryDetails,
	}
})

reducer.case(cartActions.reOrder, (state, payload): StoreState => {
	let deliveryDetails
	if (payload.despatchMethod === OrderDespatchMethodEnum.DIR_D) {
		deliveryDetails = {
			name: payload.clientName,
			recipientName: payload.clientName,
			onSiteContactNumber: payload.builderContact,
		}
	} else {
		// clear out any delivery details
		deliveryDetails = {
			name: undefined,
			recipientName: undefined,
			onSiteContactNumber: undefined,
		}
	}

	// process order items
	const updatedOrders: OrderItem[] = payload.orderItems.map<OrderItem>(orderItem => {
		return {
			product: getPlatformSupportImplementation().converOrderItemToProductInfo(orderItem),
			quantity: orderItem.quantity,
		}
	})

	// set remaining order details
	// Note: we do not copy across the date/time as the order is probably in the past, so would be invalid
	// Note2: account ID not set as we need the full account object not just ID
	return {
		...state,
		orderItems: updatedOrders,
		orderNumber: payload.orderReference,
		branchName: payload.branchName,
		branchId: payload.branchId.toString(),
		branchContactNumber: payload.branchContactNumber,
		despatchMethod: payload.despatchMethod as unknown as CreateOrderDespatchMethodEnum,
		address: payload.clientAddress || '',
		suburb: payload.clientSuburb || '',
		city: payload.clientCity || '',
		state: payload.clientState || '',
		postCode: payload.clientPostcode || '',
		latitude: payload.deliveryLatitude?.toString() || '',
		longitude: payload.deliveryLongitude?.toString() || '',
		notes: payload.notes,
		email: payload.email || '',
		mobileNumber: payload.mobileNumber || '',
		successSendOrder: false,
		...deliveryDetails,
	}
})

reducer.case(cartActions.reOrderCustomerOrder, (state, payload): StoreState => {
	const order = payload.customerOrder
	// process order items
	const updatedOrders: OrderItem[] | undefined = order.orderItems && order.orderItems.map<OrderItem>(orderItem => {
		return {
			product: getPlatformSupportImplementation().converCustomerOrderItemToProductInfo(orderItem),
			quantity: orderItem.quantity,
		}
	})

	if (updatedOrders === undefined) return state

	// set remaining order details
	// Note: we do not copy across the date/time as the order is probably in the past, so would be invalid
	// Note2: account ID not set as we need the full account object not just ID
	return {
		...state,
		orderItems: updatedOrders,
		orderNumber: order.orderReference,
		branchName: payload.branchName,
		branchId: payload.branchId,
		branchContactNumber: payload.branchContactNumber,
		despatchMethod: CreateOrderDespatchMethodEnum.CPU,
		address: '',
		suburb: '',
		city: '',
		state: '',
		postCode: '',
		latitude: '',
		longitude: '',
		notes: undefined,
		email: '',
		mobileNumber: '',
		successSendOrder: false,
	}
})

reducer.case(cartActions.saveOrderDetail, (state, { input, value }): StoreState => {
	return {
		...state,
		[input]: value,
	}
})

reducer.case(cartActions.accountSelected, (state, payload): StoreState => {
	return {
		...state,
		// we will only allow approved accounts to be selected
		selectedAccount: payload.registrationState === AccountStatus.Approved ? payload : undefined
	}
})

reducer.case(cartActions.clearAccountSelected, (state): StoreState => {
	return {
		...state,
		selectedAccount: undefined,
	}
})

reducer.case(cartActions.branchSelected, (state, payload): StoreState => {
	return {
		...state,
		branchId: payload
	}
})

reducer.case(cartActions.sendOrderFailed, (state): StoreState => {
	return {
		...state,
		successSendOrder: true,
	}
})

reducer.cases([actions.saveOrderToDatabase.started, actions.submitOrderOnline.started], (state): StoreState => {
	return {
		...state,
		loadingMessageSendOrder: 'Sending order…',
	}
})

reducer.cases([actions.saveOrderToDatabase.failed, actions.submitOrderOnline.failed], (state): StoreState => {
	return {
		...state,
		successSendOrder: false,
		loadingMessageSendOrder: undefined,
	}
})

// Mobile uses saveOrderToDatabase to clear redux, while Web uses submitOrderOnline
reducer.cases([actions.saveOrderToDatabase.done, actions.submitOrderOnline.done], (state): StoreState => {
	return {
		...INITIAL_STATE,
		successSendOrder: true,
		loadingMessageSendOrder: undefined,
		selectedAccount: state.selectedAccount,
		branchId: state.branchId,
	}
})

reducer.case(cartActions.clearCart, (state): StoreState => {
	return {
		...INITIAL_STATE,
		selectedAccount: state.selectedAccount,
		branchId: state.branchId,
	}
})

reducer.case(authActions.loggedOut, (state): StoreState => {
	return {
		...INITIAL_STATE,
		branchId: state.branchId,
	}
})

reducer.case(readyAction, (state): StoreState => {
	return {
		...state,
		successSendOrder: true,
		loadingMessageSendOrder: undefined,
	}
})

reducer.case(cartActions.updateDespatchMethodInCart, (state, payload): StoreState => {
	return {
		...state,
		despatchMethod: payload,
	}
})

reducer.case(cartActions.fetchCartPriceSummary.done, (state, { result }): StoreState => {
	return {
		...state,
		priceSummary: result,
	}
})