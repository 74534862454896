import { SagaIterator } from 'redux-saga'
import { takeEvery, put } from 'redux-saga/effects'
import { Location } from 'react-router-dom'
import { Action } from 'typescript-fsa'

import { accountSelected } from '../../common/cart/actions'
import { fetchCustomerOrders, viewCustomerOrderDetailAction, ViewCustomerOrderDetailsAction } from '../../common/customerorders/actions'
import { UserAccount } from '../../common/accounts/types'
import { navigateToOrdersTab } from '../platform/actions'
import { CustomerOrderDetailsNavigationState } from './types'
import * as NavigationManager from '../navigation/NavigationManager'
import { Paths } from '../navigation/types'

/**
 * Listens when a new account is selected and updates the related customer orders / branch quotes data.
 * This is done so our list of customer orders or branch quotes will always match with the user's selected account.
 */
function* handleCustomerOrders(action: Action<UserAccount>): SagaIterator {
	const location: Location | undefined = NavigationManager.getLocation()
	// check if we are in a screen related to customer orders / branch quotes
	const isOrderUrl: boolean = location
		? location.pathname.startsWith(Paths.CUSTOMER_ORDERS)
		: false

	if (isOrderUrl) {
		const selectedAccount: UserAccount = action.payload
		// update list of customer orders / branch quotes
		yield put(fetchCustomerOrders.started({
			customerId: selectedAccount.customerId,
			type: 'BOTH',
		}))

		// check if we are in a product screen
		const urlParams = new URLSearchParams(location?.search)
		const productSku: string | undefined = urlParams.get('product') || undefined
		if (productSku) {
			// replace the current url with a product-specific one since the previous order won't exist anymore
			NavigationManager.navigateToProductDetails(productSku, true)
		}
	}
}

function handleNavigateToCustomerOrderDetails(action: ViewCustomerOrderDetailsAction) {
	const { orderId, orderSuffixId, orderReference } = action.payload
	const state: CustomerOrderDetailsNavigationState = { orderReference }
	NavigationManager.navigateToCustomerOrderDetails(orderId, orderSuffixId.toString(), state)
}

function handleNavigateToCustomerOrders() {
	NavigationManager.navigateToCustomerOrders()
}

export default function* (): SagaIterator {
	yield takeEvery(accountSelected, handleCustomerOrders)
	yield takeEvery(viewCustomerOrderDetailAction, handleNavigateToCustomerOrderDetails)
	yield takeEvery(navigateToOrdersTab, handleNavigateToCustomerOrders)
}
