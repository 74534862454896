import { Environment } from '../../common/api/types'

export const FirebaseConfig = {
	[Environment.LOCAL]: {
		apiKey: 'AIzaSyCvibpbqEja_szWxprFnT7VLtCK4rEiwKk',
		authDomain: 'ps-coop.firebaseapp.com',
		projectId: 'ps-coop',
		storageBucket: 'ps-coop.appspot.com',
		messagingSenderId: '241838943342',
		appId: '1:241838943342:web:0fe4894b0c90f7b2f58a7f',
		measurementId: 'G-XTT30PVBKJ'
	},
	[Environment.DEV]: {
		apiKey: 'AIzaSyCvibpbqEja_szWxprFnT7VLtCK4rEiwKk',
		authDomain: 'ps-coop.firebaseapp.com',
		projectId: 'ps-coop',
		storageBucket: 'ps-coop.appspot.com',
		messagingSenderId: '241838943342',
		appId: '1:241838943342:web:f0095529674896caf58a7f',
		measurementId: 'G-H6JE0DP77M'
	},
	[Environment.TEST]: {
		apiKey: 'AIzaSyCvibpbqEja_szWxprFnT7VLtCK4rEiwKk',
		authDomain: 'ps-coop.firebaseapp.com',
		projectId: 'ps-coop',
		storageBucket: 'ps-coop.appspot.com',
		messagingSenderId: '241838943342',
		appId: '1:241838943342:web:26444de79de771c8f58a7f',
		measurementId: 'G-R81DXTPTE0'
	},
	[Environment.PRODUCTION]: {
		apiKey: 'AIzaSyCvibpbqEja_szWxprFnT7VLtCK4rEiwKk',
		authDomain: 'ps-coop.firebaseapp.com',
		projectId: 'ps-coop',
		storageBucket: 'ps-coop.appspot.com',
		messagingSenderId: '241838943342',
		appId: '1:241838943342:web:d8b0cf55c84c8413f58a7f',
		measurementId: 'G-3VBPW149DH'
	},
}
