import { Middleware, ReducersMapObject, Reducer, Action } from 'redux'
import { BranchProductDetail, Order as ServerOrder, Product as ApiProduct, ProductPrices, OrderItem, CustomerOrderItem } from 'typescript-fetch-api'

import { Config } from '../auth/types'
import { RootStoreState } from '../root'
import { OrderId } from '../order/types'
import { ChangePasswordRequestAction } from '../forgotpassword/actions'
import { ProductInfo, PlatformProduct, getPlatformSupport } from '../../modules/platform'
import { ViewProductPayload } from '../product/actions'
import { RemoteConfig, RemoteConfigFeature } from '../remoteConfig/types'
import { Environment } from '../api/types'

export interface PlatformSupport {
	customiseReduxMiddleware: (middlewares: Middleware[]) => Middleware[]

	customiseRootReducer: (reducer: Reducer<RootStoreState>) => Reducer<RootStoreState>

	customiseReducers: <S, A extends Action>(reducers: ReducersMapObject<S, A>) => ReducersMapObject<S, A>

	customiseBlacklistedReducers: () => string[]

	/** Create and return the authentication configuration */
	createAuthConfiguration(): Config

	getPlatformClientId(): string

	getPlatformClientSecret(): string

	getEnvironment(): Environment

	/**
	 * Show confirmation message for user signing out. Resolves to `true` if user confirms sign out.
	 */
	signOutConfirmation(title: string, message: string, positiveButtonTitle: string): Promise<boolean>

	reportError(message: string, object?: any): void

	logBreadCrumb(tag: 'network' | 'database' | 'navigation' | 'action', message: string, data?: object): void

	/**
	 * Called when user tried to reset their password but provided an invalid SMS code.
	 */
	handleIncorrectSMSCode(action: ChangePasswordRequestAction, errorText: string): void

	getFCMToken(): Promise<string>

	/**
	 * This is called from the offline action
	 */
	submitOrderToApi(orderId: OrderId): Promise<ServerOrder>

	/**
	 * Converts details product info into basic info. Needs to be done by platform as structure might be different. TODO these types should be also defined here as extended by platform
	 */
	convertPlatformProductToProductInfo(product: PlatformProduct): ProductInfo // TODO define these in common

	convertPlatformProductToViewProductPayload(product: PlatformProduct, hasExtraParams?: boolean, reloadProduct?: boolean): ViewProductPayload

	convertProductInfoToViewProductPayload(product: ProductInfo, hasExtraParams?: boolean): ViewProductPayload

	converOrderItemToProductInfo(orderItem: OrderItem): ProductInfo

	converCustomerOrderItemToProductInfo(orderItem: CustomerOrderItem): ProductInfo

	getBranchesFetchDateSharedPrefs(): Promise<string | null>

	saveBranchesFetchDateSharedPrefs(timestamp: string): void

	hasPinFeature(): boolean

	mapApiProductToPlatformProduct(product: ApiProduct): PlatformProduct

	mapProductPricesToProductInfo(product: ProductInfo, productPrices: ProductPrices): ProductInfo

	getPricingPreferences(state: RootStoreState): { retailEnabled: boolean, costEnabled: boolean, searchRankEnabled: boolean }

	getRemoteConfigByKey(remoteConfigKey: RemoteConfig): Promise<RemoteConfigFeature>

	/**
	 * When fetching pricing and availability after loading products, needed a way to append the price and availability to each product in the results array (for web only).
	 * We don't want to do this on mobile as we try to avoid looping through the realm cursor as takes up time, so added this platform method to only do it on web.
	 */
	appendPricingToProducts?(products: ReadonlyArray<PlatformProduct>, prices: Array<ProductPrices>): Array<PlatformProduct>

	appendAvailabilityToProducts?(products: ReadonlyArray<PlatformProduct>, availability: Array<BranchProductDetail>): Array<PlatformProduct>

	appendAvailabilityToProduct?(product: PlatformProduct, availability: BranchProductDetail): PlatformProduct
}

export function getPlatformSupportImplementation(): PlatformSupport {
	return getPlatformSupport()
}