import { Location } from 'react-router-dom'

import * as OrderComplete from '../../common/cart/containers/OrderComplete'
import * as Confirm from '../../common/cart/containers/Confirm'
import * as Pin from '../../common/cart/containers/Pin'
import { Order, OrderItem } from '../../common/order/types'
import { PIN_TYPE } from '../../common/cart/types'
import { UserAccount } from '../../common/accounts/types'

/**
 * ORDER COMPLETE
 */

export interface OrderCompleteNavigationState {
	order: Order
	customerId?: number
}

export interface OrderCompleteNavigationProps extends OrderCompleteNavigationState { }

export function extractOrderCompleteNavProps(props: OrderCompleteNavigationProps): OrderComplete.OwnPropInfo {
	return props
}

export function extractOrderCompleteNavigationState(location: Location): OrderComplete.OwnPropInfo {
	const state = location.state as OrderCompleteNavigationState
	if (state) {
		return {
			order: state.order,
			customerId: state.customerId,
		}
	}
	return {
		order: { orderItems: [] } as unknown as Order, // OrderComplete.tsx requires the Order to have orderItems array (even if its empty),
		customerId: undefined,
	}
}

/**
 * PIN
 */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PinNavigationProps { }

export function extractPinNavProps(props: PinNavigationProps): Pin.OwnPropInfo {
	return {
		type: PIN_TYPE.SET, // temporarily setting as default `type` value
		goCheckoutAfter: false
	}
}

/**
 * CONFIRM
 */

export interface ConfirmNavigationState {
	orderItems?: OrderItem[]
	selectedAccount?: UserAccount
}

export interface ConfirmNavigationProps extends ConfirmNavigationState { }

export function extractConfirmNavProps(props: ConfirmNavigationProps): Confirm.OwnPropInfo {
	return {
		items: props.orderItems,
		selectedAccount: props.selectedAccount,
	}
}

/**
 * We pass through the order items and selected account via the router so we can preserve the values even when a user decides to change the selected account from the subheader.
 * This is a web-only case since users can only change their account from the profile or the checkout form screen.
 */
export function extractConfirmNavigationState(location: Location): Confirm.OwnPropInfo {
	const state = location.state as ConfirmNavigationState
	if (state) {
		return {
			items: state.orderItems,
			selectedAccount: state.selectedAccount,
		}
	}
	return {}
}

/**
 * OTHER TYPES
 */

// defines the actions in the checkout stepper
export enum CheckoutPath {
	OrderDetails = 'details',
	Confirm = 'confirm',
	Complete = 'complete',
}

export interface OrderPriceSummary {
	subtotal: number
	gst: number
	total: number
}

export interface CheckoutStep {
	id: number
	title: string
	path: CheckoutPath | undefined
}

export enum OrderFormErrors {
	REQUIRED_ORDER_NUMBER = 'An order number is required',
	REQUIRED_DESPATCH_DATE = 'A date is required',
	INVALID_DATE = 'Invalid date',
	REQUIRED_RECIPIENT = 'A recipient name is required',
	REQUIRED_CONTACT_NUMBER = 'A contact number is required',
	REQUIRED_ADDRESS = 'A street address is required',
	REQUIRED_SUBURB = 'A suburb is required',
	REQUIRED_CITY = 'A city is required',
	REQUIRED_STATE = 'A state is required',
	REQUIRED_POSTCODE = 'A postcode is required',
	REQUIRED_EMAIL = 'An email is required',
	REQUIRED_MOBILE = 'A mobile number is required',
	REQUIRED_DELIVERY_LAT = 'A delivery latitude is required',
	REQUIRED_DELIVERY_LNG = 'A delivery longitude is required',
	INVALID_ACCOUNT_PERMISSION = 'Invalid account, no permissions to create order',
}

export enum OrderInputs {
	ADDRESS = 'address',
	SUBURB = 'suburb',
	CITY = 'city',
	STATE = 'state',
	POSTCODE = 'postcode',
	DELIVERY_LAT = 'delivery_lat',
	DELIVERY_LNG = 'delivery_lng',
}

/**
 * A single address component within a <code>GeocoderResult</code>. A full
 * address may consist of multiple address components.
 */
interface GeocoderAddressComponent {
	/**
	 * The full text of the address component
	 */
	long_name: string
	/**
	 * The abbreviated, short text of the given address component
	 */
	short_name: string
	/**
	 * An array of strings denoting the type of this address component. A list
	 * of valid types can be found <a
	 * href="https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes">here</a>
	 */
	types: string[]
}

interface LatLng {
	/**
	 * Returns the latitude in degrees.
	 */
	lat(): number
	/**
	 * Returns the longitude in degrees.
	 */
	lng(): number
}

/**
 * Defines information about the geometry of a Place.
 */
interface PlaceGeometry {
	/**
	 * The Place’s position.
	 */
	location?: LatLng
}

/**
 * Defines information about a Place.
 */
export interface PlaceResult {
	/**
	 * The collection of address components for this Place’s location.
	 */
	address_components?: GeocoderAddressComponent[]
	/**
	 * The Place’s geometry-related information.
	 */
	geometry?: PlaceGeometry
	/**
	 * Attribution text to be displayed for this Place result. Available
	 * <code>html_attributions</code> are always returned regardless of what
	 * <code>fields</code> have been requested, and must be displayed.
	 */
	html_attributions?: string[]
	/**
	 * The Place’s name. Note: In the case of user entered Places, this is the
	 * raw text, as typed by the user. Please exercise caution when using this
	 * data, as malicious users may try to use it as a vector for code injection
	 * attacks (See <a href="http://en.wikipedia.org/wiki/Code_injection">
	 * http://en.wikipedia.org/wiki/Code_injection</a>).
	 */
	name?: string
}