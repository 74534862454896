import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from './actions'
import { Order } from 'typescript-fetch-api'
import { loggedOut, login } from '../auth/actions'
import { submitOrder, submitOrderOnline } from '../order/actions'
import { readyAction } from '../root/actions'

export interface StoreState {
	readonly orders: Order[]
	readonly totalOrderCount?: number
	readonly fetchingOrders: boolean
	readonly errorFetchingOrders?: Error
}

/**
 * For loading orders from the server. This is different to the `order` reducer which handles the users cart and the process of making an order on the app (before it gets saved to the database and to network)
 */
const INITIAL_STATE: StoreState = {
	orders: [],
	totalOrderCount: undefined,
	fetchingOrders: false,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

// FETCHING
reducer.case(actions.fetchOrders.started, (state, payload): StoreState => {
	if (!payload.appendToList) {
		return {
			...state, orders: [], fetchingOrders: true, errorFetchingOrders: undefined, totalOrderCount: undefined,
		}
	}
	return {
		...state, fetchingOrders: true, errorFetchingOrders: undefined, totalOrderCount: undefined,
	}
})
reducer.case(actions.fetchOrders.done, (state, payload): StoreState => {
	const orders = payload.result.orders || []
	return {
		...state,
		orders: payload.params.appendToList ? [...state.orders, ...orders] : orders,
		totalOrderCount: payload.result.count,
		fetchingOrders: false,
	}
})
reducer.case(actions.fetchOrders.failed, (state, payload): StoreState => {
	return {
		...state, fetchingOrders: false, errorFetchingOrders: payload.error,
	}
})

// CREATE ORDER
// Mobile uses the offline action submitOrder, whereas Web uses submitOrderOnline
reducer.cases([submitOrder.done, submitOrderOnline.done], (state, payload): StoreState => {
	// we save the newly created server Order object in our list (which saves having to fetch ALL users orders again)
	// the most recent order is always at top of the list so we know where to put it
	const updatedOrders = [...state.orders]
	// check order does not exist already (had an issue where we were showing duplicates as server request happened more than once - server prevents dupes)
	const orderExists: boolean = updatedOrders.find(item => item.id === payload.result.id) !== undefined
	if (!orderExists) { updatedOrders.unshift(payload.result) }
	return {
		...state, orders: updatedOrders,
	}
})

// LOGGED OUT
reducer.case(loggedOut, (): StoreState => {
	// clear users orders
	return INITIAL_STATE
})
// LOGGED IN
reducer.case(login.done, (): StoreState => {
	// if user logs in we want to clear any potential errors due to them trying to fetch orders while signed out (easiest way is to set back to initial state)
	return INITIAL_STATE
})

reducer.case(readyAction, (state): StoreState => {
	return {
		...state,
		fetchingOrders: false,
		errorFetchingOrders: undefined,
	}
})
