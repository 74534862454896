import { CreateOrderDespatchMethodEnum, OrderDespatchMethodEnum } from 'typescript-fetch-api'
import { OrderItem } from '../order/types'
import { PriceType, ProductAvailability } from './types'

export const filterOrderItemsByStockAvailability = (items: OrderItem[], type: ProductAvailability): OrderItem[] => {
	const filteredItems: OrderItem[] = []
	for (const item of items) {
		const availability = getStockAvailabilityTypeForItem(item.quantity, item.product.quantityAvailable)
		if (availability === type) {
			filteredItems.push(item)
		}
	}
	return filteredItems
}

export const getStockAvailabilityTypeForItem = (quantity: number, quantityAvailable?: number): ProductAvailability => {
	if (quantityAvailable === undefined || quantityAvailable <= 0) return ProductAvailability.AVAILABLE_TO_ORDER
	if (quantityAvailable - quantity < 0) return ProductAvailability.LIMITED_STOCK
	return ProductAvailability.IN_STOCK
}

/**
 * Handles getting dummy data for the stock count and quantity to populate on the @see StockAvailabilityLogo.tsx
 * @param productAvailability the product availability
 * @returns the stock count and quantity
 */
export const getDummyDataForProductAvailability = (productAvailability: ProductAvailability): { stockCount: number, quantity: number } => {
	let stockCount = 0
	let quantity = 0
	if (productAvailability === ProductAvailability.IN_STOCK) {
		stockCount = 2
		quantity = 1
	} else if (productAvailability === ProductAvailability.LIMITED_STOCK) {
		stockCount = 1
		quantity = 2
	}
	return { stockCount, quantity }
}

export const getDespatchMethodLabel = (despatchMethod: OrderDespatchMethodEnum | CreateOrderDespatchMethodEnum): string => {
	switch (despatchMethod) {
		case OrderDespatchMethodEnum.CPU:
		case CreateOrderDespatchMethodEnum.CPU:
			return 'Click & Collect'
		case OrderDespatchMethodEnum.DIR_D:
		case CreateOrderDespatchMethodEnum.DIR_D:
			return 'Delivery'
	}
}

/**
 * Handles getting the display name for a price type
 * @param priceType the price type
 * @returns the display name
 */
export function getPriceTypeDisplayName(priceType: PriceType): string {
	switch (priceType) {
		case PriceType.RRP:
			return 'Retail Price'
		case PriceType.TRD:
			return 'Trade Price'
		case PriceType.CST:
			return 'Cost Price'
		default:
			throw Error('Unknown price type: ' + priceType)
	}
}