import actionCreatorFactory, { Action } from 'typescript-fsa'
import { PagedUserOrdersResponse } from 'typescript-fetch-api'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('MyOrders')

/* An example action with an interface as its payload. */
export interface ViewOrderDetailsPayload {
	orderId: string
	isCustomerOrder: boolean
	isRealmOrder: boolean
	isFromRealmOrderDetails?: boolean
}
export type ViewOrderDetailsAction = Action<ViewOrderDetailsPayload>
export const viewOrderDetailAction = actionCreator<ViewOrderDetailsPayload>('VIEW_ORDER_DETAILS')

/**
 * We dispatch the action that is listened for my the redux-persist library, which triggers retrying any queued actions. Even though there appear to be no usages of the action, its because the library is using it.
 */
const sendOrderActionCreator = actionCreatorFactory('Offline')
export const sendOrdersAction = sendOrderActionCreator<number>('SCHEDULE_RETRY')

export interface FetchOrdersPayload {
	page?: number
	pageSize?: number
	appendToList?: boolean
}
export type FetchOrdersAction = Action<FetchOrdersPayload>
export const fetchOrders = actionCreator.async<FetchOrdersPayload, PagedUserOrdersResponse, Error>('FETCH_ORDERS')

export const navigateToSignIn = actionCreator('NAVIGATE_TO_SIGN_IN')