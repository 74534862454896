export enum RemoteConfig {
	NOTIFY_ME = 'notify_me',
	STOCK_COUNTS = 'stock_counts',
	BARCODE_SCANNER = 'barcode_scanner',
	AROFLO = 'aroflo',
	CLICK_AND_COLLECT = 'click_and_collect',
}

export enum RemoteConfigProperty {
	USERS = 'users',
	CUSTOMERS = 'customers',
	AVAILABLE_TO_ALL = 'availabletoall',
}

export interface RemoteConfigFeature {
	[RemoteConfigProperty.USERS]: string[]
	[RemoteConfigProperty.CUSTOMERS]: string[]
	[RemoteConfigProperty.AVAILABLE_TO_ALL]: boolean
}

export enum UserProperties {
	USER_ID = 'api_user_id',
}
