import { NavigateFunction, Location } from 'react-router-dom'
import { OwnPropInfo as AccountListOwnProps } from '../../common/mylists/containers/AccountList'

import { CheckoutPath, ConfirmNavigationState, OrderCompleteNavigationState } from '../cart/types'
import { CustomerOrderDetailsNavigationState } from '../customerorders/types'
import { ChangePasswordNavigationState } from '../forgotpassword/types'
import { MobileVerificationLocationState } from '../mobileVerification/types'
import { NavigateToNewListState } from '../mylists/types'
import { TabOptions } from '../platform/actions'
import { Paths } from './types'
import { FinancialTabOptions } from '../financials/actions'
import { PSCOOPPathsEnum, SupportPathsEnum, TradePathsEnum } from '../general/types'

/**
 * NavigateFunction must be aquired from the useNavigation hook, so is set via NavigateSetter dummy component
 */
let navigate: NavigateFunction

export function setNavigateFunction(func: NavigateFunction) {
	navigate = func
}

let location: Location

export function setLocation(loc: Location) {
	location = loc
}

export function getLocation(): Location | undefined {
	return location
}

/* -------------------------------------------------------------------------- */
/*                              Screen Navigation                             */
/* -------------------------------------------------------------------------- */

export const navigateToHome = (): void => {
	navigate(Paths.HOME)
}

export const navigateToSignIn = (): void => {
	navigate(Paths.SIGN_IN)
}

/**
 * Products
 */

export const navigateToProductDetails = (productSku: string, replace?: boolean): void => {
	const encodedProductSku = encodeURIComponent(productSku)
	navigate(Paths.PRODUCT_DETAILS.replace(':id', encodedProductSku), { replace })
}

export const navigateToProductViaParams = (productSku: string): void => {
	const url = location.pathname
	const encodedProductSku = encodeURIComponent(productSku)
	navigate(url + '?product=' + encodedProductSku)
}

export const navigateToProductSearch = (searchText?: string): void => {
	if (searchText) {
		const encodedSearchText = searchText && encodeURIComponent(searchText)
		navigate(Paths.SEARCH + '?q=' + encodedSearchText)
	} else {
		navigate(Paths.SEARCH)
	}
}

export const navigateToCategory = (url: string): void => {
	// most of the logic was in the saga so I left it there for now and just pass through the calculated url
	navigate(url)
}

/**
 * Orders/Quotes
 */

export const navigateToCustomerOrders = (): void => {
	navigate(Paths.CUSTOMER_ORDERS)
}

export const navigateToCustomerOrderDetails = (orderId: string, orderSuffix: string, state: CustomerOrderDetailsNavigationState): void => {
	navigate(Paths.CUSTOMER_ORDER_DETAILS.replace(':id', orderId).replace(':suffix', orderSuffix), { state })
}

export const navigateToCheckout = (): void => {
	navigate(Paths.CHECKOUT)
}

export const navigateToCheckoutWithAction = (action: CheckoutPath, state?: ConfirmNavigationState | OrderCompleteNavigationState): void => {
	navigate(`${Paths.CHECKOUT}?action=${action}`, { state })
}

/**
 * Lists
 */

export const navigateToLists = (state?: NavigateToNewListState): void => {
	navigate(Paths.LISTS, { state })
}

export const navigateToListGroup = (listGroupId: string, state: AccountListOwnProps): void => {
	navigate(Paths.LIST_GROUP.replace(':id', listGroupId), { state })
}

export const navigateToListDetails = (listGroupId: string, listId: string): void => {
	const encodedListId = encodeURIComponent(listId)
	navigate(Paths.LIST_DETAILS.replace(':id', listGroupId).replace(':listId', encodedListId))
}

/**
 * User
 */

export const navigateToAddAccount = (): void => {
	navigate(Paths.ADD_ACCOUNT)
}

export const navigateToRegister = (): void => {
	navigate(Paths.REGISTER)
}

export const navigateToResetPassword = (): void => {
	navigate(Paths.RESET_PASSWORD)
}

export const navigateToVerifyAccount = (): void => {
	navigate(Paths.VERIFY_ACCOUNT)
}

export const navigateToConfirmResetPassword = (state: ChangePasswordNavigationState): void => {
	navigate(Paths.CONFIRM_RESET_PASSWORD, { state })
}

export const navigateToConfirmPhone = (state: MobileVerificationLocationState): void => {
	navigate(Paths.REGISTER_CONFIRM_PHONE, { state })
}

/**
 * General
 */

export const navigateToTab = (tab: TabOptions, userId?: number): void => {
	if (tab === 'home') {
		navigate(Paths.HOME)
	} else if (tab === 'profile') {
		// distinguishes whether we display the basic or editable user profile screen
		const profilePath: string = userId ? `/${tab}/${userId}` : `/${tab}`
		// saves the current location when the profile tab was clicked.
		// this is to preserve the previous location while the profile modal/dialog is showing.
		const state = { background: location }
		navigate(profilePath, { state })
	} else {
		navigate('/' + tab)
	}
}

export const navigateToVersionUpdates = (): void => {
	navigate(Paths.VERSION_UPDATES)
}

export const navigateToTermsAndConditions = (): void => {
	navigate(Paths.TERMS_AND_CONDITIONS)
}

export const navigateToPrivacyPolicy = (): void => {
	navigate(Paths.PRIVACY_POLICY)
}

export const navigateToContact = (): void => {
	navigate(Paths.CONTACT)
}

export const navigateToRegions = (): void => {
	navigate(Paths.REGIONS)
}

export const navigateToSupport = (screen: SupportPathsEnum): void => {
	navigate(`/${Paths.SUPPORT_RELATIVE}/${screen}`)
}

export const navigateToTrade = (screen: TradePathsEnum): void => {
	navigate(`/${Paths.TRADE_RELATIVE}/${screen}`)
}

export const navigateToPSCoop = (screen: PSCOOPPathsEnum): void => {
	navigate(`/${Paths.PSCOOP_RELATIVE}/${screen}`)
}

/**
 * Admin
 */

export const navigateToFinancialTab = (tab: FinancialTabOptions): void => {
	navigate(Paths.FINANCIALS.replace(':screen', tab))
}
