import actionCreatorFactory, { Action } from 'typescript-fsa'
import { Supplier } from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Filter')

export const closeFiltersAction = actionCreator('CLOSE_FILTERS')

export interface SaveFiltersPayload {
	filters: Array<string>
}

export type SearchDatabaseActionAction = Action<SaveFiltersPayload>
export const saveFiltersAction = actionCreator<SaveFiltersPayload>('SAVE_FILTERS')

export const clearFilters = actionCreator('CLEAR_FILTERS')

export interface LoadSupplierPayload {
	categoryId?: string
}
export const loadSuppliersAction = actionCreator.async<LoadSupplierPayload, Supplier[], Error>('LOAD_SUPPLIERS')
