import { SagaIterator } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'

import { navigateToAddAccount } from '../../common/accounts/actions'
import * as NavigationManager from '../navigation/NavigationManager'

function handleNavigateToAddAccount() {
	NavigationManager.navigateToAddAccount()
}

export default function* (): SagaIterator {
	yield takeEvery(navigateToAddAccount, handleNavigateToAddAccount)
}