import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'

import * as actions from './actions'
import * as authActions from '../../common/auth/actions'
import { RemoteConfig, RemoteConfigFeature } from './types'
import { DEFAULT_REMOTE_CONFIG_FEATURE } from './content'

export type StoreState = Record<RemoteConfig, RemoteConfigFeature>

const INITIAL_STATE: StoreState = {
	[RemoteConfig.NOTIFY_ME]: DEFAULT_REMOTE_CONFIG_FEATURE,
	[RemoteConfig.STOCK_COUNTS]: DEFAULT_REMOTE_CONFIG_FEATURE,
	[RemoteConfig.BARCODE_SCANNER]: DEFAULT_REMOTE_CONFIG_FEATURE,
	[RemoteConfig.AROFLO]: DEFAULT_REMOTE_CONFIG_FEATURE,
	[RemoteConfig.CLICK_AND_COLLECT]: DEFAULT_REMOTE_CONFIG_FEATURE,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
reducer.case(actions.onUpdateRemoteConfig, (state, payload): StoreState => {
	return {
		...state,
		// we merge the default and new values for the config so all the needed properties get populated
		[payload.remoteConfig]: {
			...INITIAL_STATE[payload.remoteConfig],
			...payload.value,
		},
	}
})
reducer.cases([authActions.loggedOut, authActions.refreshAuthTokenFailed], () => INITIAL_STATE)