import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import moment from 'moment'

import * as actions from './actions'
import * as accountActions from '../../common/accounts/actions'
import * as authActions from '../../common/auth/actions'
import { DrawerType, PAGE_SIZES } from './types'
import { Category } from '../../common/categories/types'

export interface StoreState {
	readonly paginationSize: number
	readonly hideBrowserBanner: boolean
	readonly lastWebDisplayedFeatureDialogDate?: string
	readonly toggledDrawer?: DrawerType
	// subcategories for menu drawer (do not want to override subcategories on categories reducer as used on products page)
	readonly menuSubcategories?: ReadonlyArray<Category>
	readonly categoryId?: string
	readonly isLoadingSubcategories: boolean
	readonly errorLoadingSubcategories?: Error
	readonly menuSubSubcategories?: ReadonlyArray<Category>
	readonly subcategoryId?: string
	readonly isLoadingSubSubcategories: boolean
	readonly errorLoadingSubSubcategories?: Error

	// approve account
	readonly approvingAccount: boolean
	readonly approvedAccount: boolean
	readonly errorApprovingAccount?: Error
}

const INITIAL_STATE: StoreState = {
	paginationSize: PAGE_SIZES[0].value,
	hideBrowserBanner: false,
	lastWebDisplayedFeatureDialogDate: undefined,
	toggledDrawer: undefined,
	menuSubcategories: undefined,
	categoryId: undefined,
	isLoadingSubcategories: false,
	errorLoadingSubcategories: undefined,
	menuSubSubcategories: undefined,
	subcategoryId: undefined,
	isLoadingSubSubcategories: false,
	errorLoadingSubSubcategories: undefined,

	// approve account
	approvingAccount: false,
	approvedAccount: false,
	errorApprovingAccount: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	.case(actions.updatePaginationSize, (state, payload): StoreState => {
		return {
			...state, paginationSize: payload
		}
	})

	.case(actions.hideBrowserBanner, (state, payload): StoreState => {
		return {
			...state, hideBrowserBanner: payload
		}
	})

	// feature dialog
	.case(actions.updateLastDisplayedFeatureDialog.done, (state, { result }): StoreState => {
		return {
			...state,
			lastWebDisplayedFeatureDialogDate: result,
		}
	})
	.case(accountActions.getAccounts.done, (state, { result: { lastWebDisplayedFeatureDialogTimestamp } }): StoreState => {
		return {
			...state,
			lastWebDisplayedFeatureDialogDate: lastWebDisplayedFeatureDialogTimestamp
				? moment(lastWebDisplayedFeatureDialogTimestamp).format('YYYY-MM-DD')
				: undefined,
		}
	})

	// load subcategories for menu drawer
	.case(actions.loadSubcategoriesForMenu.started, (state, payload): StoreState => {
		if (payload.type === 'subcategory') {
			return { ...state, menuSubcategories: undefined, categoryId: payload.categoryId, isLoadingSubcategories: true, subcategoryId: undefined, menuSubSubcategories: undefined } // clear subsubcategories if loading new top level category
		}
		return { ...state, menuSubSubcategories: undefined, subcategoryId: payload.categoryId, isLoadingSubSubcategories: true }
	})
	.case(actions.loadSubcategoriesForMenu.done, (state, payload): StoreState => {
		if (payload.params.type === 'subcategory') {
			return { ...state, menuSubcategories: payload.result, isLoadingSubcategories: false }
		}
		return { ...state, menuSubSubcategories: payload.result, isLoadingSubSubcategories: false }
	})
	.case(actions.loadSubcategoriesForMenu.failed, (state, payload): StoreState => {
		if (payload.params.type === 'subcategory') {
			return { ...state, errorLoadingSubcategories: payload.error, isLoadingSubcategories: false }
		}
		return { ...state, errorLoadingSubSubcategories: payload.error, isLoadingSubSubcategories: false }
	})

	.case(authActions.loggedOut, (): StoreState => {
		return INITIAL_STATE
	})

	.case(actions.toggleDrawer, (state, payload): StoreState => {
		return {
			...state,
			toggledDrawer: payload,
		}
	})

	// approve account
	.case(actions.approveAccount.started, (state): StoreState => {
		return {
			...state,
			approvingAccount: true,
			approvedAccount: false,
			errorApprovingAccount: undefined,
		}
	})
	.case(actions.approveAccount.done, (state): StoreState => {
		return {
			...state,
			approvingAccount: false,
			approvedAccount: true,
		}
	})
	.case(actions.approveAccount.failed, (state, { error }): StoreState => {
		return {
			...state,
			approvingAccount: false,
			approvedAccount: true,
			errorApprovingAccount: error,
		}
	})