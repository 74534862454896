import actionCreatorFactory, { Action } from 'typescript-fsa'
import { BranchProductsResponse, CreateOrderDespatchMethodEnum, CustomerOrder, Order, ProductPriceSummaryRequestItem, ProductPriceSummaryResponse, ProductPricesResponse } from 'typescript-fetch-api'

import { OrderItem } from '../order/types'
import { DeliveryDetails, DespatchDetails, OrderInputs } from './types'
import { UserAccount } from '../accounts/types'
import { ProductInfo } from '../../modules/platform'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Cart')

export interface AddProductToCartPayload {
	product: ProductInfo
	count: number
}
export type AddProductToCartAction = Action<AddProductToCartPayload>
export const addProductToCart = actionCreator<AddProductToCartPayload>('ADD_PRODUCT_TO_CART')

export const removeProductFromCart = actionCreator<OrderItem>('REMOVE_PRODUCT_FROM_CART')

export type ClearCartAction = Action<void | OrderItem[]>
export const clearCart = actionCreator<void | OrderItem[]>('CLEAR_CART')

// Note the payload contains the new correct OrderItem count
export type UpdateProductQuantityInCartAction = Action<OrderItem>
export const updateProductQuantityInCart = actionCreator<OrderItem>('UPDATE_PRODUCT_QUANTITY_IN_CART')

export const updateProductPricesInCart = actionCreator.async<void, ProductPricesResponse, Error>('UPDATE_PRODUCT_PRICES_IN_CART')

export const updateProductAvailabilityInCart = actionCreator.async<void, BranchProductsResponse, Error>('UPDATE_PRODUCT_AVAILABILITY_IN_CART')

export const navigateToOrders = actionCreator('VIEW_ORDERS')

export const navigateToCart = actionCreator('VIEW_CART')

export const navigateToReviewCart = actionCreator('REVIEW_CART')

export const navigateToCheckout = actionCreator('VIEW_CHECKOUT')

export const navigateToPin = actionCreator('ENTER_PIN')

export type AccountSelectedAction = Action<UserAccount>
export const accountSelected = actionCreator<UserAccount>('ACCOUNT_SELECTED')

export const clearAccountSelected = actionCreator('CLEAR_ACCOUNT_SELECTED')

export const branchSelected = actionCreator<string | undefined>('BRANCH_SELECTED')

export interface NavigateToConfirmActionPayload {
	orderNumber: string,
	customerId?: number,
	branchName: string,
	branchId: string,
	branchContactNumber: string,
	despatchDetails: DespatchDetails,
	deliveryDetails?: DeliveryDetails | undefined,
	notes: string | undefined,
}
export const navigateToConfirmOrder = actionCreator<NavigateToConfirmActionPayload>('VIEW_CONFIRM_ORDER')

export const reOrder = actionCreator<Order>('RE_ORDER')

export interface ReOrderCustomerOrderPayload {
	customerOrder: CustomerOrder
	branchId: string
	branchName: string
	branchContactNumber: string
}
export const reOrderCustomerOrder = actionCreator<ReOrderCustomerOrderPayload>('RE_ORDER_CUSTOMER_ORDER')

export const sendOrderFailed = actionCreator('SEND_ORDER_FAILED')

export const updateDespatchMethodInCart = actionCreator<CreateOrderDespatchMethodEnum>('UPDATE_DESPATCH_METHOD_IN_CART')

export interface FetchCartPriceSummaryPayload {
	customerId?: number
	items: ProductPriceSummaryRequestItem[]
}
export type FetchCartPriceSummaryAction = Action<FetchCartPriceSummaryPayload>
export const fetchCartPriceSummary = actionCreator.async<FetchCartPriceSummaryPayload, ProductPriceSummaryResponse, Error>('FETCH_CART_PRICE_SUMMARY')

export interface SaveOrderDetailPayload {
	input: OrderInputs
	value: string
}
export const saveOrderDetail = actionCreator<SaveOrderDetailPayload>('SAVE_ORDER_DETAIL')