import { BranchProductDetail } from 'typescript-fetch-api'

import { BranchesAvailability } from './types'

export const updateBranchesAvailability = (regionId: string, products: BranchProductDetail[] | undefined, savedAvailability: BranchesAvailability): BranchesAvailability | undefined => {
	switch (regionId) {
		case '2':
			return { ...savedAvailability, northern: products || [] }
		case '3':
			return { ...savedAvailability, central: products || [] }
		case '4':
			return { ...savedAvailability, lowerCentral: products || [] }
		case '5':
			return { ...savedAvailability, southern: products || [] }
		default:
			return undefined
	}
}
