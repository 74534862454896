import * as firebase from 'firebase/app'
import { Order as ServerOrder, RegisterDeviceOstypeEnum, OrderItem } from 'typescript-fetch-api'

import { OrderId } from '../../common/order/types'
import { FirebaseConfig } from '../analytics/content'
import { REMOTE_CONFIG_DEFAULTS } from '../../common/remoteConfig/content'
import { Environment } from '../../common/api/types'
import { ProductInfo } from '.'

export function getFCMToken(): Promise<string> {
	return Promise.resolve('INVALID_WEB_TOKEN')
}

export function submitOrderToApi(orderId: OrderId): Promise<ServerOrder> {
	// typecasted to temporarily fix errors with required fields
	return Promise.resolve({} as ServerOrder)
}

export function getEnvironment(): Environment {
	// read server environment from environment variable
	const serverEnvironment = process.env.REACT_APP_SERVER_ENVIRONMENT
	if (serverEnvironment !== undefined) {
		if (serverEnvironment === Environment.DEV || serverEnvironment === Environment.PRODUCTION || serverEnvironment === Environment.TEST || serverEnvironment === Environment.LOCAL) {
			return serverEnvironment
		}
	}
	return Environment.TEST
}

/**
 * Displays a confirmation dialog to the user.
 * @param title The title of the dialog
 * @param message The message of the dialog
 * @param positiveButtonTitle The positive button title
 */
export function signOutConfirmationDialog(title: string, message: string, positiveButtonTitle: string): Promise<boolean> {
	return Promise.resolve(true)
}

/**
 * Displays a confirmation dialog to the user.
 * @param title The title of the dialog
 * @param message The message of the dialog
 * @param positiveButtonTitle The positive button title
 */
export function profileDeletedConfirmationDialog(title: string, message: string, positiveButtonTitle: string): Promise<boolean> {
	return Promise.resolve(true) // TODO
}

/**
 * PLATFORM DETECTION
 * 
 * Configurations to help detect device, browser and version for the current platform.
 * https://medium.com/creative-technology-concepts-code/detect-device-browser-and-version-using-javascript-8b511906745
 */

const osList: { name: string, value: string, version: string }[] = [
	{ name: RegisterDeviceOstypeEnum.WINDOWS, value: 'Win', version: 'NT' },
	{ name: RegisterDeviceOstypeEnum.MACOS, value: 'Mac', version: 'OS X' },
	{ name: RegisterDeviceOstypeEnum.LINUX, value: 'Linux', version: 'rv' },
]

const browserList: { name: string, value: string, version: string }[] = [
	{ name: 'Chrome', value: 'Chrome', version: 'Chrome' },
	{ name: 'Firefox', value: 'Firefox', version: 'Firefox' },
	{ name: 'Safari', value: 'Safari', version: 'Version' },
	{ name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
	{ name: 'Opera', value: 'Opera', version: 'Opera' },
	{ name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
	// this handles legacy versions of Edge.
	// note that newer versions are now based upon Chromium (basically the same as Google Chrome, so in these cases the match will return as Chrome).
	// https://stackoverflow.com/questions/31721250/how-to-target-edge-browser-with-javascript#comment107967287_31721250
	{ name: 'Microsoft Edge', value: 'Edge', version: 'Edge' },
]

const matchItem = (headers: string, data: { name: string, value: string, version: string }[]): { name: string, version: string } => {
	let valueRegex: RegExp
	let versionRegex: RegExp
	let hasMatchingValue: boolean
	let versionMatchList: RegExpMatchArray | null
	let version = '0'

	for (let i = 0; i < data.length; i += 1) {
		valueRegex = new RegExp(data[i].value, 'i')
		hasMatchingValue = valueRegex.test(headers)

		if (hasMatchingValue) {
			versionRegex = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i')
			versionMatchList = headers.match(versionRegex)

			if (versionMatchList && versionMatchList[1]) {
				// grabs the version number from the match list
				version = versionMatchList[1]
			}

			return {
				name: data[i].name,
				version: version.replace(/_/g, '.'),
			}
		}
	}

	return { name: 'unknown', version }
}

export const detectPlatform = (): { os: { name: string, version: string }, browser: { name: string, version: string } } => {
	const headers = [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor]
	const combinedHeaders = headers.join(' ')

	const os = matchItem(combinedHeaders, osList)
	const browser = matchItem(combinedHeaders, browserList)

	return { os, browser }
}

export const getFirebaseConfig = () => FirebaseConfig[getEnvironment()]

export const setupFirebase = (): { analytics: firebase.analytics.Analytics, remoteConfig: firebase.remoteConfig.RemoteConfig } => {
	// firebase setup
	const firebaseConfig = getFirebaseConfig()
	const app = firebase.initializeApp(firebaseConfig)

	// analytics setup
	const analytics = firebase.analytics(app)

	// remote config setup
	const remoteConfig = firebase.remoteConfig(app)
	remoteConfig.defaultConfig = REMOTE_CONFIG_DEFAULTS
	if (getEnvironment() !== Environment.PRODUCTION) {
		remoteConfig.settings = {
			// default data caching is set to 12hrs - prevents frequent changes in remote config values
			// NOTE: firebase may start rejecting requests if values are fetched too frequently
			minimumFetchIntervalMillis: 0,
			fetchTimeoutMillis: 60000, // default; added for lint purposes
		}
	}

	return {
		analytics,
		remoteConfig,
	}
}

export const converOrderItemToProductInfo = (orderItem: OrderItem): ProductInfo => {
	return {
		sku: orderItem.productId,
		primaryImageTitle: orderItem.productImage || '',
		shortDescription: orderItem.productDescription,
		recommendedRetailPrice: orderItem.price,
		uom: orderItem.uom,
	}
}
