import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { CarouselItem } from 'typescript-fetch-api'

import * as actions from './actions'
import { PlatformProduct } from '../../modules/platform'

export interface StoreState {
	readonly openQuickSearch: boolean // had trouble getting with working with navigation props so using redux to determine whether quick search should open for now
	readonly carouselItems: CarouselItem[]
	readonly loadingCarousel: boolean
	readonly productOffers: ReadonlyArray<PlatformProduct>
	readonly loadingProductOffers: boolean
}

const INITIAL_STATE: StoreState = {
	openQuickSearch: false,
	carouselItems: [],
	loadingCarousel: false,
	productOffers: [],
	loadingProductOffers: false,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(actions.navigateToProductsTabAndOpenSearch, (state): StoreState => ({
	...state, openQuickSearch: true
}))

reducer.case(actions.clearOpenQuickSearchFlag, (state): StoreState => ({
	...state, openQuickSearch: false
}))

reducer
	.case(actions.fetchCarousel.started, (state): StoreState => ({
		...state, loadingCarousel: true,
	}))
	.case(actions.fetchCarousel.done, (state, { result }): StoreState => ({
		...state, loadingCarousel: false, carouselItems: result.items || [],
	}))
	.case(actions.fetchCarousel.failed, (state): StoreState => ({
		...state, loadingCarousel: false,
	}))

reducer
	.case(actions.fetchProductOffers.started, (state): StoreState => ({
		...state, loadingProductOffers: true,
	}))
	.case(actions.fetchProductOffers.done, (state, { result }): StoreState => ({
		...state, loadingProductOffers: false, productOffers: result,
	}))
	.case(actions.fetchProductOffers.failed, (state): StoreState => ({
		...state, loadingProductOffers: false,
	}))
