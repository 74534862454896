import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { setLocation, setNavigateFunction } from '../NavigationManager'
import { locationChange } from '../../platform/actions'

/**
 * Needed a way to get access to navigate hook to use from redux-sagas, without using history router.
 * https://stackoverflow.com/questions/68399876/how-to-navigate-outside-of-react-component-using-react-router-6/70002872#70002872
 */
const NavigateSetter = () => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	 * Navigation Hooks
	 */

	const navigate = useNavigate()
	const location = useLocation()

	/**
	 * Effects/Subscriptions
	 */

	useEffect(() => {
		setNavigateFunction(navigate)
	}, [navigate])

	useEffect(() => {
		setLocation(location)

		dispatch(locationChange({ pathname: location.pathname, search: location.search }))
	}, [location])

	return null
}

export default NavigateSetter