import { Action, actionCreatorFactory } from 'typescript-fsa'
import { RemoteConfig, RemoteConfigFeature } from './types'

/** Action creator factory for root actions. */
const actionCreator = actionCreatorFactory('RemoteConfig')

/** Action dispatched when the app state is ready (has been rehydrated) */
export const onRemoteConfigReady = actionCreator<boolean>('ON_REMOTE_CONFIG_READY')

export interface OnUpdateRemoteConfigPayload {
    remoteConfig: RemoteConfig
    value: RemoteConfigFeature
}
export const onUpdateRemoteConfig = actionCreator<OnUpdateRemoteConfigPayload>('ON_UPDATE_REMOTE_CONFIG')

export type FetchRemoteConfigAction = Action<RemoteConfig>
export const fetchRemoteConfig = actionCreator<RemoteConfig>('FETCH_REMOTE_CONFIG')
