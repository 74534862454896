import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { combineReducers } from 'redux'

import { getPlatformSupportImplementation } from '../platform'
import * as a from './actions'

/* Import reducers from our modules */
import * as auth from '../auth/reducer'
import * as accounts from '../accounts/reducer'
import * as register from '../register/reducer'
import * as mobileVerification from '../mobileVerification/reducer'
import * as forgotPassword from '../forgotpassword/reducer'
import * as categories from '../categories/reducer'
import * as products from '../products/reducer'
import * as search from '../search/reducer'
import * as product from '../product/reducer'
import * as config from '../config/reducer'
import * as supplierFilter from '../supplierFilter/reducer'
import * as order from '../order/reducer'
import * as orders from '../myorders/reducer'
import * as customerOrders from '../customerorders/reducer'
import * as home from '../home/reducer'
import * as showrooms from '../showrooms/reducer'
import * as profile from '../profile/reducer'
import * as myLists from '../mylists/reducer'
import * as financials from '../financials/reducer'
import * as remoteConfig from '../remoteConfig/reducer'
import * as versionUpdates from '../versionUpdates/reducer'

/**
 * The root store state. Include sub-states for all of the modules / ducks.
 * All of these should be annotated `readonly`, as should everything down
 * the tree of StoreState interfaces, and their contents.
 */
export interface StoreState {
	readonly auth: auth.StoreState
	readonly accounts: accounts.StoreState
	readonly register: register.StoreState
	readonly mobileVerification: mobileVerification.StoreState
	readonly forgotPassword: forgotPassword.StoreState
	readonly categories: categories.StoreState
	readonly products: products.StoreState
	readonly search: search.StoreState
	readonly product: product.StoreState
	readonly config: config.StoreState
	readonly supplierFilter: supplierFilter.StoreState
	readonly order: order.StoreState
	readonly orders: orders.StoreState
	readonly customerOrders: customerOrders.StoreState
	readonly home: home.StoreState
	readonly showrooms: showrooms.StoreState
	readonly profile: profile.StoreState
	readonly lists: myLists.StoreState
	readonly financials: financials.StoreState
	readonly remoteConfig: remoteConfig.StoreState
	readonly versionUpdates: versionUpdates.StoreState

	readonly ready: boolean
}

/**
 * There is a redux action that is dispatched when network status changes: Offline/STATUS_CHANGED
 */
export interface StoreStateWithOffline {
	readonly offline: OfflineState
}

export interface OfflineState {
	readonly online: boolean
	readonly netInfo: any
}

const readyReducer = reducerWithInitialState(false)
	.case(a.readyAction, (state, payload) => (true))

/**
 * The root reducer, combines reducers for all of the modules / ducks.
 */
export const reducer = getPlatformSupportImplementation().customiseRootReducer(combineReducers<StoreState>(getPlatformSupportImplementation().customiseReducers({
	auth: auth.reducer,
	accounts: accounts.reducer,
	register: register.reducer,
	mobileVerification: mobileVerification.reducer,
	forgotPassword: forgotPassword.reducer,
	categories: categories.reducer,
	products: products.reducer,
	search: search.reducer,
	product: product.reducer,
	config: config.reducer,
	supplierFilter: supplierFilter.reducer,
	order: order.reducer,
	orders: orders.reducer,
	customerOrders: customerOrders.reducer,
	home: home.reducer,
	showrooms: showrooms.reducer,
	profile: profile.reducer,
	lists: myLists.reducer,
	financials: financials.reducer,
	remoteConfig: remoteConfig.reducer,
	versionUpdates: versionUpdates.reducer,

	ready: readyReducer,
})))
