import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { InvoicesResponse, StatementsResponse, InvoiceType, InvoiceMetaData, StatementMetaData, InvoiceDownloadType, DownloadStatementFileType, EwayPaymentLink, GetStatementDiscountResponse, StatementBalanceItem, GetOutstandingInvoicesRequest, OutstandingInvoicesResponse } from 'typescript-fetch-api'
import { InvoiceFilter, InvoiceFilterType, StatementPayable } from './types'
import { EwayPaymentResultResponse } from 'typescript-fetch-api/models'

const actionCreator = actionCreatorFactory('Financials')

export interface GetInvoicesPayload {
	accountId: number
	fromDate: string
	toDate?: string
	productId?: string
	branchId?: number
	reference?: string
	invoiceNumber?: string
	type?: InvoiceType
	pwgoOnly?: boolean
	page?: number
	pageSize?: number
	appendToList?: boolean
}
export type GetInvoicesRequestAction = Action<GetInvoicesPayload>
export type GetInvoicesRequestSuccessAction = Action<Success<GetInvoicesPayload, InvoicesResponse>>
export const getInvoices = actionCreator.async<GetInvoicesPayload, InvoicesResponse, Error>('GET_INVOICES')

// needed a way to load next page of invoices from UI without having to pass in all the current filters from redux
// payload is the page number to load, if > 0, append results to existing array on redux
export type GetInvoicesForPageRequestAction = Action<number>
export const getInvoicesForPage = actionCreator<number>('GET_INVOICES_FOR_PAGE')

export const setInvoiceFilter = actionCreator<InvoiceFilter>('SET_INVOICE_FILTER')

export const clearInvoiceFilter = actionCreator<InvoiceFilterType>('CLEAR_INVOICE_FILTER')

export const clearAllInvoiceFilters = actionCreator('CLEAR_ALL_INVOICE_FILTERS')

export interface DownloadInvoicePayload {
	customerId: number
	invoiceId: number
	invoiceSuffixId: number
	type: InvoiceDownloadType
	date?: string
	view?: boolean // opens the downloaded file
}
export type DownloadInvoiceRequestAction = Action<DownloadInvoicePayload>
export const downloadInvoice = actionCreator.async<DownloadInvoicePayload, InvoicesResponse, Error>('DOWNLOAD_INVOICE')

export interface DownloadInvoiceAsZipPayload {
	customerId: number
	type?: InvoiceDownloadType
	invoices?: InvoiceMetaData[]
	isCombined?: boolean
}
export type DownloadInvoiceAsZipAction = Action<DownloadInvoiceAsZipPayload>
export const downloadInvoiceAsZip = actionCreator.async<DownloadInvoiceAsZipPayload, Blob, Error>('DOWNLOAD_INVOICES_AS_ZIP')

export interface GetStatementsPayload {
	accountId: number
}
export type GetStatementsRequestAction = Action<GetStatementsPayload>
export type GetStatementsRequestSuccessAction = Action<Success<GetStatementsPayload, StatementsResponse>>
export const getStatements = actionCreator.async<GetStatementsPayload, StatementsResponse, Error>('GET_STATEMENTS')

export interface DownloadStatementPayload {
	customerId: number
	statementDate: string
	type: DownloadStatementFileType
	view?: boolean // opens the downloaded file
}
export type DownloadStatementRequestAction = Action<DownloadStatementPayload>
export const downloadStatement = actionCreator.async<DownloadStatementPayload, StatementsResponse, Error>('DOWNLOAD_STATEMENT')

export interface DownloadStatementAsZipPayload {
	customerId: number
	type?: DownloadStatementFileType
	statements?: StatementMetaData[]
}
export type DownloadStatementAsZipAction = Action<DownloadStatementAsZipPayload>
export const downloadStatementAsZip = actionCreator.async<DownloadStatementAsZipPayload, Blob, Error>('DOWNLOAD_STATEMENTS_AS_ZIP')

export interface EditInvoiceCommentPayload {
	customerId: number
	invoiceId: number
	invoiceSuffixId: number
	comment: string
}
export type EditInvoiceCommentRequestAction = Action<EditInvoiceCommentPayload>
export const editInvoiceComment = actionCreator.async<EditInvoiceCommentPayload, StatementsResponse, Error>('EDIT_INVOICE_COMMENT')

export const clearInvoiceCommentError = actionCreator('CLEAR_INVOICE_COMMENT_ERROR')

export interface LoadEwayUrlPayload {
	customerId: number
	amount: number
}
export type LoadEwayUrlRequestAction = Action<LoadEwayUrlPayload>
export type LoadEwayUrlRequestSuccessAction = Action<Success<LoadEwayUrlPayload, EwayPaymentLink>>
export const loadEwayUrl = actionCreator.async<LoadEwayUrlPayload, EwayPaymentLink, Error>('LOAD_EWAY_URL')

export const updateStatementPayable = actionCreator<StatementPayable | undefined>('UPDATE_STATEMENT_PAYABLE')

export interface LoadEwayPaymentResultPayload {
	accessCode: string
}
export type LoadEwayPaymentResultAction = Action<LoadEwayPaymentResultPayload>
export const loadEwayPaymentResult = actionCreator.async<LoadEwayPaymentResultPayload, EwayPaymentResultResponse, Error>('LOAD_EWAY_PAYMENT_RESULT')

export const navigateToEwayPaymentResult = actionCreator('NAVIGATE_TO_PAYMENT_RESULT')

export interface NavigateToPaymentPayload {
	link: string
}
export type NavigateToPaymentAction = Action<NavigateToPaymentPayload>
export const navigateToPayment = actionCreator<NavigateToPaymentPayload>('NAVIGATE_TO_PAYMENT')

export interface GetStatementDiscountPayload {
	customerId: number
	statementBalanceItems: StatementBalanceItem[]
}
export type GetStatementDiscountAction = Action<GetStatementDiscountPayload>
export const getStatementDiscount = actionCreator.async<GetStatementDiscountPayload, GetStatementDiscountResponse, Error>('GET_STATEMENT_DISCOUNT')

export type GetOutstandingInvoicesAction = Action<GetOutstandingInvoicesRequest>
export const getOutstandingInvoices = actionCreator.async<GetOutstandingInvoicesRequest, OutstandingInvoicesResponse, Error>('GET_OUTSTANDING_INVOICES')