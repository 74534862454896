import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from './actions'
import { Supplier } from './types'

export interface StoreState {
	readonly suppliers: Supplier[]
	readonly appliedSupplierIdFilterIds: string[]
}

const INITIAL_STATE: StoreState = {
	suppliers: [],
	appliedSupplierIdFilterIds: [],
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

/** Reducer function for the exampleAction that returns a new state using an implicit return. */
reducer.case(actions.saveFiltersAction, (state, payload): StoreState => ({
	...state, appliedSupplierIdFilterIds: payload.filters
}))

reducer.case(actions.loadSuppliersAction.done, (state, payload): StoreState => ({
	...state, suppliers: payload.result
}))

reducer.case(actions.clearFilters, (state): StoreState => ({
	...state, appliedSupplierIdFilterIds: []
}))