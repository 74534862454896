import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { GetSearchFiltersPublicRequest, GetSearchFiltersRequest, PagedProductsResponse, SearchFilter, SearchFilterFilterTypeEnum, SearchProductsPublicRequest, SearchProductsRequest, SearchRequest } from 'typescript-fetch-api'

import * as actions from '../../common/search/actions'
import * as productsActions from '../../common/products/actions'
import { UserAccount } from '../../common/accounts/types'
import { orderBranchSelector, selectedAccountSelector } from '../../common/order/selectors'
import { getPriceParams } from '../../common/auth/functions'
import { paginationSizeSelector } from '../platform/selectors'
import { stockCountsEnabledSelector } from '../../common/remoteConfig/selectors'
import { callApi } from '../../common/api/functions'
import { getContentApi } from '../../common/api'
import { INITIAL_PAGE } from '../platform/content'
import { authTokenSelector, branchFilterPreferenceSelector } from '../../common/auth/selectors'
import * as NavigationManager from '../navigation/NavigationManager'

// 6 as the page size is to limit the search items to be displayed in the search suggestions dropdown
export const SEARCH_SUGGESTIONS_PAGE_SIZE = 6

function handleNavigateToSearchResults(action: actions.NavigateToSearchResultsAction) {
	const searchText: string | undefined = action.payload
	NavigationManager.navigateToProductSearch(searchText)
}

function* handleSearchProducts(action: actions.SearchQueryAction): SagaIterator {
	const pageSize: number = yield select(paginationSizeSelector)

	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.performQuickSearch,
			(payload: actions.SearchQuery) => {
				const requestParameters: SearchProductsPublicRequest = {
					query: payload.queryText,
					categoryId: payload.categoryId,
					page: INITIAL_PAGE,
					pageSize,
				}
				return getContentApi().searchProductsPublic(requestParameters).then(response => ({
					products: response.products || [],
					totalPagesCount: response.totalPagesCount,
					count: response.count,
				}))
			}
		)
		return
	}

	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId } = getPriceParams(selectedAccount)
	const selectedBranchId = yield select(orderBranchSelector)
	const useBranchFilter: boolean = yield select(branchFilterPreferenceSelector)

	// search filters
	const filters: SearchFilter[] = []
	if (useBranchFilter) filters.push({ id: selectedBranchId, filterType: SearchFilterFilterTypeEnum.BRANCH })

	const searchRequest: SearchRequest | undefined = filters.length > 0 ? { filters } : undefined

	// @ts-ignore - add request params to original action so that branch product counts gets correct counts for applied search filters (hnz mode)
	action.payload.searchRequest = searchRequest

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.performQuickSearch,
		(payload: actions.SearchQuery) => {
			const requestParameters: SearchProductsRequest = {
				query: payload.queryText,
				categoryId: payload.categoryId,
				page: INITIAL_PAGE,
				pageSize,
				customerId,
				searchRequest,
			}
			return getContentApi().searchProducts(requestParameters).then(response => ({
				products: response.products || [],
				totalPagesCount: response.totalPagesCount,
				count: response.count,
			}))
		}
	)
}

function* loadSearchFilters(action: actions.SearchQueryAction | actions.LoadSearchFiltersAction): SagaIterator {
	const { queryText, categoryId } = action.payload

	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.loadSearchFiltersAction,
			() => {
				const requestParameters: GetSearchFiltersPublicRequest = {
					query: queryText,
					categoryId,
				}
				return getContentApi().getSearchFiltersPublic(requestParameters)
					.then(response => response.filters || [])
			}
		)
		return
	}

	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId, includePrices } = getPriceParams(selectedAccount)

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.loadSearchFiltersAction,
		() => {
			const requestParameters: GetSearchFiltersRequest = {
				query: queryText,
				categoryId,
				customerId,
				includePrices,
			}
			return getContentApi().getSearchFilters(requestParameters)
				.then(response => response.filters || [])
		}
	)
}

/**
 * Loads the search suggestions for the search dropdown in the header.
 * @param action contains the search query
 */
function* fetchSearchSuggestions(action: actions.FetchSearchSuggestionsAction): SagaIterator {
	// Note: do not apply branch filter to search suggestions, as they may be performing search from home screen where branch filter not visible
	const searchRequest: SearchRequest | undefined = undefined

	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.fetchSearchSuggestions,
			(payload: string) => {
				const requestParameters: SearchProductsPublicRequest = {
					query: payload,
					page: INITIAL_PAGE,
					pageSize: SEARCH_SUGGESTIONS_PAGE_SIZE,
					searchRequest,
				}
				return getContentApi().searchProductsPublic(requestParameters).then(response => ({ products: response.products || [] }))
			}
		)
		return
	}

	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId, includePrices } = getPriceParams(selectedAccount)

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.fetchSearchSuggestions,
		(payload: string) => {
			const requestParameters: SearchProductsRequest = {
				query: payload,
				page: INITIAL_PAGE,
				pageSize: SEARCH_SUGGESTIONS_PAGE_SIZE,
				customerId,
				includePrices,
				branchId: undefined, // don't include stock counts in the quick search results
				searchRequest,
			}
			return getContentApi().searchProducts(requestParameters).then(response => ({ products: response.products || [] }))
		}
	)
}

/**
 * Loads the search suggestions for the search dropdown in the list or label group details page
 * Note: a PW list is either a PRODUCT list or LABEL list
 * @param action contains the search query
 */
function* fetchSearchSuggestionsForList(action: actions.SearchProductsForListAction): SagaIterator {
	// NB: don't need prices as we don't display price in the search results
	if (!action.payload.query) {
		const searchProductsForListResponse: actions.SearchProductsForListResponse = {
			products: [],
			page: undefined,
			totalPages: undefined,
		}
		yield put(actions.searchProductsForList.done({ params: action.payload, result: searchProductsForListResponse }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.searchProductsForList,
			(payload: actions.SearchProductsForListPayload) => {
				const requestParameters: SearchProductsRequest = {
					query: payload.query,
					page: payload.page ? payload.page : INITIAL_PAGE,
					pageSize: SEARCH_SUGGESTIONS_PAGE_SIZE,
					includePrices: false,
				}
				return getContentApi().searchProducts(requestParameters).then(response => {
					const searchProductsForListResponse: actions.SearchProductsForListResponse = {
						products: response.products || [],
						page: response.page,
						totalPages: response.totalPagesCount,
					}
					return searchProductsForListResponse
				})
			})
	}
}

/**
 * Loads the products based on changes in the filter.
 * 
 * NOTE: we trigger 2 different actions which handle saving the products in the right reducer:
 * 1 - search: `performQuickSearch`
 * 2 - usual products: `loadProducts`
 * 
 * @param action the action containing the filter/s
 */
function* handleFilterChanged(action: actions.FilterQueryAction): SagaIterator {
	const { queryText, categoryId, page: payloadPage, searchRequest } = action.payload
	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId } = getPriceParams(selectedAccount)
	const page: number = payloadPage || INITIAL_PAGE
	const pageSize: number = yield select(paginationSizeSelector)
	const stockCountsEnabled: boolean = yield select(stockCountsEnabledSelector)
	const selectedBranchId: number | undefined = stockCountsEnabled ? yield select(orderBranchSelector) : undefined

	if (queryText) {
		// user filtered by product range/branch stock, we dispatch a separate action to show a loading state on the search screen
		if (selectedBranchId !== undefined) {
			yield put(actions.searchInProgressAction())
		}

		// setup request and payload based on user's auth
		let request: Promise<PagedProductsResponse>
		let requestParameters: SearchProductsPublicRequest = {
			query: queryText,
			categoryId,
			page,
			pageSize,
			searchRequest,
		}
		if (hasAuthToken) {
			requestParameters = {
				...requestParameters,
				customerId,
			} as SearchProductsRequest
			request = getContentApi().searchProducts(requestParameters)
		} else {
			request = getContentApi().searchProductsPublic(requestParameters)
		}

		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.performQuickSearch, () => (
				request.then(response => ({
					products: response.products || [],
					totalPagesCount: response.totalPagesCount,
					count: response.count,
				}))
			)
		)
	} else if (categoryId) {
		// we check for the specific products page being loaded based on the router path
		const savedCategoryId: string = categoryId || '' // we default to an empty string for Kainga Ora/HNZ mode
		const saveFilterPayload = {
			categoryId: savedCategoryId,
			page,
			pageSize,
			filters: (searchRequest && searchRequest.filters) || undefined
		}

		// we save the product filter and setup the search action depending on the current path
		const searchAction = productsActions.loadProducts
		yield put(productsActions.saveProductsFilter(saveFilterPayload))

		// user filtered by product range/branch stock, we dispatch a separate action to show a loading state on the products screen
		if (selectedBranchId !== undefined) {
			yield put(productsActions.setLoadingProductsState())
		}

		// setup request and payload based on user's auth
		let request: Promise<PagedProductsResponse>
		let requestParameters: SearchProductsPublicRequest = {
			query: queryText,
			categoryId,
			page,
			pageSize,
			searchRequest,
		}
		if (hasAuthToken) {
			requestParameters = {
				...requestParameters,
				customerId,
			} as SearchProductsRequest
			request = getContentApi().searchProducts(requestParameters)
		} else {
			request = getContentApi().searchProductsPublic(requestParameters)
		}

		yield call(
			// @ts-ignore API
			callApi,
			action,
			searchAction, () => (
				request.then(response => ({ products: response.products || [], totalProductPagesCount: response.totalPagesCount }))
			)
		)
	}
}

/**
 * Handles loading the filters (categories and suppliers) on successful search
 * @param action the action containing the search details
 */
function* loadSearchFiltersOnSearchSuccess(action: actions.PerformQuickSearchSuccess): SagaIterator {
	const payload = action.payload.params as actions.FilterQueryPayload
	const { queryText, categoryId } = payload

	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.loadSearchFiltersAction,
			() => {
				const requestParameters: GetSearchFiltersPublicRequest = {
					query: queryText,
					categoryId,
				}
				return getContentApi().getSearchFiltersPublic(requestParameters)
					.then(response => response.filters || [])
			}
		)
		return
	}

	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId, includePrices } = getPriceParams(selectedAccount)

	const branchFilter = payload.searchRequest && payload.searchRequest.filters && payload.searchRequest.filters.find(item => item.filterType === SearchFilterFilterTypeEnum.BRANCH)
	const branchId = branchFilter && branchFilter.id ? parseInt(branchFilter.id, 10) : undefined

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.loadSearchFiltersAction,
		() => {
			const requestParameters: GetSearchFiltersRequest = {
				query: queryText,
				categoryId,
				customerId,
				includePrices,
				branchId,
			}
			return getContentApi().getSearchFilters(requestParameters)
				.then(response => response.filters || [])
		}
	)
}

export default function* (): SagaIterator {
	yield takeEvery(actions.navigateToSearchResults, handleNavigateToSearchResults)
	yield takeLatest(actions.textChangeAction, handleSearchProducts)
	yield takeLatest(actions.loadSearchFiltersAction.started, loadSearchFilters)
	yield takeLatest(actions.performQuickSearch.done, loadSearchFiltersOnSearchSuccess)
	yield takeLatest(actions.fetchSearchSuggestions.started, fetchSearchSuggestions)
	yield takeLatest(actions.searchProductsForList.started, fetchSearchSuggestionsForList)
	yield takeLatest(actions.filterChangeAction, handleFilterChanged)
}