import { SagaIterator } from 'redux-saga'
import { put, takeEvery, select, call, takeLatest } from 'redux-saga/effects'
import { RESET_STATE } from '@redux-offline/redux-offline/lib/constants'
import * as Api from 'typescript-fetch-api'

import { store } from '../../common/root'
import { backSignInStack, loggedOut, login, LoginRequestFailureAction, navigateToForgotPassword, navigateToRegister, preloggedOut } from '../../common/auth/actions'
import { oAuthConfig, apiTimeout, getAppServerRootURL, ApiErrorMessage } from '../../common/api'
import { authTokenSelector } from '../../common/auth/selectors'
import { AuthToken } from '../../common/auth/types'
import { deregisterNotificationToken, navigateToTab } from '../platform/actions'
import { checkImpersonatorToken, CheckImpersonatorTokenAction } from './actions'
import * as NavigationManager from '../navigation/NavigationManager'

/**
 * Handles all the necessary logout actions when a user has performed notification de-registration.
 */
function* handleLoggedOut(): SagaIterator {

	// grabs the refresh token from the saved auth details in the store
	const refreshToken: string = ((yield select(authTokenSelector)) as AuthToken).refresh_token!

	// if impersonating user there will be no refresh token as the access token can only be used for as long as its valid period (need to impersonate user again to get new token)
	if (refreshToken) {
		yield call(signout, refreshToken)
	}

	// NB still log user out even if failed to invalidate token as theres nothing else they can do if token was deleted on server
	// dispatch an action to reset the offline redux state
	store.dispatch({ type: RESET_STATE })

	// dispatch the loggedOut action
	yield put(loggedOut())
}

/**
 * Handles a user signing out, invalidating the refresh token.
 * @param refreshToken auth refresh token
 */
function signout(refreshToken: string): Promise<boolean> {
	// construct new oAuthApi since sign out needs the access token for checking a user
	const configuration = new Api.Configuration({
		basePath: getAppServerRootURL(),
		accessToken: oAuthConfig.accessToken,
		fetchApi: (input: RequestInfo, init?: RequestInit | undefined): Promise<Response> => {
			const url: string = (input as Request).url || (input as string)
			return apiTimeout(url, init)
		},
	})
	const oAuthApi = new Api.OauthApi(configuration)

	return oAuthApi.signOut({ refreshToken })
		.then(() => true)
		.catch((error: Error) => {
			console.log('Error signing the user out', error)
			return false
		})
}

export function handleNavigateToSignIn() {
	NavigationManager.navigateToSignIn()
}

function handleNavigateToRegister() {
	NavigationManager.navigateToRegister()
}

function handleNavigateToResetPassword() {
	NavigationManager.navigateToResetPassword()
}

function handleLoginFailed(action: LoginRequestFailureAction) {
	// check if login failed because user is not verified/enabled - we want to take them to the verify/resetPassword screen
	if (action.payload.error.message === ApiErrorMessage.USER_IS_DISABLED_MESSAGE) {
		NavigationManager.navigateToVerifyAccount()
	}
}

/**
 * Had to extract this code out into function to work nicely within a saga
 * @param token the impersonated token
 */
function getProfileOfImpersonatedUser(token: string): Promise<boolean> {
	// construct new UserApi so we can attempt to use the given access token before setting it on our redux store
	const config = new Api.Configuration({
		basePath: getAppServerRootURL(),
		accessToken: () => {
			return 'Bearer ' + token
		},
		fetchApi: (input: RequestInfo, init?: RequestInit | undefined): Promise<Response> => {
			const url: string = (input as Request).url || (input as string)
			return apiTimeout(url, init)
		},
	})
	const userApi = new Api.UserApi(config)

	// attempt to get users profile to test if access token is valid
	return userApi.getAccounts()
		.then(_ => {
			// token valid
			return true
		}).catch(response => {
			if (response instanceof Response) {
				if (response.status === 401) {
					// invalid token
					throw Error('Invalid impersonator token')
				}
			}
			throw Error('An error occurred trying to impersonate user')
		})
}

function* handleCheckImpersonator(action: CheckImpersonatorTokenAction): SagaIterator {
	const { token, username } = action.payload

	try {
		const success = yield call(getProfileOfImpersonatedUser, token)
		if (success) {
			// we are changing to the impersonated user by setting access token as if we just logged in as that user
			yield put(login.done({ params: {}, result: { access_token: token, impersonator_username: username } }))
		}
	} catch (e) {
		console.log('handleCheckImpersonator e', e)
		// TODO display error message on the impersonate screen, or kick them back to home screen?
		// log user out if they are logged in as someone else
		yield put(preloggedOut())
		yield put(navigateToTab({ tab: 'home' }))
	}
}

export default function* (): SagaIterator {
	yield takeEvery([deregisterNotificationToken.done, deregisterNotificationToken.failed], handleLoggedOut)
	yield takeEvery(navigateToRegister, handleNavigateToRegister)
	yield takeEvery(navigateToForgotPassword, handleNavigateToResetPassword)
	yield takeLatest(backSignInStack, handleNavigateToSignIn)
	yield takeEvery(loggedOut, handleNavigateToSignIn) // if user logged out
	yield takeEvery(login.failed, handleLoginFailed)
	yield takeEvery(checkImpersonatorToken, handleCheckImpersonator)
}
