import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { ProductPrices, SearchFilter, BranchProductDetail } from 'typescript-fetch-api'

import * as actions from './actions'
import { loadProductsCount, resetProductsTab } from '../products/actions'
import { PlatformProduct } from '../../modules/platform'
import { loggedOut } from '../auth/actions'
import { getPlatformSupportImplementation } from '../platform'
import { TotalProductsCount } from '../products/types'
import { updateTotalProductsCount, updateTotalProductsCountLoadingState } from '../products/functions'
import { addProductToList, removeProductFromList } from '../mylists/actions'

export interface StoreState {
	readonly searchText?: string
	readonly searchResults?: ReadonlyArray<PlatformProduct> // need to store the Join table because we want to return Realm Proxy object without iterating through items
	readonly quickSearchResults?: ReadonlyArray<PlatformProduct>
	readonly searchResultPrices: ProductPrices[]
	readonly searchResultStockCounts: BranchProductDetail[]
	readonly categoryId?: string // essentially which screen's toolbar started the search so the results only get displayed on one screen (not three)
	readonly searchResultFilters?: SearchFilter[]
	readonly totalPagesCount?: number
	readonly searchResultsCount?: number
	readonly fetchingSearchResults: boolean
	readonly errorFetchingSearchResults?: Error
	readonly fetchingQuickSearchResults: boolean
	readonly errorFetchingQuickSearchResults?: Error
	readonly fetchingSearchResultStockCount: boolean
	readonly fetchingSearchResultPrices: boolean

	readonly searchedProductsPage?: number
	readonly searchedProductsPageCount?: number

	readonly searchedProductsForList: ReadonlyArray<PlatformProduct>
	readonly searchedProductsPageForList?: number
	readonly searchedProductsPageCountForList?: number
	readonly fetchingProductsForList: boolean
	readonly errorFetchingProductsForList?: Error

	// array of product skus being added to list by quick search so we can display a loading spinner for each product
	readonly productsBeingAddedToList: string[]

	readonly productsCount: TotalProductsCount
}

const INITIAL_STATE: StoreState = {
	/* Note that we end each property with a comma, so we can add new properties without modifying this line
	(improve your git diffs!).
	 */
	searchText: undefined,
	searchResults: undefined,
	quickSearchResults: undefined,
	searchResultPrices: [],
	searchResultStockCounts: [],
	categoryId: undefined,
	searchResultFilters: undefined,
	totalPagesCount: undefined,
	searchResultsCount: undefined,
	fetchingSearchResults: false,
	errorFetchingSearchResults: undefined,
	fetchingQuickSearchResults: false,
	errorFetchingQuickSearchResults: undefined,
	fetchingSearchResultStockCount: false,
	fetchingSearchResultPrices: false,

	searchedProductsPage: undefined,
	searchedProductsPageCount: undefined,

	searchedProductsForList: [],
	searchedProductsPageForList: undefined,
	searchedProductsPageCountForList: undefined,
	fetchingProductsForList: false,
	errorFetchingProductsForList: undefined,

	productsBeingAddedToList: [],

	productsCount: {
		allProductsCount: undefined,
		branchProductsCount: undefined,
		allProductsParams: undefined,
		branchProductsParams: undefined,
		loadingAllProductsCount: false,
		loadingBranchProductsCount: false,
	},
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

/** Reducer function for the exampleAction that returns a new state using an implicit return. */
reducer.case(actions.textChangeAction, (state, payload): StoreState => {
	const hasUpdatedQuery: boolean = state.searchText !== payload.queryText
	if (hasUpdatedQuery) {
		return {
			...state,
			searchText: payload.queryText,
			searchResults: undefined,
			searchResultFilters: undefined,
			totalPagesCount: undefined,
			searchResultsCount: undefined,
			fetchingSearchResults: true,
			errorFetchingSearchResults: undefined,
		}
	}
	return state
})

reducer.case(actions.performQuickSearch.done, (state, payload): StoreState => {
	// we want to store what screen/toolbar requested the search be done because there can be up to 3 screens all listening for search results (CategoriesList x 2, Products).
	// this was resulting in all 3 screens showing the search results even though they might not be visible.
	return {
		...state,
		searchResults: payload.result.products,
		categoryId: payload.params ? payload.params.categoryId : undefined,
		totalPagesCount: payload.result.totalPagesCount,
		searchResultsCount: payload.result.count,
		fetchingSearchResults: false,
	}
})

reducer.case(actions.performQuickSearch.failed, (state, payload): StoreState => ({
	...state, totalPagesCount: undefined, searchResultsCount: undefined, fetchingSearchResults: false, errorFetchingSearchResults: payload.error
}))

reducer.case(actions.fetchSearchSuggestions.started, (state): StoreState => ({
	...state, fetchingQuickSearchResults: true, quickSearchResults: undefined, errorFetchingQuickSearchResults: undefined,
}))
reducer.case(actions.fetchSearchSuggestions.done, (state, { result }): StoreState => {
	// only show results if loading flag still true (user might have cancelled the request by pressing Enter to show full screen results)
	if (state.fetchingQuickSearchResults) {
		return {
			...state, fetchingQuickSearchResults: false, quickSearchResults: result.products,
		}
	}
	return state
})
reducer.case(actions.fetchSearchSuggestions.failed, (state, { error }): StoreState => ({
	...state, fetchingQuickSearchResults: false, errorFetchingQuickSearchResults: error,
}))
reducer.case(actions.navigateToSearchResults, (state): StoreState => ({
	...state, fetchingQuickSearchResults: false, // set loading flag to false so quick search loading spinner not shown
}))

reducer.case(actions.loadSearchFiltersAction.started, (state, payload): StoreState => ({
	...state, categoryId: payload.categoryId, searchText: payload.queryText, searchResultFilters: undefined,
}))

reducer.case(actions.loadSearchFiltersAction.done, (state, payload): StoreState => ({
	...state, searchResultFilters: payload.result,
}))

reducer.case(actions.loadSearchPrices.started, (state): StoreState => ({
	...state, fetchingSearchResultPrices: true,
}))
reducer.case(actions.loadSearchPrices.done, (state, { params, result }): StoreState => {
	const searchResultPrices = result.prices || []

	// loop through products and append pricing (on web only)
	const searchResults: ReadonlyArray<PlatformProduct> | undefined = state.searchResults && getPlatformSupportImplementation().appendPricingToProducts ? getPlatformSupportImplementation().appendPricingToProducts!(state.searchResults, searchResultPrices) : state.searchResults

	if (params.appendToList) {
		return {
			...state,
			searchResultPrices: [...state.searchResultPrices, ...searchResultPrices],
			fetchingSearchResultPrices: false,
			searchResults,
		}
	}
	return {
		...state,
		searchResultPrices,
		fetchingSearchResultPrices: false,
		searchResults,
	}
})
reducer.case(actions.loadSearchPrices.failed, (state): StoreState => ({
	...state, fetchingSearchResultPrices: false,
}))

reducer.case(actions.searchCancelledAction, (): StoreState => ({
	...INITIAL_STATE
}))

/**
 * LIST/LABEL SEARCH
 */

reducer.case(actions.searchProductsForList.started, (state, payload): StoreState => {
	return {
		...state,
		fetchingProductsForList: true,
		// if loading next page, keep previous results (append to the array)
		searchedProductsForList: !payload.page ? [] : state.searchedProductsForList,
		searchedProductsPageForList: undefined,
		searchedProductsPageCountForList: undefined,
		errorFetchingProductsForList: undefined,
	}
})

reducer.case(actions.searchProductsForList.done, (state, { params, result }): StoreState => {
	return {
		...state,
		fetchingProductsForList: false,
		// if loading next page, append current results to the array
		searchedProductsForList: !params.page
			? result.products
			: state.searchedProductsForList.concat(result.products),
		searchedProductsPageForList: result.page,
		searchedProductsPageCountForList: result.totalPages,
	}
})

reducer.case(actions.searchProductsForList.failed, (state, { params, error }): StoreState => {
	return {
		...state,
		fetchingProductsForList: false,
		errorFetchingProductsForList: error,
	}
})

reducer.case(actions.clearSearchedProductsForList, (state, productListType): StoreState => {
	return {
		...state,
		searchedProductsForList: [],
		searchedProductsPageForList: undefined,
		searchedProductsPageCountForList: undefined,
		fetchingProductsForList: false,
		errorFetchingProductsForList: undefined,
	}
})

reducer.case(actions.searchInProgressAction, (state): StoreState => ({
	...state,
	searchResults: INITIAL_STATE.searchResults,
	totalPagesCount: INITIAL_STATE.totalPagesCount,
	searchResultsCount: INITIAL_STATE.searchResultsCount,
	fetchingSearchResults: true,
	errorFetchingSearchResults: INITIAL_STATE.errorFetchingSearchResults,
}))

// FETCHING STOCK COUNTS
reducer.case(actions.getSearchProductsAvailability.started, (state): StoreState => ({
	...state,
	fetchingSearchResultStockCount: true,
}))
reducer.case(actions.getSearchProductsAvailability.done, (state, { params, result }): StoreState => {
	const searchResultStockCounts = result.products || []

	// loop through products and append availability (on web only)
	const searchResults: ReadonlyArray<PlatformProduct> | undefined = state.searchResults && getPlatformSupportImplementation().appendAvailabilityToProducts ? getPlatformSupportImplementation().appendAvailabilityToProducts!(state.searchResults, searchResultStockCounts) : state.searchResults

	if (params.appendToList) {
		return {
			...state,
			searchResultStockCounts: [...state.searchResultStockCounts, ...searchResultStockCounts],
			fetchingSearchResultStockCount: false,
			searchResults,
		}
	}
	return {
		...state,
		searchResultStockCounts,
		fetchingSearchResultStockCount: false,
		searchResults,
	}
})
reducer.case(actions.getSearchProductsAvailability.failed, (state): StoreState => ({
	...state,
	fetchingSearchResultStockCount: false,
}))

reducer.cases([resetProductsTab, loggedOut], (): StoreState => {
	/**
	 * we clear the search results in the following cases:
	 * - when the user updates products (clear out any old Realm objects)
	 * - when the user logs out
	 */
	return INITIAL_STATE
})

// FETCHING PRODUCT COUNTS
reducer.case(loadProductsCount.started, (state, params): StoreState => {
	if (params.queryText) {
		const updatedResult = updateTotalProductsCountLoadingState(params, true, state.productsCount)
		if (updatedResult) {
			return ({
				...state, productsCount: updatedResult,
			})
		}
	}
	return state
})
reducer.case(loadProductsCount.done, (state, { params, result }): StoreState => {
	if (params.queryText) {
		const updatedResult = updateTotalProductsCount(params, result, state.productsCount)
		if (updatedResult) {
			return ({
				...state, productsCount: updatedResult,
			})
		}
	}
	return state
})
reducer.case(loadProductsCount.failed, (state, { params }): StoreState => {
	if (params.queryText) {
		const updatedResult = updateTotalProductsCountLoadingState(params, false, state.productsCount)
		if (updatedResult) {
			return ({
				...state, productsCount: updatedResult,
			})
		}
	}
	return state
})

// add/remove product to list
reducer.cases([addProductToList.started, removeProductFromList.started], (state, payload): StoreState => {
	if (payload.screen === 'List Details') {
		const productsBeingAddedToList = [...state.productsBeingAddedToList]
		productsBeingAddedToList.push(payload.sku)
		return {
			...state,
			productsBeingAddedToList,
		}
	}
	return state
})
reducer.cases([addProductToList.done, removeProductFromList.done, addProductToList.failed, removeProductFromList.failed], (state, { params }): StoreState => {
	if (params.screen === 'List Details') {
		const productsBeingAddedToList = state.productsBeingAddedToList.filter(sku => sku !== params.sku)
		return {
			...state,
			productsBeingAddedToList,
		}
	}
	return state
})