import { getAssetsServerRootURL, getAboutServerRootURL } from '../../common/api'
import { GeneralPathsEnum, SupportPathsEnum, TradePathsEnum, PSCOOPPathsEnum, HowToContent } from './types'

export const GeneralScreens: Record<GeneralPathsEnum, { title: string, path: GeneralPathsEnum }> = {
	[GeneralPathsEnum.PRIVATE_POLICY]: {
		title: 'Privacy Policy',
		path: GeneralPathsEnum.PRIVATE_POLICY,
	},
	[GeneralPathsEnum.TERMS_AND_CONDITIONS]: {
		title: 'Terms and Conditions',
		path: GeneralPathsEnum.TERMS_AND_CONDITIONS,
	},
	[GeneralPathsEnum.TERMS_OF_TRADE]: {
		title: 'Terms of Trade',
		path: GeneralPathsEnum.TERMS_OF_TRADE,
	},
}

export const SupportScreens: Record<SupportPathsEnum, { title: string, path: SupportPathsEnum }> = {
	[SupportPathsEnum.DELIVERY_POLICY]: {
		title: 'Delivery Policy',
		path: SupportPathsEnum.DELIVERY_POLICY,
	},
	[SupportPathsEnum.ONLINE_RETURNS_POLICY]: {
		title: 'Online Returns Policy',
		path: SupportPathsEnum.ONLINE_RETURNS_POLICY,
	},
	[SupportPathsEnum.PAYMENTS_POLICY]: {
		title: 'Payments Policy',
		path: SupportPathsEnum.PAYMENTS_POLICY,
	},
	[SupportPathsEnum.ACCOUNT_DELETE]: {
		title: 'Account Deletion',
		path: SupportPathsEnum.ACCOUNT_DELETE,
	},
	[SupportPathsEnum.HOW_TO]: {
		title: 'How-To',
		path: SupportPathsEnum.HOW_TO,
	},
}

export const TradeScreens: Record<TradePathsEnum, { title: string, path: TradePathsEnum }> = {
	[TradePathsEnum.OPEN_TRADE_ACCOUNT]: {
		title: 'Open a Trade Account',
		path: TradePathsEnum.OPEN_TRADE_ACCOUNT,
	},
	[TradePathsEnum.MEMBERSHIP_BENEFITS]: {
		title: 'Membership Benefits',
		path: TradePathsEnum.MEMBERSHIP_BENEFITS,
	},
}

export const PSCOOPScreens: Record<PSCOOPPathsEnum, { title: string, path: PSCOOPPathsEnum }> = {
	[PSCOOPPathsEnum.CAREERS]: {
		title: 'Careers',
		path: PSCOOPPathsEnum.CAREERS,
	},
	[PSCOOPPathsEnum.ABOUT_US]: {
		title: 'About Us',
		path: PSCOOPPathsEnum.ABOUT_US,
	},
}

export const ABOUT_US__IMAGE_URLS: Record<string, string> = {
	began: `${getAboutServerRootURL()}began-img-1.png`,
	warehouse: `${getAboutServerRootURL()}1st_warehouse_Bray_lane_St_Peters.jpeg`,
	andrew_murphy: `${getAboutServerRootURL()}Andrew_Murphy.jpeg`,
	cameron_muir: `${getAboutServerRootURL()}Cameron_Muir.jpeg`,
	new_branch: `${getAboutServerRootURL()}New_Branch_Beresfield.jpeg`,
	paul_bull: `${getAboutServerRootURL()}Paul_Bull.jpeg`,
	peter_mcpherson: `${getAboutServerRootURL()}Peter_McPherson.jpeg`,
	tony_fordward: `${getAboutServerRootURL()}Tony_Fordward.jpeg`,
}

export const PW_SERVICE_DESK_URL = 'servicedesk@plumbingworld.co.nz'
export const TRADE_TERMS_URL = 'http://images2.plumbingworld.co.nz/Forms/Terms%20of%20Trade.pdf'
export const PW_LTD = 'privacy@plumbingworld.co.nz'

const VIDEOS_URL = getAssetsServerRootURL() + 'Videos/'
export const HowToContentItems: HowToContent[] = [
	{
		id: 0,
		title: 'Acount Access',
		content: [
			'Navigate to your Profile',
			'Adding an Account',
			'Choices your business Owner makes',
		],
		videoUrl: VIDEOS_URL + 'CN-ACCOUNT-ACCESS.mp4',
	},
	{
		id: 1,
		title: 'Branch Selection – Quick',
		content: [
			'Finding the branch selection icon on the Home screen',
			'Selecting a branch from the list',
			'Checking the branch has changed',
		],
		videoUrl: VIDEOS_URL + 'CN-CHANGE-BRANCH.mp4',
	},
	{
		id: 2,
		title: 'Branch Selection – Detailed',
		content: [
			'Find the Branch Selection tile in your Profile',
			'Find the Branch Selection icon on the Home page',
			'Find the Branch Selection tile in the Checkout',
			'Select a Branch from a list',
			'Select a Branch from a map',
		],
		videoUrl: VIDEOS_URL + 'BranchSelection.mp4',
	},
	{
		id: 3,
		title: 'Download/Register',
		content: [
			'Downloading the app',
			'Self register in the app',
			'Request access to an account',
			'Grant access to an account',
			'Check access to an account',
		],
		videoUrl: VIDEOS_URL + 'Download-Register.mp4',
	},
	{
		id: 4,
		title: 'Filtering Products',
		content: [
			'Navigate to the product lists',
			'Apply a Filter',
			'Change a Filter',
			'Clear a Filter',
			'Determine if a Filter is on / off',
		],
		videoUrl: VIDEOS_URL + 'CN-FILTERS.mp4',
	},
	{
		id: 5,
		title: 'Finding Help',
		content: [
			'Navigate to the Support screen',
			'Review the list of videos',
			'Select a topic to view',
		],
		videoUrl: VIDEOS_URL + 'CN-HELP.mp4',
	},
	{
		id: 6,
		title: 'Make A Payment',
		content: [
			'Pay the balance',
			'Pay your last statement',
			'Pay any amount',
			'Make the payement',
		],
		videoUrl: VIDEOS_URL + 'Payment.mp4',
	},
	{
		id: 7,
		title: 'Ordering',
		content: [
			'Navigate the product groups',
			'Search for a product',
			'Add to the cart',
			'Move the cart to the checkout',
			'Delivery or collection details',
			'Confirm the order',
		],
		videoUrl: VIDEOS_URL + 'Ordering.mp4',
	},
	{
		id: 8,
		title: 'Prices',
		content: [
			'Tell the difference between viewing Retail (RRP) and My (business) Price',
			'Change from RRP to My Price',
			'Change from My Price to RRP',
			'Set your staff up to see either RRP or My Price',
			'Remove the display of prices in the app',
		],
		videoUrl: VIDEOS_URL + 'CN-PRICES.mp4',
	},
	{
		id: 9,
		title: 'Refresh Data',
		content: [
			'Access your profile',
			'Refresh Products data to see the latest products offered',
			'Refresh Order data to see all the orders on your account',
		],
		videoUrl: VIDEOS_URL + 'DataRefresh.mp4',
	},
	{
		id: 10,
		title: 'Reordering Prior Orders',
		content: [
			'Find previous orders that you have placed in the app',
			'Find previous orders placed by anyone on your account',
			'Convert a prior order to a new order',
		],
		videoUrl: VIDEOS_URL + 'ReorderingOrders.mp4',
	},
	{
		id: 11,
		title: 'Reset Password',
		content: [
			'Reset your own password using your recorded phone number to gain access to the app',
		],
		videoUrl: VIDEOS_URL + 'ResetPassword.mp4',
	},
	{
		id: 12,
		title: 'Searching',
		content: [
			'3 ways to access Search',
			'3 ways to search',
			'Using search results',
		],
		videoUrl: VIDEOS_URL + 'CN-SEARCH.mp4',
	},
	{
		id: 13,
		title: 'Top Products',
		content: [
			'Find your list of top products that your organisation purchases',
			'Select items from the list',
		],
		videoUrl: VIDEOS_URL + 'CN-TOP-PRODS.mp4',
	},
	{
		id: 14,
		title: 'Using Lists',
		content: [
			'Find a list',
			'Convert a list to a cart',
			'Move the cart to the checkout',
			'Confirm the order',
			'Add an item to a list',
			'Create a new list',
		],
		videoUrl: VIDEOS_URL + 'UsingLists.mp4',
	},
	{
		id: 15,
		title: 'View Products',
		content: [
			'Navigate to the product lists',
			'Change the view to see products in a Grid',
			'Change the view to see products in a List',
		],
		videoUrl: VIDEOS_URL + 'CN-GRIDS.mp4',
	},
]