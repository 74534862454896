import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { CarouselPlatform, Product, ProductPricesResponse } from 'typescript-fetch-api'

import * as actions from './actions'
import { callApi } from '../api/functions'
import { getContentApi } from '../api'
import { getSortedData } from '../util/functions'
import { getPlatformSupportImplementation } from '../platform'
import { UserAccount } from '../accounts/types'
import { selectedAccountSelector } from '../order/selectors'

function* handleFetchCarousel(action: actions.FetchCarouselAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.fetchCarousel,
		(platform: CarouselPlatform) => getContentApi().getCarousel({ platform, active: true })
			.then(result => {
				const carouselItems = result.items || []
				return {
					...result,
					items: getSortedData(carouselItems, 'ordinal'),
				}
			})
	)
}

/**
 * Performs a chain of API calls to fetch the product offers and their pricing.
 * @param action the action to fetch product offers
 */
function* handleFetchProductOffers(action: actions.FetchProductOffersAction): SagaIterator {
	try {
		// 1 - make call to load product offers
		const allProductOffers: Product[] = yield call(() => getContentApi().getProductOffers())
		// only grab 2 from the list
		let productOffers = allProductOffers.slice(0, 2)

		// 2 - make call to load pricing
		let customerId: number | undefined
		if (action.payload.customerId) {
			customerId = action.payload.customerId
		} else {
			const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
			customerId = selectedAccount?.customerId
		}
		const productPrices: ProductPricesResponse = yield call(() => getContentApi().getProductPrices({
			productPriceRequestList: {
				products: productOffers.map(item => ({ sku: item.sku })),
			},
			customerId,
		}))

		// 3 - map pricing to product offers
		productOffers = productOffers.map(productOffer => {
			const productOfferPrices = productPrices.prices?.find(productPrice => productPrice.sku === productOffer.sku)
			return {
				...productOffer,
				recommendedRetailPrice: productOfferPrices?.recommendedRetailPrice,
				recommendedRetailPriceGst: productOfferPrices?.recommendedRetailPriceGst,
				accountPrice: productOfferPrices?.accountPrice,
				accountPriceGst: productOfferPrices?.accountPriceGst,
			}
		})


		// 4 - map product offers to platform products
		yield put(actions.fetchProductOffers.done({
			params: action.payload,
			result: productOffers.map(item => getPlatformSupportImplementation().mapApiProductToPlatformProduct(item)),
		}))

	} catch (error) {
		yield put(actions.fetchProductOffers.failed({ params: action.payload, error: error as Error }))
	}
}

export default function* (): SagaIterator {
	yield takeEvery(actions.fetchCarousel.started, handleFetchCarousel)
	yield takeEvery(actions.fetchProductOffers.started, handleFetchProductOffers)
}