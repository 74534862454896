import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { Invoice, Statement, Branch } from 'typescript-fetch-api'
import { EwayPaymentResultResponse } from 'typescript-fetch-api/models'

import * as actions from './actions'
import * as authActions from '../auth/actions'
import * as rootActions from '../root/actions'
import { InvoiceFilter, StatementDiscount, StatementPayable } from './types'

export interface StoreState {
	readonly invoices?: Array<Invoice>
	readonly invoiceTotalPages?: number
	readonly invoiceBranches: Branch[]
	readonly invoiceFilters: InvoiceFilter[]
	readonly totalInvoiceCount?: number
	readonly errorGetInvoices?: Error
	readonly loadingMessageGetInvoices?: string
	readonly downloadingInvoices: Array<string>
	readonly downloadInvoiceAsZipLoading: boolean | undefined
	readonly downloadInvoiceAsZipSuccess: boolean | undefined
	readonly downloadInvoiceAsZipError: Error | undefined

	readonly statements?: Array<Statement>
	readonly downloadingStatementDates: Array<string>
	readonly errorDownloadStatement?: Error
	readonly errorGetStatements?: Error
	readonly loadingMessageGetStatements?: string
	readonly downloadStatementAsZipLoading: boolean | undefined
	readonly downloadStatementAsZipSuccess: boolean | undefined
	readonly downloadStatementAsZipError: Error | undefined
	readonly statementDiscount?: StatementDiscount
	readonly outstandingInvoices?: Invoice[]
	readonly loadingMessageGetOutstandingInvoices?: string
	readonly statementPayable?: StatementPayable

	readonly errorFetchingPaymentUrl?: Error
	readonly loadingMessageGetPaymentUrl?: string
	readonly paymentUrl?: string

	readonly errorFetchingPaymentResult?: Error
	readonly loadingMessageGetPaymentResult?: string
	readonly paymentResult?: EwayPaymentResultResponse

	readonly savingComment: boolean
	readonly errorEditingComment?: Error
}

const INITIAL_STATE: StoreState = {
	invoices: undefined,
	invoiceTotalPages: undefined,
	invoiceBranches: [],
	invoiceFilters: [],
	totalInvoiceCount: undefined,
	errorGetInvoices: undefined,
	downloadingInvoices: [],
	downloadInvoiceAsZipLoading: undefined,
	downloadInvoiceAsZipSuccess: undefined,
	downloadInvoiceAsZipError: undefined,

	statements: undefined,
	errorGetStatements: undefined,
	downloadingStatementDates: [],
	errorDownloadStatement: undefined,
	downloadStatementAsZipLoading: undefined,
	downloadStatementAsZipSuccess: undefined,
	downloadStatementAsZipError: undefined,
	statementDiscount: undefined,
	outstandingInvoices: undefined,
	loadingMessageGetOutstandingInvoices: undefined,
	statementPayable: undefined,

	errorFetchingPaymentUrl: undefined,
	loadingMessageGetPaymentUrl: undefined,
	paymentUrl: undefined,

	savingComment: false,
	errorEditingComment: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	.case(actions.getInvoices.done, (state, { params, result }): StoreState => {
		const invoiceBranches = result.branches || []
		const invoices = params.appendToList ? [...state.invoices || [], ...result.invoices || []] : result.invoices
		return {
			...state,
			invoices,
			totalInvoiceCount: result.totalInvoiceCount,
			errorGetInvoices: undefined,
			loadingMessageGetInvoices: undefined,
			invoiceBranches,
			invoiceTotalPages: result.count,
		}
	})
	.case(actions.getInvoices.failed, (state, { error }): StoreState => {
		return {
			...state,
			totalInvoiceCount: undefined,
			errorGetInvoices: error,
			loadingMessageGetInvoices: undefined,
			invoiceTotalPages: undefined,
		}
	})
	.case(actions.getInvoices.started, (state, payload): StoreState => {
		return {
			...state,
			totalInvoiceCount: undefined,
			errorGetInvoices: undefined,
			loadingMessageGetInvoices: 'Loading invoices…',
			invoices: payload.appendToList ? state.invoices : undefined, // clear invoices so loading/error message is shown
			invoiceBranches: payload.appendToList ? state.invoiceBranches : [],
			invoiceTotalPages: payload.appendToList ? state.invoiceTotalPages : undefined,
		}
	})

	.case(actions.getStatements.done, (state, { result }): StoreState => {
		return {
			...state,
			statements: result.statements,
			errorGetStatements: undefined,
			loadingMessageGetStatements: undefined
		}
	})
	.case(actions.getStatements.failed, (state, { error }): StoreState => {
		return {
			...state,
			errorGetStatements: error,
			loadingMessageGetStatements: undefined
		}
	})
	.case(actions.getStatements.started, (state): StoreState => {
		return {
			...state,
			errorGetStatements: undefined,
			loadingMessageGetStatements: 'Loading statements…',
			statements: undefined, // clear statements so loading/error message is shown
		}
	})

	.case(actions.loadEwayUrl.done, (state, { result }): StoreState => {
		return {
			...state,
			paymentUrl: result.link,
			errorFetchingPaymentUrl: undefined,
			loadingMessageGetPaymentUrl: undefined
		}
	})
	.case(actions.loadEwayUrl.failed, (state, { error }): StoreState => {
		return {
			...state,
			errorFetchingPaymentUrl: error,
			loadingMessageGetPaymentUrl: undefined
		}
	})
	.case(actions.loadEwayUrl.started, (state): StoreState => {
		return {
			...state,
			errorFetchingPaymentUrl: undefined,
			loadingMessageGetPaymentUrl: 'Loading payment…',
			paymentUrl: undefined, // clear url so it is fresh
		}
	})

	.case(actions.loadEwayPaymentResult.done, (state, { result }): StoreState => {
		return {
			...state,
			paymentResult: result,
			errorFetchingPaymentResult: undefined,
			loadingMessageGetPaymentResult: undefined
		}
	})
	.case(actions.loadEwayPaymentResult.failed, (state, { error }): StoreState => {
		return {
			...state,
			errorFetchingPaymentResult: error,
			loadingMessageGetPaymentResult: undefined
		}
	})
	.case(actions.loadEwayPaymentResult.started, (state): StoreState => {
		return {
			...state,
			errorFetchingPaymentResult: undefined,
			loadingMessageGetPaymentResult: 'Loading payment result…',
			paymentResult: undefined, // clear result so it is fresh
		}
	})

	.case(actions.editInvoiceComment.done, (state, payload): StoreState => {
		if (state.invoices) {
			let { invoiceId, invoiceSuffixId, comment } = payload.params
			const invoices = state.invoices.map(invoice => {
				const isInvoiceMatch: boolean = invoice.invoiceNumber === invoiceId && invoice.invoiceSuffixNumber === invoiceSuffixId
				// we found a matching invoice, update the comment
				if (isInvoiceMatch) {
					return { ...invoice, invoiceComment: comment }
				}
				return invoice
			})

			return {
				...state,
				invoices,
				errorEditingComment: undefined,
				savingComment: false,
			}
		}

		return {
			...state,
			errorEditingComment: undefined,
			savingComment: false,
		}
	})
	.case(actions.editInvoiceComment.failed, (state, { error }): StoreState => {
		return {
			...state,
			errorEditingComment: error,
			savingComment: false,
		}
	})
	.case(actions.editInvoiceComment.started, (state): StoreState => {
		return {
			...state,
			errorEditingComment: undefined,
			savingComment: true,
		}
	})
	.case(actions.clearInvoiceCommentError, (state): StoreState => {
		return {
			...state,
			errorEditingComment: undefined,
		}
	})

	// invoice filters
	.case(actions.setInvoiceFilter, (state, payload): StoreState => {
		// remove filter for same type if already exists
		var invoiceFilters = state.invoiceFilters.filter(filter => filter.type !== payload.type)
		// add the new filter
		invoiceFilters.push(payload)
		return {
			...state,
			invoiceFilters,
		}
	})
	.case(actions.clearInvoiceFilter, (state, payload): StoreState => {
		// remove filter for same type
		var invoiceFilters = state.invoiceFilters.filter(filter => filter.type !== payload)
		return {
			...state,
			invoiceFilters,
		}
	})
	.case(actions.clearAllInvoiceFilters, (state): StoreState => {
		return {
			...state,
			invoiceFilters: [],
		}
	})

	.case(actions.downloadStatement.done, (state, payload): StoreState => {
		let downloadingStatements = state.downloadingStatementDates.filter((date) => date !== payload.params.statementDate)
		return {
			...state,
			downloadingStatementDates: downloadingStatements,
		}
	})
	.case(actions.downloadStatement.failed, (state, payload): StoreState => {
		let downloadingStatements = state.downloadingStatementDates.filter((date) => date !== payload.params.statementDate)
		return {
			...state,
			downloadingStatementDates: downloadingStatements,
			errorDownloadStatement: payload.error,
		}
	})
	.case(actions.downloadStatement.started, (state, payload): StoreState => {
		let downloadingStatements: Array<string> = []
		downloadingStatements = downloadingStatements.concat(state.downloadingStatementDates)
		downloadingStatements = downloadingStatements.concat(payload.statementDate)
		return {
			...state,
			downloadingStatementDates: downloadingStatements,
		}
	})

	.case(actions.downloadInvoice.done, (state, payload): StoreState => {
		let downloadingInvoices = state.downloadingInvoices.filter((date) => date !== (payload.params.invoiceId + '/' + payload.params.invoiceSuffixId))
		return {
			...state,
			downloadingInvoices,
		}
	})
	.case(actions.downloadInvoice.failed, (state, payload): StoreState => {
		let downloadingInvoices = state.downloadingInvoices.filter((date) => date !== (payload.params.invoiceId + '/' + payload.params.invoiceSuffixId))
		return {
			...state,
			downloadingInvoices,
		}
	})
	.case(actions.downloadInvoice.started, (state, payload): StoreState => {
		let downloadingInvoices: Array<string> = []
		downloadingInvoices = downloadingInvoices.concat(state.downloadingInvoices)
		downloadingInvoices = downloadingInvoices.concat(payload.invoiceId + '/' + payload.invoiceSuffixId)
		return {
			...state,
			downloadingInvoices,
		}
	})

	.case(actions.downloadInvoiceAsZip.started, (state): StoreState => {
		return {
			...state,
			downloadInvoiceAsZipLoading: true,
			downloadInvoiceAsZipSuccess: undefined,
			downloadInvoiceAsZipError: undefined,
		}
	})
	.case(actions.downloadInvoiceAsZip.done, (state): StoreState => {
		return {
			...state,
			downloadInvoiceAsZipLoading: undefined,
			downloadInvoiceAsZipSuccess: true,
		}
	})
	.case(actions.downloadInvoiceAsZip.failed, (state, { error }): StoreState => {
		return {
			...state,
			downloadInvoiceAsZipLoading: undefined,
			downloadInvoiceAsZipError: error,
		}
	})

	.case(actions.downloadStatementAsZip.started, (state): StoreState => {
		return {
			...state,
			downloadStatementAsZipLoading: true,
			downloadStatementAsZipSuccess: undefined,
			downloadStatementAsZipError: undefined,
		}
	})
	.case(actions.downloadStatementAsZip.done, (state): StoreState => {
		return {
			...state,
			downloadStatementAsZipLoading: undefined,
			downloadStatementAsZipSuccess: true,
		}
	})
	.case(actions.downloadStatementAsZip.failed, (state, { error }): StoreState => {
		return {
			...state,
			downloadStatementAsZipLoading: undefined,
			downloadStatementAsZipError: error,
		}
	})

	.case(actions.getStatementDiscount.started, (state): StoreState => {
		return {
			...state,
			statementDiscount: undefined,
		}
	})
	.case(actions.getStatementDiscount.done, (state, { params, result }): StoreState => {
		return {
			...state,
			statementDiscount: result,
		}
	})

	.case(actions.getOutstandingInvoices.started, (state): StoreState => {
		return {
			...state,
			statementDiscount: undefined,
			outstandingInvoices: undefined,
			loadingMessageGetOutstandingInvoices: 'Loading outstanding invoices…',
		}
	})
	.case(actions.getOutstandingInvoices.done, (state, { params, result }): StoreState => {
		return {
			...state,
			statementDiscount: {
				totalBalance: result.amtApp,
				totalAmount: result.amtTot,
				totalDiscount: result.amtDisc,
			},
			outstandingInvoices: result.invoices,
			loadingMessageGetOutstandingInvoices: undefined,
		}
	})
	.case(actions.getOutstandingInvoices.failed, (state): StoreState => {
		return {
			...state,
			loadingMessageGetOutstandingInvoices: undefined,
		}
	})

	.case(actions.updateStatementPayable, (state, payload): StoreState => {
		return {
			...state,
			statementPayable: payload,
		}
	})

	/* The user has been logged out remove invoices from the state. */
	.case(authActions.loggedOut, (): StoreState => {
		return INITIAL_STATE
	})

	// reset flags to their initial state
	.case(rootActions.readyAction, (state): StoreState => {
		return {
			...state,
			errorGetInvoices: undefined,
			loadingMessageGetInvoices: undefined,
			downloadingInvoices: [],
			downloadInvoiceAsZipLoading: undefined,
			downloadInvoiceAsZipSuccess: undefined,
			downloadInvoiceAsZipError: undefined,

			downloadingStatementDates: [],
			errorGetStatements: undefined,
			loadingMessageGetStatements: undefined,
			downloadStatementAsZipLoading: undefined,
			downloadStatementAsZipSuccess: undefined,
			downloadStatementAsZipError: undefined,

			savingComment: false,
			errorEditingComment: undefined,
		}
	})
