import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { PasswordUpdate, ProfileUpdate } from 'typescript-fetch-api'
import { WebViewNavigationParams } from '../webview/types'

const actionCreator = actionCreatorFactory('Profile')

export const navigateToOrders = actionCreator('VIEW_ORDERS')

export const navigateToInvoices = actionCreator('VIEW_INVOICES')

export const navigateToMakePayment = actionCreator('NAVIGATE_TO_MAKE_PAYMENT')

export const navigateToCustomerOrders = actionCreator('VIEW_CUSTOMER_ORDERS')

export const navigateToSignIn = actionCreator('GO_SIGN_IN')

export const navigateToProfile = actionCreator('GO_TO_PROFILE')

export const navigateToAccounts = actionCreator('NAVIGATE_TO_ACCOUNTS')

export const navigateToChangePassword = actionCreator('NAVIGATE_TO_CHANGE_PASSWORD')

export const navigateToRegister = actionCreator('NAVIGATE_TO_REGISTER')

export const navigateToPrivacyPolicy = actionCreator('NAVIGATE_TO_PRIVACY_POLICY')

export const navigateToTermsAndConditions = actionCreator('NAVIGATE_TO_TERMS_AND_CONDITIONS')

export const popAllStacks = actionCreator('POP_ALL_STACKS')

export interface EditProfilePayload {
    id: number
    profile: ProfileUpdate
}
export type EditProfileAction = Action<EditProfilePayload>
export type EditProfileSuccessAction = Action<Success<EditProfilePayload, Response>>
export const editProfile = actionCreator.async<EditProfilePayload, Response, Error>('EDIT_PROFILE')

export type UpdateUserPasswordAction = Action<PasswordUpdate>
export const updateUserPassword = actionCreator.async<PasswordUpdate, Response, Error>('UPDATE_USER_PASSWORD')

export const clearUpdateUserPasswordFlags = actionCreator('CLEAR_UPDATE_USER_PASSWORD_FLAGS')

export type NavigateToPaymentWebviewAction = Action<WebViewNavigationParams>
export const navigateToPaymentWebview = actionCreator<WebViewNavigationParams>('NAVIGATE_TO_PAYMENT_WEBVIEW')