import { ProductList } from 'typescript-fetch-api'

export const RECIPIENT_NAME_PLACEHOLDER: string = ' Recipient Name: '
export const ON_SITE_CONTACT_NUMBER_PLACEHOLDER: string = ' On Site Contact Number: '

/**
 * Sort the label groups by favourited, then title.
 * @param labelGroups the label groups to sort
 */
export const getSortedLabelGroups = (labelGroups: ProductList[]): ProductList[] => {
	const sorted = labelGroups.sort((a, b) => {
		if (a.favourited && b.favourited) {
			return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
		} else if (a.favourited || b.favourited) {
			return b.favourited ? 1 : -1
		}
		return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
	})
	return sorted
}

export function createDeliveryInstructionsForLabelOrder(instructions: string, recipientName?: string, onSiteContactNumber?: string): string {
	// append the recipient name and on-site contact number if in delivery mode
	let deliveryInstructions = instructions
	if (recipientName) {
		instructions += RECIPIENT_NAME_PLACEHOLDER + recipientName
	}
	if (onSiteContactNumber) {
		instructions += ON_SITE_CONTACT_NUMBER_PLACEHOLDER + onSiteContactNumber
	}
	return deliveryInstructions
}
