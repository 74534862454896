import { lazy } from 'react'

/**
 * Provides the routes used across the app.
 * The components are wrapped with lazy so we only load what's necessary based on the current route.
 *
 * Note: The lazy route/component should then be rendered inside a `Suspense`, so we can show fallback content while waiting for the lazy component to load.
 *
 * @see [Code-Splitting](https://reactjs.org/docs/code-splitting.html)
 * @see [Route-based code splitting](https://reactjs.org/docs/code-splitting.html#route-based-code-splitting)
 */
export default {
	Home: lazy(() => import('../common/home/containers/Home')),
	SearchResultList: lazy(() => import('../modules/search/containers/SearchResultListNavigator')),
	AddAccount: lazy(async () => import('../modules/accounts/components/AddAccount')),
	CustomerOrdersNavigator: lazy(() => import('../modules/customerorders/components/CustomerOrdersNavigator')),
	CustomerOrderDetailsNavigator: lazy(() => import('../modules/customerorders/containers/CustomerOrderDetailsNavigator')),
	ProductsNavigator: lazy(() => import('../modules/products/containers/ProductsNavigator')),
	ProductEntry: lazy(() => import('../modules/product/components/ProductEntry')),
	CheckoutNavigator: lazy(() => import('../modules/cart/components/CheckoutNavigator')),
	Lists: lazy(() => import('../modules/mylists/containers/MyListsNavigator')),
	AccountList: lazy(() => import('../modules/mylists/containers/AccountListNavigator')),
	ListDetails: lazy(() => import('../modules/mylists/containers/ListDetailsNavigator')),
	SignIn: lazy(() => import('../common/auth/containers/SignIn')),
	Register: lazy(() => import('../common/register/containers/Register')),
	EmailVerification: lazy(() => import('../modules/mobileVerification/containers/EmailVerification')),
	MobileVerification: lazy(() => import('../modules/mobileVerification/containers/MobileVerificationNavigator')),
	ForgotPassword: lazy(() => import('../modules/forgotpassword/containers/ForgotPasswordNavigator')),
	Admin: lazy(() => import('../modules/admin/containers/AdminAccounts')),
	Financials: lazy(() => import('../modules/financials/containers/Financials')),
	ChangePassword: lazy(() => import('../modules/forgotpassword/containers/ChangePasswordNavigator')),
	Impersonate: lazy(() => import('../modules/auth/components/Impersonate')),
	GeneralNavigator: lazy(() => import('../modules/general/components/GeneralNavigator')),
	SupportNavigator: lazy(() => import('../modules/general/components/SupportNavigator')),
	TradeNavigator: lazy(() => import('../modules/general/components/TradeNavigator')),
	PSCoopNavigator: lazy(() => import('../modules/general/components/PSCoopNavigator')),
	Regions: lazy(() => import('../common/showrooms/containers/Regions')),
	ContactUs: lazy(() => import('../modules/contactus/components/ContactUs')),
	DeliveryPolicy: lazy(() => import('../modules/general/components/DeliveryPolicy')),
	OnlineReturnsPolicy: lazy(() => import('../modules/general/components/OnlineReturnsPolicy')),
	PaymentsPolicy: lazy(() => import('../modules/general/components/PaymentsPolicy')),
	OpenTradeAccount: lazy(() => import('../modules/general/components/OpenTradeAccount')),
	MembershipBenefits: lazy(() => import('../modules/general/components/MembershipBenefits')),
	Careers: lazy(() => import('../modules/general/components/Careers')),
	HowTo: lazy(() => import('../modules/general/components/HowTo')),
	AboutUs: lazy(() => import('../modules/general/components/AboutUs')),
	PageUnknown: lazy(() => import('../modules/platform/containers/PageUnknown')),
	VersionUpdates: lazy(() => import('../modules/versionUpdates/components/VersionUpdates')),
	ConsumerHome: lazy(() => import('../modules/home/components/ConsumerHome')),
	ApproveAccount: lazy(() => import('../modules/register/components/ApproveAccount')),
	DeleteAccount: lazy(() => import('../modules/general/components/DeleteAccount')),
	CoopNow: lazy(() => import('../modules/platform/components/CoopNow')),
}
