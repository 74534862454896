/**
 * Defines the paths for all screens.
 */
export enum Paths {
	HOME = '/',
	SIGN_IN = '/signin',

	SEARCH = '/search',
	PRODUCTS_BASE = '/products',
	PRODUCTS = '/products/:id/:name',
	PRODUCTS_SUB = '/products/:id/:name/:sid/:sname',
	PRODUCTS_SUB_SUB = '/products/:id/:name/:sid/:sname/:ssid/:ssname',
	PRODUCT = '/product',
	PRODUCT_DETAILS = '/product/:id',

	ORDERS = '/orders/*',
	CUSTOMER_ORDERS = '/orders/current-orders',
	CUSTOMER_ORDER_DETAILS = '/orders/current-orders/:id/:suffix',
	CHECKOUT = '/checkout',

	LISTS = '/lists',
	LIST_GROUP = '/lists/:id',
	LIST_DETAILS = '/lists/:id/:listId',

	REGISTER = '/register',
	REGISTER_CONFIRM_EMAIL = '/register/confirmEmail',
	REGISTER_CONFIRM_PHONE = '/register/confirmPhone',
	CONFIRM_RESET_PASSWORD = '/register/confirmResetPassword',
	APPROVE_ACCOUNT = '/register/accounts/:id/principal/approve',
	RESET_PASSWORD = '/resetPassword',
	VERIFY_ACCOUNT = '/verifyAccount',
	PROFILE = '/profile',
	ADD_ACCOUNT = '/accounts/add',

	ADMIN = '/admin',
	IMPERSONATE = '/impersonate',

	FINANCIALS = '/financials/:screen',
	FINANCIALS_INVOICES = '/financials/invoices',
	FINANCIALS_STATEMENTS = '/financials/statements',
	FINANCIALS_ACCOUNT_BALANCE = '/financials/account-balance',

	VERSION_UPDATES = '/version-updates',
	TERMS_AND_CONDITIONS = '/terms-and-conditions',
	PRIVACY_POLICY = '/privacy-policy',
	SUPPORT = '/support/*',
	SUPPORT_RELATIVE = 'support',
	SUPPORT_DELIVERY_POLICY_RELATIVE = 'delivery-policy',
	SUPPORT_ONLINE_RETURNS_POLICY_RELATIVE = 'online-returns-policy',
	SUPPORT_PAYMENTS_POLICY_RELATIVE = 'payments-policy',
	SUPPORT_ACCOUNT_DELETE_RELATIVE = 'account-delete',
	SUPPORT_HOW_TO_RELATIVE = 'how-to',
	TRADE = '/trade/*',
	TRADE_RELATIVE = 'trade',
	TRADE_OPEN_TRADE_ACCOUNT_RELATIVE = 'open-trade-account',
	TRADE_MEMBERSHIP_BENEFITS_RELATIVE = 'membership-benefits',
	PSCOOP = '/pscoop/*',
	PSCOOP_RELATIVE = 'pscoop',
	PSCOOP_CAREERS_RELATIVE = 'careers',
	PSCOOP_ABOUT_US_RELATIVE = 'about-us',
	REGIONS = '/regions',
	CONTACT = '/contact',
}
