import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from './actions'
import { Category } from './types'
import { resetProductsTab } from '../products/actions'

export interface StoreState {
	readonly categories?: ReadonlyArray<Category>
	readonly subcategories?: ReadonlyArray<Category>
	readonly subSubcategories?: ReadonlyArray<Category>
	readonly subSubSubcategories?: ReadonlyArray<Category>
	readonly subcategoryId?: string
	readonly subsubcategoryId?: string
	readonly subsubsubcategoryId?: string
	readonly loadingMessage?: string
	readonly error?: Error
}

const INITIAL_STATE: StoreState = {
	// default to undefined so the component knows when to re/load categories
	// categories: [] as ReadonlyArray<Category>,
	// subcategories: [] as ReadonlyArray<Category>,
	// subSubcategories: [] as ReadonlyArray<Category>,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(actions.loadCategories.started, (state, { type, categoryId }): StoreState => {
	if (type === 'category') {
		return { ...state, categories: undefined, loadingMessage: 'Categories loading...', error: undefined }
	}
	if (type === 'subcategory') {
		return { ...state, subcategories: undefined, subcategoryId: categoryId, loadingMessage: 'Categories loading...', error: undefined }
	}
	if (type === 'subsubcategory') {
		return { ...state, subSubcategories: undefined, subsubcategoryId: categoryId, loadingMessage: 'Categories loading...', error: undefined }
	}
	if (type === 'subsubsubcategory') {
		return { ...state, subSubSubcategories: undefined, subsubsubcategoryId: categoryId, loadingMessage: 'Categories loading...', error: undefined }
	}
	return { ...state, error: undefined }
})

reducer.case(actions.loadCategories.done, (state, { params: { type }, result }): StoreState => {
	if (type === 'category') {
		return { ...state, categories: result, loadingMessage: undefined }
	}
	if (type === 'subcategory') {
		return { ...state, subcategories: result, loadingMessage: undefined }
	}
	if (type === 'subsubcategory') {
		return { ...state, subSubcategories: result, loadingMessage: undefined }
	}
	if (type === 'subsubsubcategory') {
		return { ...state, subSubSubcategories: result, loadingMessage: undefined }
	}
	return { ...state, loadingMessage: undefined }
})

reducer.case(actions.loadCategories.failed, (state, { error }): StoreState => {
	return { ...state, error, loadingMessage: undefined }
})

reducer.case(resetProductsTab, (): StoreState => {
	// clear the top level categories because we want to clear out any old Realm objects being referenced by the CategoriesList
	// we could clear the other categories too but its not strictly neccessary as the screens get unmounted and so will reload categories when they get remounted anyway
	// return { ...state, categories: [] }
	return INITIAL_STATE
})