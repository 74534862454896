export enum GeneralPathsEnum {
	PRIVATE_POLICY = 'privacy-policy',
	TERMS_AND_CONDITIONS = 'terms-and-conditions',
	TERMS_OF_TRADE = 'terms-of-trade',
}

export enum SupportPathsEnum {
	DELIVERY_POLICY = 'delivery-policy',
	ONLINE_RETURNS_POLICY = 'online-returns-policy',
	PAYMENTS_POLICY = 'payments-policy',
	ACCOUNT_DELETE = 'account-delete',
	HOW_TO = 'how-to',
}

export enum TradePathsEnum {
	OPEN_TRADE_ACCOUNT = 'open-trade-account',
	MEMBERSHIP_BENEFITS = 'membership-benefits',
}

export enum PSCOOPPathsEnum {
	CAREERS = 'careers',
	ABOUT_US = 'about-us',
}

export interface HowToContent {
	id: number
	title: string
	content: string[]
	videoUrl: string
}