import { connect } from 'react-redux'

// import Component from '../components/Root'
// NOTE: temporarily display the consumer root component
import Component from '../components/ConsumerRoot'
import { WebStoreState } from '../types'

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	isLoggedIn: boolean
	isCodAccount: boolean
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: WebStoreState): Props => {
	return {
		isLoggedIn: !!state.auth.authToken,
		isCodAccount: !!state.order.selectedAccount?.isCodAccount,
	}
}

export default connect(mapStateToProps, {})(Component)
