import { SagaIterator } from 'redux-saga'
import { takeEvery, select, put, call } from 'redux-saga/effects'
import { GetProductPublicRequest, GetProductRequest, Product, ProductPricesResponse } from 'typescript-fetch-api'

import * as actions from '../../common/product/actions'
import * as cartActions from '../../common/cart/actions'
import { PlatformProduct } from '../platform'
import { productSelector } from '../../common/product/selectors'
import { selectedAccountSelector } from '../../common/order/selectors'
import { UserAccount } from '../../common/accounts/types'
import { ApiErrorMessage, getContentApi } from '../../common/api'
import { getPriceParams } from '../../common/auth/functions'
import { callApi } from '../../common/api/functions'
import { authTokenSelector } from '../../common/auth/selectors'
import * as NavigationManager from '../navigation/NavigationManager'
import { Paths } from '../navigation/types'

function getProductPrices(sku: string, customerId?: number): Promise<ProductPricesResponse> {
	return getContentApi().getProductPrices({ productPriceRequestList: { products: [{ sku }] }, customerId })
}

/**
 * Listens for any updates to the order account and updates the selected product prices when in a product detail screen.
 */
function* updateProductPrices(): SagaIterator {
	// we are in a product detail screen
	if (NavigationManager.getLocation()?.pathname.startsWith(Paths.PRODUCT) || location.search.startsWith('?product')) {
		// Note: this is creating a simultaneous request for the same endpoint whenever there are products in the cart
		const selectedProduct: PlatformProduct | undefined = yield select(productSelector)
		if (selectedProduct) {
			// grabs the user's current selected account which is tagged to the order
			const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
			const customerId = selectedAccount && selectedAccount.customerId

			// build up action params from payload
			const params: actions.LoadProductDetailsPayload = { productSku: selectedProduct.sku }

			try {
				// handle request to update user profile
				const productPrices: ProductPricesResponse = yield call(getProductPrices, selectedProduct.sku, customerId)
				const selectedProductPrices = productPrices && productPrices.prices && productPrices.prices.find(item => item.sku === selectedProduct.sku)
				if (selectedProductPrices) {
					const { sku, ...rest } = selectedProductPrices
					// copy the updated prices to the product
					const updatedProduct: PlatformProduct = {
						...selectedProduct,
						...rest,
					}
					yield put(actions.loadProductDetails.done({ params, result: updatedProduct }))
				}
			} catch (error) {
				// failed to load product prices - we do nothing
			}
		}
	}
}

function handleNavigateToProduct(action: actions.ProductSelectedAction) {
	const { productSku, hasExtraParams } = action.payload
	if (hasExtraParams) {
		NavigationManager.navigateToProductViaParams(productSku)
	} else {
		NavigationManager.navigateToProductDetails(productSku)
	}
}

function* handleLoadProductDetails(action: actions.LoadProductDetailsAction): SagaIterator {
	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	// grabs the user's current selected account which is tagged to the order
	let selectedAccount: UserAccount | undefined
	if (hasAuthToken) {
		selectedAccount = yield select(selectedAccountSelector)
	}

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.loadProductDetails,
		(payload: actions.LoadProductDetailsPayload) => {
			let requestParameters: GetProductPublicRequest = {
				sku: payload.productSku,
			}
			let request: Promise<Product>
			if (hasAuthToken) {
				const { customerId, includePrices } = getPriceParams(selectedAccount)
				requestParameters = {
					...requestParameters,
					customerId,
					includePrices,
				} as GetProductRequest
				request = getContentApi().getProduct(requestParameters)
			} else {
				request = getContentApi().getProductPublic(requestParameters)
			}

			return request
				.catch((error) => {
					let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
					if (error instanceof Response) {
						switch (error.status) {
							case 404:
								errorText = 'Product not available.'
								break
							default:
								// @ts-ignore
								if (error.error_description) {
									// @ts-ignore
									errorText = error.error_description
								}
						}
					}
					throw new Error(errorText)
				})
		})
}

export default function* (): SagaIterator {
	yield takeEvery(cartActions.accountSelected, updateProductPrices)
	yield takeEvery(actions.navigateToProduct, handleNavigateToProduct)
	yield takeEvery(actions.loadProductDetails.started, handleLoadProductDetails)
}