import { Branch, GetStatementDiscountResponse, InvoiceType, Statement } from "typescript-fetch-api"

export enum InvoiceFilterType {
    DATE, INVOICE_NO, ORDER_NO, TYPE, BRANCH
}

export interface DateRange {
    fromDate: string
    toDate: string
}

export interface InvoiceFilter {
    type: InvoiceFilterType
    value: string | DateRange | InvoiceType | Branch
}

export const INVOICE_FILTERS: InvoiceFilter[] = [
    {
        type: InvoiceFilterType.DATE,
        value: ''
    },
    {
        type: InvoiceFilterType.INVOICE_NO,
        value: ''
    },
    {
        type: InvoiceFilterType.ORDER_NO,
        value: ''
    },
    {
        type: InvoiceFilterType.TYPE,
        value: ''
    },
    {
        type: InvoiceFilterType.BRANCH,
        value: ''
    },
]

export interface TypeFilter {
    type: InvoiceType,
    name: string,
}

export const INVOICE_TYPES: TypeFilter[] = [
    {
        type: InvoiceType.INVOICE,
        name: 'Invoice',
    },
    {
        type: InvoiceType.CREDIT_NOTE,
        name: 'Credit Note',
    },
    {
        type: InvoiceType.CASH_SALE,
        name: 'Cash Sale',
    },
]

export interface StatementDiscount extends GetStatementDiscountResponse { }

export interface ShortPaymentItem {
    shortPaidAmount: string
    invoice: string
    reason: string
}

export interface StatementPayable {
    statement: Statement
    customerId: number
    amount: number
}