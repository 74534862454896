import actionCreatorFactory, { Action } from 'typescript-fsa'
import { GeneralPathsEnum, PSCOOPPathsEnum, SupportPathsEnum, TradePathsEnum } from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('General')

export type NavigateToGeneralPathAction = Action<GeneralPathsEnum>
export const navigateToGeneralPath = actionCreator<GeneralPathsEnum>('NAVIGATE_TO_GENERAL_PATH')

export type NavigateToSupportPathAction = Action<SupportPathsEnum>
export const navigateToSupportPath = actionCreator<SupportPathsEnum>('NAVIGATE_TO_SUPPORT_PATH')

export type NavigateToTradePathAction = Action<TradePathsEnum>
export const navigateToTradePath = actionCreator<TradePathsEnum>('NAVIGATE_TO_TRADE_PATH')

export type NavigateToPSCOOPPathAction = Action<PSCOOPPathsEnum>
export const navigateToPSCOOPPath = actionCreator<PSCOOPPathsEnum>('NAVIGATE_TO_PSCOOP_PATH')