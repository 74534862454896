import { SagaIterator } from 'redux-saga'
import { takeEvery, put, select } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'
import { ProductListDomain, ProductListGroup } from 'typescript-fetch-api'

import * as actions from '../../common/mylists/actions'
import * as cartActions from '../../common/cart/actions'
import { UserAccount } from '../../common/accounts/types'
import { getPriceParams } from '../../common/auth/functions'
import { OwnPropInfo as AccountListOwnProps } from '../../common/mylists/containers/AccountList'
import { MY_LISTS_URL_ID, PWGO_LISTS_URL_ID } from '../../common/mylists/content'
import { accountListsSelector } from '../../common/mylists/selectors'
import { getProductListGroupDomain } from '../../common/mylists/functions'
import * as NavigationManager from '../navigation/NavigationManager'
import { ListsDialogType, NavigateToNewListState } from './types'

/**
 * Listens for any updates to the order account and updates the actions.
 */
function* updateLists(action: Action<UserAccount>): SagaIterator {
	const { customerId, includePrices } = getPriceParams(action.payload)
	yield put(actions.fetchLists.started({ customerId, includePrices, includeItems: false }))
}

function handleNavigateToLists() {
	NavigationManager.navigateToLists()
}

function handleNavigateToAccountList(action: actions.NavigateToAccountListAction) {
	// shape the account list so we can reuse the helper method `getProductListGroupDomain`
	const productListGroup: ProductListGroup = {
		title: action.payload.title,
		prostix_account_id: action.payload.id,
		lists: action.payload.data,
	}

	// setup parent list ID
	const productListGroupDomain = getProductListGroupDomain(productListGroup)
	let accountListId: string
	switch (productListGroupDomain) {
		case ProductListDomain.ACCOUNT:
			accountListId = productListGroup.prostix_account_id!.toString()
			break
		case ProductListDomain.PWGO:
			accountListId = PWGO_LISTS_URL_ID
			break
		default:
			accountListId = MY_LISTS_URL_ID
			break
	}
	const state: AccountListOwnProps = { accountList: action.payload }
	NavigationManager.navigateToListGroup(accountListId, state)
}

function* handleNavigateToListDetails(action: actions.NavigateToListDetailsActionPayload): SagaIterator {
	const listID = action.payload.id
	const accountLists: ProductListGroup[] = yield select(accountListsSelector)
	// grab the account list where the selected list belongs to
	const accountList = accountLists.find(item => item.lists && item.lists.some(list => list.id === listID))
	if (accountList) {
		// setup parent list ID
		const productListGroupDomain = getProductListGroupDomain(accountList)
		let accountListId: string
		switch (productListGroupDomain) {
			case ProductListDomain.ACCOUNT:
				accountListId = accountList.prostix_account_id!.toString()
				break
			case ProductListDomain.PWGO:
				accountListId = PWGO_LISTS_URL_ID
				break
			default:
				accountListId = MY_LISTS_URL_ID
				break
		}
		NavigationManager.navigateToListDetails(accountListId, listID)
	} else {
		// unable to find account list (list group) for the selected list, just navigate the user to the top-level lists screen
		NavigationManager.navigateToLists()
	}
}

function* handleCreateOrEditList(action: actions.CreateOrEditListActionSuccess) {
	if (action.payload.params.screen === 'New List') {
		// navigate to the list details for new list
		yield put(actions.navigateToListDetails(action.payload.params.list))
	}
}

function handleNavigateToNewList() {
	const state: NavigateToNewListState = { dialogType: ListsDialogType.CREATE }
	NavigationManager.navigateToLists(state)
}

function handleNavigateToImportList() {
	const state: NavigateToNewListState = { dialogType: ListsDialogType.UPLOAD }
	NavigationManager.navigateToLists(state)
}

export default function* (): SagaIterator {
	yield takeEvery(cartActions.accountSelected, updateLists)
	yield takeEvery(actions.navigateToListsTab, handleNavigateToLists)
	yield takeEvery(actions.navigateToAccountList, handleNavigateToAccountList)
	yield takeEvery(actions.navigateToListDetails, handleNavigateToListDetails)
	yield takeEvery(actions.createOrEditList.done, handleCreateOrEditList)
	yield takeEvery(actions.navigateToNewList, handleNavigateToNewList)
	yield takeEvery(actions.navigateToImportList, handleNavigateToImportList)
}
