import { SagaIterator } from 'redux-saga'
import { call, select, takeEvery } from 'redux-saga/effects'
import { CategoryResponse, GetCategoryPublicRequest, GetCategoryRequest, SearchFilterFilterTypeEnum } from 'typescript-fetch-api'

import * as actions from '../../common/categories/actions'
import * as platformActions from '../platform/actions'
import { getContentApi } from '../../common/api'
import { formatCategoryResponse } from './functions'
import { callApi } from '../../common/api/functions'
import { INITIAL_PAGE } from '../platform/content'
import { filterChangeAction, FilterQueryAction } from '../../common/search/actions'
import { ProductFilter } from '../../common/products/actions'
import { savedFilterSelector } from '../../common/products/selectors'
import { getCategoryType } from '../../common/categories/functions'
import { authTokenSelector } from '../../common/auth/selectors'
import * as NavigationManager from '../navigation/NavigationManager'
import { Paths } from '../navigation/types'

function handleNavigateToCategory(action: actions.CategorySelectedAction) {
	const { categoryId, categoryTitle, type } = action.payload
	const title = categoryTitle || `CATEGORY ${categoryId}`
	const encodedCategoryTitle = encodeURIComponent(title)

	// build up the path for the selected category
	const selectedCategoryPath = `/${categoryId}/${encodedCategoryTitle}`
	const { pathname } = window.location
	let location: string

	if (pathname.startsWith(Paths.PRODUCTS_BASE) && type !== undefined) {
		location = pathname

		// e.g 'products/BRM/Bathroom' => [products', 'BRM', 'Bathroom']
		const pathnameFragments: string[] = pathname.split('/')
		// grabs the previous category id from the array of pathnames => 'BRM'
		const previousCategoryId: string = pathnameFragments[pathnameFragments.length - 2]

		// if the selected category is a child, just append the selected category path
		if (categoryId.length > previousCategoryId.length) {
			location += selectedCategoryPath
		} else {
			const locationFragments: string[] = location.split('/')
			// pops the latest category details from the url
			// e.g 'products/BRM/Bathroom/BRM_ACC/Accessories' => 'products/BRM/Bathroom'
			locationFragments.splice(-2, 2)
			location = locationFragments.join('/')

			// if the selected category is of the same tier, just append the selected category path
			if (categoryId.length === previousCategoryId.length) {
				location += selectedCategoryPath
			}
		}
	} else {
		// just append the selected category path in the ff cases:
		// - we didn't come from a products-related url
		// - the user wants to load a top-level category
		location = `${Paths.PRODUCTS_BASE}${selectedCategoryPath}`
	}

	NavigationManager.navigateToCategory(location)
}

/**
 * Builds the category url based on the product category details.
 * @param action contains the payload
 */
function* handleNavigateToCategoryFromProductBreadcrumbs(action: platformActions.NavigateToCategoryFromProductBreadcrumbsAction): SagaIterator {

	// as a product can have multiple categories we disable this for now
	// const { categoryId, categoryTitle, type } = action.payload
	// const selectedProduct: PlatformProduct | undefined = yield select(productSelector)

	// if (selectedProduct) {
	// 	// build up the path for the selected category
	// 	const selectedCategoryPath = `${categoryId}/${categoryTitle}`
	// 	let pathname = `${Paths.PRODUCTS_BASE}/`
	// 	// NOTE: setup temporarily since current data for top-level categories have empty names
	// 	const categoryName = selectedProduct.categoryName || `CATEGORY ${selectedProduct.category}`

	// 	switch (type) {
	// 		case 'subcategory':
	// 			// appends the category details to the selected category path
	// 			pathname += `${selectedProduct.category}/${categoryName}/${selectedCategoryPath}`
	// 			break
	// 		case 'subsubcategory': {
	// 			const subcategoryId = `${selectedProduct.category}_${selectedProduct.subcategory}`
	// 			// appends the category and subcategory details to the selected category path
	// 			pathname += `${selectedProduct.category}/${categoryName}/${subcategoryId}/${selectedProduct.subcategoryName}/${selectedCategoryPath}`
	// 			break
	// 		}
	// 		default:
	// 			pathname += selectedCategoryPath
	// 			break
	// 	}

	// 	NavigationManager.navigateToCategory(pathname)
	// }
}

function* loadCategories(action: actions.LoadCategoriesAction): SagaIterator {
	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	if (action.payload.categoryId) {
		// Since we only really want to load the categories, pass through the minimum amount for the page size to save the request
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.loadCategories,
			(payload: actions.LoadCategoriesPayload) => {
				let request: Promise<CategoryResponse>
				let requestParameters: GetCategoryPublicRequest = {
					id: payload.categoryId!,
					page: INITIAL_PAGE,
					pageSize: 1,
				}
				if (hasAuthToken) {
					requestParameters = {
						...requestParameters,
						branchId: payload.branchId,
					} as GetCategoryRequest
					request = getContentApi().getCategory(requestParameters)
				} else {
					request = getContentApi().getCategoryPublic(requestParameters)
				}
				return request.then(response => (
					response.categories ? formatCategoryResponse(response.categories) : undefined
				))
			})
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.loadCategories,
			(_: actions.LoadCategoriesPayload) => {
				const request: Promise<CategoryResponse> = hasAuthToken ? getContentApi().getTopLevelCategory({}) : getContentApi().getTopLevelCategoryPublic({})
				return request.then(response => (
					response.categories ? formatCategoryResponse(response.categories) : undefined
				))
			})
	}
}

function* reloadCategoriesOnBranchUpdate(action: FilterQueryAction): SagaIterator {
	const savedFilters: ProductFilter | undefined = yield select(savedFilterSelector)
	const searchFilters = action.payload.searchRequest?.filters

	const categoryId = action.payload.categoryId
	if (categoryId) {
		const newBranchFilter = searchFilters?.find(item => item.filterType === SearchFilterFilterTypeEnum.BRANCH)
		const hasEnabledBranchFilter = !savedFilters?.filters?.some(item => item.filterType === SearchFilterFilterTypeEnum.BRANCH) && !!newBranchFilter
		const hasDisabledBranchFilter = !!savedFilters?.filters?.some(item => item.filterType === SearchFilterFilterTypeEnum.BRANCH) && !newBranchFilter
		// check if the user enabled/disabled the branch filter
		if (hasEnabledBranchFilter || hasDisabledBranchFilter) {
			const categoryType = getCategoryType(categoryId)
			const childCategoryType = categoryType === 'category' ? 'subcategory' : 'subsubcategory'
			const isSubsubcategorySibling = categoryType === 'subsubcategory' && childCategoryType === 'subsubcategory'
			// prevent reloading the counts if already a subsubcategory
			if (!isSubsubcategorySibling) {
				const branchId = hasEnabledBranchFilter && newBranchFilter && newBranchFilter.id !== undefined ? parseInt(newBranchFilter.id, 10) : undefined
				yield call(loadCategories, {
					type: actions.loadCategories.started.type,
					payload: {
						categoryId,
						type: childCategoryType,
						branchId,
					},
				})
			}
		}
	}
}

export default function* (): SagaIterator {
	yield takeEvery(actions.selectCategoryAction, handleNavigateToCategory)
	yield takeEvery(platformActions.navigateToCategoryFromProductBreadcrumbs, handleNavigateToCategoryFromProductBreadcrumbs)
	yield takeEvery(actions.loadCategories.started, loadCategories)
	yield takeEvery(filterChangeAction, reloadCategoriesOnBranchUpdate)
}