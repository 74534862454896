import * as Api from 'typescript-fetch-api'
import { store } from '../root'
import { fetchTimeout } from './timeout'
import { getPlatformSupportImplementation } from '../platform'
import { refreshTokenAndApply } from '../auth/functions'
import { AuthToken } from '../auth/types'
import { Environment, EnvironmentUrls } from './types'

export type ApiErrorTransformer = (response: Response) => Error
let errorTransformer: ApiErrorTransformer | undefined

export const IMAGE_ID_PLACEHOLDER = '{id}'
export enum ApiErrorMessage {
	GENERIC_ERROR_MESSAGE = 'An unknown error has occurred',
	QUOTE_ERROR_MESSAGE = 'This transaction cannot be updated, it is a Voided Quote',
	ORDER_ERROR_MESSAGE = 'This transaction cannot be updated, it is a Voided Sales Order',
	INVOICE_ERROR_MESSAGE = 'This transaction cannot be updated, it is a invoice',
	STATEMENT_ERROR_MESSAGE = 'Customer has no open transactions',
	CODE_SEND_FAILURE = 'Code send failure',
	ERROR_NOT_LOGGED_IN = 'Not logged in',
	ERROR_MSG_ALREADY_VERIFIED = 'User already verified',
	USER_IS_DISABLED_MESSAGE = 'User is disabled',
	REQUEST_MAX_ERROR_MESSAGE = 'Too Many Requests',
}

/** Our fetch API wrapper to provide additional functionality */
export const apiTimeout: Api.FetchAPI = (url: string, init?: {}): Promise<Response> => {
	/* Apply a timeout to our requests. Note that the timeout doesn't cancel the request, it merely
	   throws an error so we are not left hanging.
	 */
	// bigger timeout for loading products as can take a while to process the request on first install
	const timeoutMillis: number = url.endsWith('/products') ? 120000 : 60000
	return fetchTimeout(url, init, timeoutMillis)
}

const EnvConfig: Record<Environment, EnvironmentUrls> = {
	[Environment.LOCAL]: {
		'appServerRootURL': 'http://localhost:8080',
		// 'appServerRootURL': 'http://psc-api.test.juliusspencer.co.nz',
		'imageThumbServerUrl': 'http://psc-api.test.juliusspencer.co.nz/Dev/Products/{id}/image/ImageThumb.webp',
		'imageLargeServerUrl': 'http://psc-api.test.juliusspencer.co.nz/Dev/Products/{id}/image/ImageLarge.webp',
		'imageLargeServerUrlFallback': 'http://psc-api.test.juliusspencer.co.nz/Dev/Products/image/ImageLarge.jpg',
		'specSheetsServerUrl': 'http://psc-api.test.juliusspencer.co.nz/Dev/Products/{id}/specs/',
		'brochuresServerUrl': 'http://psc-api.test.juliusspencer.co.nz/Dev/Products/{id}/brochure/',
		'msdsServerUrl': 'http://psc-api.test.juliusspencer.co.nz/Dev/Products/{id}/msds/',
		'warrantyServerUrl': 'http://psc-api.test.juliusspencer.co.nz/Dev/Products/{id}/warranty/',
		'installServerUrl': 'http://psc-api.test.juliusspencer.co.nz/Dev/Products/{id}/install/',
		'techdrawServerUrl': 'http://psc-api.test.juliusspencer.co.nz/Dev/Products/{id}/techdraw/',
		'metaDataUrl': 'http://psc-api.test.juliusspencer.co.nz/Dev/Data/metadata.json',
		'categoryImageThumbServerUrl': 'http://psc-api.test.juliusspencer.co.nz/Dev/Category/Categories/{id}/ImageThumb.webp',
		'categoryImageLargeServerUrl': 'http://psc-api.test.juliusspencer.co.nz/Dev/Category/Categories/{id}/ImageLarge.webp',
		'assetsServerUrl': 'http://psc-api.test.juliusspencer.co.nz/Dev/Assets/',
	},
	[Environment.DEV]: {
		'appServerRootURL': 'http://api-dev.plumberscoop.au',
		'imageThumbServerUrl': 'https://json-test.plumberscoop.au/Test/Products/{id}/image/ImageThumb.webp',
		'imageLargeServerUrl': 'https://json-test.plumberscoop.au/Test/Products/{id}/image/ImageLarge.webp',
		'imageLargeServerUrlFallback': 'https://json-test.plumberscoop.au/Test/Products/{id}/image/ImageLarge.jpg',
		'specSheetsServerUrl': 'http://json-dev.coopnow.plumberscoop.au/Dev/Products/{id}/specs/',
		'brochuresServerUrl': 'http://json-dev.coopnow.plumberscoop.au/Dev/Products/{id}/brochure/',
		'msdsServerUrl': 'http://json-dev.coopnow.plumberscoop.au/Dev/Products/{id}/msds/',
		'warrantyServerUrl': 'http://json-dev.coopnow.plumberscoop.au/Dev/Products/{id}/warranty/',
		'installServerUrl': 'http://json-dev.coopnow.plumberscoop.au/Dev/Products/{id}/install/',
		'techdrawServerUrl': 'http://json-dev.coopnow.plumberscoop.au/Dev/Products/{id}/techdraw/',
		'metaDataUrl': 'http://json-dev.coopnow.plumberscoop.au/Dev/Data/metadata.json',
		'categoryImageThumbServerUrl': 'https://json-test.plumberscoop.au/Test/Category/Categories/{id}/ImageThumb.webp',
		'categoryImageLargeServerUrl': 'https://json-test.plumberscoop.au/Test/Category/Categories/{id}/ImageLarge.webp',
		'assetsServerUrl': 'http://json-dev.coopnow.plumberscoop.au/Dev/Assets/',
	},
	[Environment.TEST]: {
		'appServerRootURL': 'https://api-test.plumberscoop.au',
		'imageThumbServerUrl': 'https://json-test.plumberscoop.au/Test/Products/{id}/image/ImageThumb.webp',
		'imageLargeServerUrl': 'https://json-test.plumberscoop.au/Test/Products/{id}/image/ImageLarge.webp',
		'imageLargeServerUrlFallback': 'https://json-test.plumberscoop.au/Test/Products/{id}/image/ImageLarge.jpg',
		'specSheetsServerUrl': 'https://json-test.plumberscoop.au/Test/Products/{id}/specs/',
		'brochuresServerUrl': 'https://json-test.plumberscoop.au/Test/Products/{id}/brochure/',
		'msdsServerUrl': 'https://json-test.plumberscoop.au/Test/Products/{id}/msds/',
		'warrantyServerUrl': 'https://json-test.plumberscoop.au/Test/Products/{id}/warranty/',
		'installServerUrl': 'https://json-test.plumberscoop.au/Test/Products/{id}/install/',
		'techdrawServerUrl': 'https://json-test.plumberscoop.au/Test/Products/{id}/techdraw/',
		'metaDataUrl': 'https://json-test.plumberscoop.au/Test/Data/metadata.json',
		'categoryImageThumbServerUrl': 'https://json-test.plumberscoop.au/Test/Category/Categories/{id}/ImageThumb.webp',
		'categoryImageLargeServerUrl': 'https://json-test.plumberscoop.au/Test/Category/Categories/{id}/ImageLarge.webp',
		'assetsServerUrl': 'https://json-test.plumberscoop.au/Test/Assets/',
	},
	[Environment.PRODUCTION]: {
		'appServerRootURL': 'https://api.plumberscoop.au',
		'imageThumbServerUrl': 'https://json.plumberscoop.au/Products/{id}/image/ImageThumb.webp',
		'imageLargeServerUrl': 'https://json.plumberscoop.au/Products/{id}/image/ImageLarge.webp',
		'imageLargeServerUrlFallback': 'https://json.plumberscoop.au/Products/{id}/image/ImageLarge.jpg',
		'specSheetsServerUrl': 'https://json.plumberscoop.au/Products/{id}/specs/',
		'brochuresServerUrl': 'https://json.plumberscoop.au/Products/{id}/brochures/',
		'msdsServerUrl': 'https://json.plumberscoop.au/Products/{id}/msds/',
		'warrantyServerUrl': 'https://json.plumberscoop.au/Products/{id}/warranty/',
		'installServerUrl': 'https://json.plumberscoop.au/Products/{id}/install/',
		'techdrawServerUrl': 'https://json.plumberscoop.au/Products/{id}/techdraw/',
		'metaDataUrl': 'https://json.plumberscoop.au/Data/metadata.json',
		'categoryImageThumbServerUrl': 'https://json.plumberscoop.au/Category/Categories/{id}/ImageThumb.webp',
		'categoryImageLargeServerUrl': 'https://json.plumberscoop.au/Category/Categories/{id}/ImageLarge.webp',
		'assetsServerUrl': 'https://json.plumberscoop.au/Assets/',
	}
}

export function isDevelopment(): boolean {
	return getPlatformSupportImplementation().getEnvironment().toUpperCase() === Environment.LOCAL
}

export function getAppServerRootURL() {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].appServerRootURL
}

export function getDataServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].metaDataUrl
}

export function getImageLargeServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].imageLargeServerUrl
}

export function getImageLargeServerUrlFallback(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].imageLargeServerUrlFallback
}

export function getCategoryImageLargeServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].categoryImageLargeServerUrl
}

export function getSpecSheetsServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].specSheetsServerUrl
}

export function getBrochureServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].brochuresServerUrl
}

export function getMSDSServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].msdsServerUrl
}

export function getWarrantyServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].warrantyServerUrl
}

export function getInstallServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].installServerUrl
}

export function getTechdrawServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].techdrawServerUrl
}

export function getSettingsServerRootURL() {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].assetsServerUrl + 'Settings/'
}

export function getAboutServerRootURL() {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].assetsServerUrl + 'About/'
}

export function getAssetsServerRootURL() {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].assetsServerUrl
}

export function getErrorTransformer(): ApiErrorTransformer | undefined {
	return errorTransformer
}

/** Set a function to be used to transform failed Response objects into Errors. */
export function setErrorTransformer(e?: ApiErrorTransformer) {
	errorTransformer = e
}

/** The API configuration. */
export const oAuthConfig = new Api.Configuration({
	basePath: getAppServerRootURL(),
	accessToken: (name?: string, scopes?: string[]) => {
		let authToken = store.getState().auth.authToken
		const accessToken = authToken !== undefined ? authToken.access_token : undefined
		if (accessToken) {
			return 'Bearer ' + accessToken
		} else {
			// TODO the generated API doesn't support not returning a valid access token
			// We send a string, rather than nothing, so the server responds with a 401 rather
			// than a 403. A 401 signals that we need to authenticate, so the rest of our code
			// handles the failure appropriately. See handleDiscard.
			return 'INVALID'
		}
	},
	fetchApi: (input: RequestInfo, init?: RequestInit | undefined): Promise<Response> => {
		const url: string = (input as Request).url || (input as string)
		return apiTimeout(url, init)
	},
})

/**
 * 
 */
export function getOauthApi(headers?: Api.HTTPHeaders): Api.OauthApi {
	console.log('Path: ', getAppServerRootURL())
	const configuration = new Api.Configuration({
		basePath: getAppServerRootURL(),
		// TODO: store these deets properly
		apiKey: 'cCt888u1iL4jdSeCWgF5R606MSgZu5ua',
		username: getPlatformSupportImplementation().getPlatformClientId(), // client id
		password: getPlatformSupportImplementation().getPlatformClientSecret(), // client secret
		headers,
	})
	return new Api.OauthApi(configuration)
}

/**
 * 
 */
export function getRegisterApi(): Api.RegisterApi {
	console.log('Path: ', getAppServerRootURL())
	const configuration = new Api.Configuration({
		basePath: getAppServerRootURL(),
		apiKey: 'cCt888u1iL4jdSeCWgF5R606MSgZu5ua'
	})
	return new Api.RegisterApi(configuration)
}

/**
 * 
 */
export function getOrderApi(): Api.OrderApi {
	return new Api.OrderApi(oAuthConfig)
}

/**
 * 
 */
export function getUserApi(): Api.UserApi {
	return new Api.UserApi(oAuthConfig)
}

export function getInvoiceApi(): Api.InvoiceApi {
	return new Api.InvoiceApi(oAuthConfig)
}

export function getPaymentApi(): Api.PaymentApi {
	return new Api.PaymentApi(oAuthConfig)
}

export function getPriceBookApi(): Api.PricebookApi {
	return new Api.PricebookApi(oAuthConfig)
}

export function getListApi(): Api.ProductListsApi {
	return new Api.ProductListsApi(oAuthConfig)
}

export function getBranchApi(): Api.BranchApi {
	return new Api.BranchApi(oAuthConfig)
}

export function getContentApi(): Api.ContentApi {
	console.log('Path: ', getAppServerRootURL())
	const configuration = new Api.Configuration({
		basePath: getAppServerRootURL(),
		apiKey: 'cCt888u1iL4jdSeCWgF5R606MSgZu5ua',
		accessToken: oAuthConfig.accessToken,
	})
	return new Api.ContentApi(configuration)
}

export function getMetadataApi(): Api.MetadataApi {
	const configuration = new Api.Configuration({
		basePath: getAppServerRootURL(),
		apiKey: 'cCt888u1iL4jdSeCWgF5R606MSgZu5ua',
	})
	return new Api.MetadataApi(configuration)
}

export function getAuthMetadataApi(): Api.MetadataApi {
	return new Api.MetadataApi(oAuthConfig)
}

export function getEventsApi(): Api.EventsApi {
	return new Api.EventsApi(oAuthConfig)
}

export function getNotificationApi(): Api.NotificationApi {
	return new Api.NotificationApi(oAuthConfig)
}

var refreshingToken: Promise<AuthToken> | undefined
/**
 * This function was created to fix the issue where both offlineApi and callApi 401 errors were fetching a new access token at same time.
 * This meant that after the second one came back, the first one would already be invalid, and so the next request that tried to use it would fail.
 * This function fixes this by keeping a reference to inflight refreshToken requests and returning the existing request if one is available.
 */
export function refreshTokenSafely(): Promise<AuthToken> {
	// if already refreshing token, return the same promise
	if (refreshingToken) {
		return refreshingToken
	}
	refreshingToken = refreshTokenAndApply()
	// clear promise reference after it completes
	refreshingToken
		.then(() => {
			refreshingToken = undefined
		})
		.catch(() => {
			refreshingToken = undefined
		})
	return refreshingToken
}