import { AccountPermission, SignInRequest, SignInGrantTypeEnum, HTTPHeaders } from 'typescript-fetch-api'

import { ApiErrorMessage, getOauthApi } from '../api'
import { LoginRequest, AuthToken } from './types'
import { store } from '../root'
import * as actions from './actions'
import { getPlatformSupportImplementation } from '../platform'
import { UserAccount } from '../accounts/types'
import { isInvalidString } from '../util/functions'

/** Attempt to obtain an AccessToken with the given credentials. */
export function authenticate(request: LoginRequest): Promise<AuthToken> {
	console.log('authenticate', request)
	const grantType = request.refreshToken ? SignInGrantTypeEnum.REFRESH_TOKEN : SignInGrantTypeEnum.PASSWORD
	const clientId = getPlatformSupportImplementation().getPlatformClientId() // TODO: store this correctly
	const requestParams: SignInRequest = {
		grantType,
		clientId,
		username: request.username,
		password: request.password,
		refreshToken: request.refreshToken,
		pwRec: request.reCaptchaToken,
	}
	const headers: HTTPHeaders = { 'Content-Type': 'application/x-www-form-urlencoded' }
	return getOauthApi(headers).signIn(requestParams)
		.then((result: AuthToken) => {
			console.log('authenticate', result)
			return result
		})
		.catch((error) => {
			console.log('authenticate error', error)
			let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
			if (error instanceof Response) {
				switch (error.status) {
					case 400:
						return error.json().then(body => {
							const message = body.error_description ? body.error_description : 'Invalid credentials'
							throw new Error(message)
						})
					default:
						// @ts-ignore
						if (error.error_description) {
							// @ts-ignore
							errorText = error.error_description
						}
				}
			}
			throw new Error(errorText)
		})
}

/** Refresh the access token, apply it to the store, and return a promise
 * indicating whether or not it was successful. This methods gets the current
 * refresh token from the store, so there's no need to know any context to
 * call it.
 */
export function refreshTokenAndApply(): Promise<AuthToken> {
	return new Promise((resolve, reject) => {
		let authToken = store.getState().auth.authToken
		if (authToken) {
			authenticate({
				refreshToken: authToken.refresh_token
			}).then(result => {
				store.dispatch(actions.refreshedAuthToken(result))
				resolve(result)
			}).catch(error => {
				store.dispatch(actions.refreshAuthTokenFailed())
				reject(error)
			})
		} else {
			reject(new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN))
		}
	})
}

export function isValidEmail(email: string): boolean {
	if (isInvalidString(email)) {
		return false
	}
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function isText(text: string): boolean {
	return isNaN(Number(text))
}

export function validatePassword(password: string): string | undefined {
	let error = undefined
	if (password.length < 8) {
		error = 'Password must contain at least 8 characters'
	} else if (password.toLowerCase() === password) {
		error = 'Password should contain an uppercase letter'
	} else if (password.search(/\d/) === -1) {
		error = 'Password should contain a number'
	}
	return error
}

/**
 * To include prices when grabbing products, you need both the `customerId` and the `includePrices` flag which are extracted from
 * the passed user account.
 * - customerId: grab directly from account
 * - includePrices: compare account permissions to price permissions needed
 * 
 * @param account a user account
 */
export const getPriceParams = (account: UserAccount | undefined): { customerId: number | undefined, includePrices: boolean } => {
	let customerId: number | undefined
	let includePrices: boolean = false

	if (account) {
		customerId = account.customerId
		includePrices = account.permissions && account.permissions.some(item => (
			item === AccountPermission.VIEW_RRP_PRICING || item === AccountPermission.VIEW_TRADE_PRICING || item === AccountPermission.VIEW_ACCOUNT_PRICING
		)) ? true : false
	}

	return { customerId, includePrices }
}