export enum FilterType {
	PRODUCT, INVOICE_NUMBER, TRANSACTION_NUMBER, INVOICE_TYPE, AMOUNT, BRANCH, REFERENCE, ORDERED_BY, ORDER_STATUS, QUOTE_NUMBER, ADDRESS, PWGO, ORDER_NUMBER, COMMENT
}

export interface Filter {
	type: FilterType
	name: string
	tooltip?: string
}

export enum SortDirection {
	ASCENDING = 'asc',
	DESCENDING = 'desc',
}

export const OFFLINE_ACTION = 'Offline/STATUS_CHANGED'