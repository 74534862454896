import { SagaIterator } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'

import { NavigateToGeneralPathAction, NavigateToPSCOOPPathAction, NavigateToSupportPathAction, NavigateToTradePathAction, navigateToGeneralPath, navigateToPSCOOPPath, navigateToSupportPath, navigateToTradePath } from '../general/actions'
import * as NavigationManager from '../navigation/NavigationManager'
import { GeneralPathsEnum } from './types'

function handleNavigateToGeneralPath(action: NavigateToGeneralPathAction) {
	switch (action.payload) {
		case GeneralPathsEnum.TERMS_AND_CONDITIONS:
			NavigationManager.navigateToTermsAndConditions()
			break
		case GeneralPathsEnum.PRIVATE_POLICY:
			NavigationManager.navigateToPrivacyPolicy()
			break
		default:
			break
	}
}

function handleNavigateToSupportPath(action: NavigateToSupportPathAction) {
	NavigationManager.navigateToSupport(action.payload)
}

function handleNavigateToTradePath(action: NavigateToTradePathAction) {
	NavigationManager.navigateToTrade(action.payload)
}

function handleNavigateToPSCOOPPath(action: NavigateToPSCOOPPathAction) {
	NavigationManager.navigateToPSCoop(action.payload)
}

export default function* (): SagaIterator {
	yield takeEvery(navigateToGeneralPath, handleNavigateToGeneralPath)
	yield takeEvery(navigateToSupportPath, handleNavigateToSupportPath)
	yield takeEvery(navigateToTradePath, handleNavigateToTradePath)
	yield takeEvery(navigateToPSCOOPPath, handleNavigateToPSCOOPPath)
}