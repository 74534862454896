import '../styles/Footer.scss'
import '../styles/ConsumerFooter.scss'

import React from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Box, Divider } from '@mui/material'
import { useLocation } from 'react-router-dom'

import { PW_SOCIALS } from '../../contactus/content'
import { GeneralScreens, PSCOOPScreens, SupportScreens, TradeScreens } from '../../general/content'
import { navigateToContactUs } from '../../../common/contactus/actions'
import { navigateToTermsAndConditions, navigateToPrivacyPolicy } from '../../../common/profile/actions'
// import { logoutRequest } from '../../../common/auth/actions'
import { APP_STORE_URL, PLAY_STORE_URL } from '../../auth/content'
import { BadgeApple, BadgeGoogle } from '../../../utils/imagesProvider'
import * as images from '../../../utils/imagesProvider'
import { navigateToRegions } from '../../../common/showrooms/actions'
import { navigateToPSCOOPPath, navigateToSupportPath, navigateToTradePath } from '../../general/actions'
import { GeneralPathsEnum } from '../../general/types'
import { Paths } from '../../navigation/types'

const ConsumerFooter = (): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()
	const location = useLocation()
	const isHomeRoute = location.pathname === Paths.HOME

	/**
	 * Local Functions
	 */

	const _onNavigateToContactUs = () => {
		dispatch(navigateToContactUs())
	}

	const _onNavigateToTOC = () => {
		dispatch(navigateToTermsAndConditions())
	}

	const _onNavigateToPrivacyPolicy = () => {
		dispatch(navigateToPrivacyPolicy())
	}

	if (!isHomeRoute) {
		return (
			<div className="max-container consumer-footer consumer-footer--bottom footer">
				<div className="footer__content">
					<div className="footer__bottom">
						<span className="footer__text">
							Copyright © {moment().year()} Plumbers Co-op
							&nbsp;&nbsp;|&nbsp;&nbsp;
							V{process.env.REACT_APP_VERSION}
						</span>

						<div className="footer__links footer__text">
							<span className="footer__links--clickable" onClick={_onNavigateToTOC}>
								{GeneralScreens[GeneralPathsEnum.TERMS_AND_CONDITIONS].title}
							</span>

							<span className="footer__links--clickable" onClick={_onNavigateToPrivacyPolicy}>
								{GeneralScreens[GeneralPathsEnum.PRIVATE_POLICY].title}
							</span>

							{/* <span className="footer__links--clickable">Site Map</span> */}
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<React.Fragment>
			<div className="max-container consumer-footer consumer-footer--top footer footer-auth">
				<div className="footer__content">
					<div className="footer__top">
						<div className="footer__top-item">
							<span className="consumer-footer__text--heading">PS CO-OP</span>

							{/*<span className="consumer-footer__text">Find us</span>*/}
							{Object.keys(PSCOOPScreens).map(key => {
								const screen = PSCOOPScreens[key]
								return (
									<span
										key={key}
										className="consumer-footer__text consumer-footer__text--clickable"
										onClick={() => dispatch(navigateToPSCOOPPath(screen.path))}
									>
										{screen.title}
									</span>
								)
							})}
							{/*<span className="consumer-footer__text" onClick={() => dispatch(logoutRequest({}))}>Sign out</span>*/}
						</div>

						<div className="footer__top-item">
							<span className="consumer-footer__text--heading">SUPPORT</span>

							<span className="consumer-footer__text consumer-footer__text--clickable" onClick={_onNavigateToContactUs}>Contact us</span>
							{/*<span className="consumer-footer__text">FAQs</span>*/}
							{Object.keys(SupportScreens).map(key => {
								const screen = SupportScreens[key]
								return (
									<span
										key={key}
										className="consumer-footer__text consumer-footer__text--clickable"
										onClick={() => dispatch(navigateToSupportPath(screen.path))}
									>
										{screen.title}
									</span>
								)
							})}
						</div>

						<div className="footer__top-item">
							{/* <div className="consumer-footer__section">
								<span className="consumer-footer__text--heading">INSIDERS GUIDE</span>

								<TextField
									placeholder="Subscribe Here"
									InputProps={{
										disableUnderline: true,
									}}
								/>
							</div>

							<Box m={2} /> */}

							<div className="consumer-footer__section">
								<span className="consumer-footer__text--heading">FOLLOW US</span>

								<span>
									{PW_SOCIALS.map(item => (
										<a key={item.icon} className="footer__social" href={item.url} target="_blank" rel="noopener noreferrer">
											<span className={`${item.icon} footer__icons--social`} />
										</a>
									))}
								</span>
							</div>
						</div>

						<div className="footer__top-item">
							<Divider orientation="vertical" className="consumer-footer__divider" />
						</div>

						<div className="footer__top-item">
							<span className="consumer-footer__text--heading">TRADE</span>
							{Object.keys(TradeScreens).map(key => {
								const screen = TradeScreens[key]
								return (
									<span
										key={key}
										className="consumer-footer__text consumer-footer__text--clickable"
										onClick={() => dispatch(navigateToTradePath(screen.path))}
									>
										{screen.title}
									</span>
								)
							})}
							<span className="consumer-footer__text consumer-footer__text--clickable" onClick={() => dispatch(navigateToRegions())}>Find a branch</span>
						</div>

						<div className="footer__top-item">
							<div className="consumer-footer__section">
								<span className="consumer-footer__text--heading consumer-footer__text--app">Building Your Future with</span>

								<img
									src={images.CoopNowHrLogo}
									className="consumer-footer__logo"
									alt="Co-op Now"
								/>

								<Box m={2} />

								{/* <Button variant="contained" color="info" className="consumer-footer__button button--fixed-width">
									TRADE WEBSITE
								</Button> */}
							</div>

							<div className="consumer-footer__section consumer-footer__section--no-gap">
								<span className="consumer-footer__text--heading consumer-footer__text--app">DOWNLOAD CO-OP<i>NOW</i> APP</span>

								<div className="footer__badges">
									<a href={APP_STORE_URL} target="_blank" rel="noopener noreferrer">
										<img src={BadgeApple} className="footer__image--apple" alt="Apple App Store" />
									</a>
									<a href={PLAY_STORE_URL} target="_blank" rel="noopener noreferrer">
										<img src={BadgeGoogle} className="footer__image--google" alt="Google Play Store" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="max-container consumer-footer consumer-footer--bottom footer">
				<div className="footer__content">
					<div className="footer__bottom">
						<span className="footer__text">
							Copyright © {moment().year()} Plumbers Co-op
							&nbsp;&nbsp;|&nbsp;&nbsp;
							V{process.env.REACT_APP_VERSION}
						</span>

						<div className="footer__links footer__text">
							<span className="footer__links--clickable" onClick={_onNavigateToTOC}>
								{GeneralScreens[GeneralPathsEnum.TERMS_AND_CONDITIONS].title}
							</span>

							<span className="footer__links--clickable" onClick={_onNavigateToPrivacyPolicy}>
								{GeneralScreens[GeneralPathsEnum.PRIVATE_POLICY].title}
							</span>

							{/* <span className="footer__links--clickable">Site Map</span> */}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default ConsumerFooter