import { CarouselItem, CarouselPlatform, CarouselResponse } from 'typescript-fetch-api'
import actionCreatorFactory, { Action, Success } from 'typescript-fsa'

import { PlatformProduct } from '../../modules/platform'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Home')

// NOTE: this action updates the openQuickSearch flag in the store, which is used to focus the search input on the categories/products toolbar
export const navigateToProductsTabAndOpenSearch = actionCreator('NAVIGATE_TO_PRODUCTS_TAB_AND_OPEN_SEARCH')

export type NavigateToCarouselViewerAction = Action<CarouselItem>
export const navigateToCarouselViewer = actionCreator<CarouselItem>('NAVIGATE_TO_CAROUSEL_VIEWER')

export const clearOpenQuickSearchFlag = actionCreator<undefined>('CLEAR_OPEN_QUICK_SEARCH_FLAG')

export interface SendTokenRequestPayload {
	token: string
}
export type SendTokenRequestAction = Action<SendTokenRequestPayload>
export const sendRefreshTokenAction = actionCreator<SendTokenRequestPayload>('SEND_TOKEN')

export type FetchCarouselAction = Action<CarouselPlatform>
export const fetchCarousel = actionCreator.async<CarouselPlatform, CarouselResponse, Error>('FETCH_CAROUSEL')

export interface FetchProductOffersPayload {
	customerId?: number
}
export type FetchProductOffersAction = Action<FetchProductOffersPayload>
export type FetchProductOffersSuccessAction = Action<Success<FetchProductOffersPayload, ReadonlyArray<PlatformProduct>>>
export const fetchProductOffers = actionCreator.async<FetchProductOffersPayload, ReadonlyArray<PlatformProduct>, Error>('FETCH_PRODUCT_OFFERS')

export const navigateToMyAccount = actionCreator('NAVIGATE_TO_MY_ACCOUNT')