import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'

import * as actions from './actions'
import { Region } from './types'

export interface StoreState {
	readonly regions: Array<Region>
}

const INITIAL_STATE: StoreState = {
	regions: [],
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(actions.storedRegions, (state, payload): StoreState => ({
	...state, regions: payload
}))

reducer.case(actions.fetchClickAndCollectTimeslots.done, (state, { params, result }): StoreState => {
	const branchId = params.id.toString()
	const updatedRegions = state.regions.map(region => {
		// check if the branch belongs in this region
		const branchInRegion = region.branches.some(branch => branch.id === branchId)
		if (branchInRegion) {
			return {
				...region,
				// loop through branches in the region and update the c&c timeslots for branch in the request
				branches: region.branches.map(branch => {
					const branchToUpdate = branch.id === branchId
					if (branchToUpdate) {
						return {
							...branch,
							clickAndCollectTimeslots: result,
						}
					}
					return branch
				}),
			}
		}
		return region
	})

	return {
		...state,
		regions: updatedRegions,
	}
})

reducer.case(actions.fetchDeliveryTimeslots.done, (state, { params, result }): StoreState => {
	const branchId = params.id.toString()
	const updatedRegions = state.regions.map(region => {
		// check if the branch belongs in this region
		const branchInRegion = region.branches.some(branch => branch.id === branchId)
		if (branchInRegion) {
			return {
				...region,
				// loop through branches in the region and update the delivery timeslots for branch in the request
				branches: region.branches.map(branch => {
					const branchToUpdate = branch.id === branchId
					if (branchToUpdate) {
						return {
							...branch,
							deliveryTimeslots: result,
						}
					}
					return branch
				}),
			}
		}
		return region
	})

	return {
		...state,
		regions: updatedRegions,
	}
})