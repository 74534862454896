import { BranchProductDetail } from 'typescript-fetch-api'

export enum SpecSheet {
	SPECS = 'specs',
	BROCHURES = 'brochure',
	MSDS = 'msds',
	WARRANTY = 'warranty',
	INSTALL = 'install',
	TECHDRAW = 'TechDraw',
}

export interface BranchesAvailability {
	northern: BranchProductDetail[],
	central: BranchProductDetail[],
	lowerCentral: BranchProductDetail[],
	southern: BranchProductDetail[],
}
