import { SagaIterator, Channel } from 'redux-saga'
import { actionChannel, call, flush, fork, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { AddListRequest, DeleteListRequest, GetListRequest, GetListsRequest, PagedProductListResponse, ProductList, ProductListItem, ProductListType, ProductPriceRequestList, ProductSku, UpdateListProductsOperationRequest } from 'typescript-fetch-api'

import * as actions from '../../common/mylists/actions'
import * as rootActions from '../../common/root/actions'
import * as authActions from '../../common/auth/actions'
import { authTokenSelector } from '../auth/selectors'
import { ApiErrorMessage, getContentApi, getListApi } from '../api'
import { callApi } from '../api/functions'
import { mapPagedProductListToProductList } from './functions'
import { listSelector } from './selectors'
import { selectedAccountSelector } from '../order/selectors'
import { getPriceParams } from '../auth/functions'
import { UserAccount } from '../accounts/types'

const MAX_SKU_SEARCH_COUNT = 20

function* handleLoadLists(): SagaIterator {
	yield put(actions.fetchLists.started({ includeItems: false }))
}

function* handleLoadListsOnReady(): SagaIterator {
	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	if (hasAuthToken) {
		yield call(handleLoadLists)
	}
}

function* handleLoadListsOnLogin(): SagaIterator {
	yield call(handleLoadLists)
}

function* handleGetLists(action: actions.FetchListsAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.fetchLists.failed({ error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		const { payload } = action
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.fetchLists,
			() => {
				if (payload) {
					const requestParams: GetListsRequest = {
						...payload,
						productListType: ProductListType.PRODUCT,
						includePwgo: true,
					}
					return getListApi().getLists(requestParams)
				} else {
					return getListApi().getLists({ productListType: ProductListType.PRODUCT, includePwgo: true })
				}
			})
	}
}

function* handleGetList(action: actions.FetchListAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.fetchList,
		(payload: actions.FetchListPayload) => {
			const requestParams: GetListRequest = {
				id: payload.id,
				includePrices: payload.includePrices,
				customerId: payload.customerId,
				page: payload.page,
				pageSize: payload.pageSize,
			}
			return getListApi().getList(requestParams)
				.then((response: PagedProductListResponse) => mapPagedProductListToProductList(response))
		})
}

/**
 * This channel allows the queueing of requests for getting lists.
 * If we have at any moment four actions, we want to handle the first REQUEST action, then only after finishing this action we process the second action and so on...
 */
function* getListChannel() {
	// create a channel for request actions to create/edit a list
	const requestChannel: Channel<actions.FetchListAction> = yield actionChannel(actions.fetchList.started.type)
	while (true) {
		// take from the channel
		const action: actions.FetchListAction = yield take(requestChannel)
		// use a blocking call and perform the requests sequentially
		yield call(handleGetList, action)
	}
}

/**
 * This channel allows the queueing of requests for creating/editing a list.
 * If we have at any moment four actions, we want to handle the first REQUEST action, then only after finishing this action we process the second action and so on...
 * https://redux-saga.js.org/docs/advanced/Channels.html
 * 
 * This was introduced to fix the problem when removing an item from a list. In web, users can select multiple lists to delete the product from, and because each
 * request for `addList()` returns the entire list group, list items would tend to reappear since they might not have been deleted in the next/previous request's pov.
 */
function* createOrEditListChannel() {
	// create a channel for request actions to create/edit a list
	const requestChannel: Channel<actions.CreateOrEditListAction> = yield actionChannel(actions.createOrEditList.started.type)
	while (true) {
		// take from the channel
		const action: actions.CreateOrEditListAction = yield take(requestChannel)
		// use a blocking call and perform the requests sequentially
		yield call(handleCreateOrEditList, action)
	}
}

function* handleCreateOrEditList(action: actions.CreateOrEditListAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.createOrEditList.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.createOrEditList,
			(payload: actions.CreateOrEditListPayload) => {
				const { list, includePrices, customerId, includeItems } = payload
				const requestParams: AddListRequest = {
					productList: list,
					includePrices,
					customerId,
					productListType: ProductListType.PRODUCT,
					includeItems,
					includePwgo: true,
				}
				return getListApi().addList(requestParams)
			})
	}
}

function* handleDeleteList(action: actions.DeleteListAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.deleteList.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.deleteList,
			(payload: actions.DeleteListPayload) => {
				const { list, includePrices, customerId, includeItems } = payload
				const requestParams: DeleteListRequest = {
					id: list.id,
					includePrices,
					customerId,
					productListType: ProductListType.PRODUCT,
					includeItems,
					includePwgo: true,
				}
				return getListApi().deleteList(requestParams)
			})
	}
}

/**
 * This channel allows the queueing of requests for updating products in a list.
 * If we have at any moment four actions, we want to handle the first REQUEST action, then only after finishing this action we process the second action and so on...
 * https://redux-saga.js.org/docs/advanced/Channels.html
 */
function* updateProductsInListChannel() {
	// create a channel for request actions to create/edit a list
	const requestChannel: Channel<actions.UpdateProductsInListAction> = yield actionChannel(actions.updateProductsInList.started.type)
	while (true) {
		// take from the channel
		const action: actions.UpdateProductsInListAction = yield take(requestChannel)
		// use a blocking call and perform the requests sequentially
		yield call(handleUpdateProductsInList, action)
	}
}

/**
 * Handles updating multiple products in a list
 * @param action the action containing the list id and basic product list item details
 */
function* handleUpdateProductsInList(action: actions.UpdateProductsInListAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.updateProductsInList,
		(payload: actions.UpdateProductsInListPayload) => {
			const { id, products, includePrices, customerId, page, pageSize, updateActionType } = payload
			const requestParams: UpdateListProductsOperationRequest = {
				id,
				includePrices,
				customerId,
				page,
				pageSize,
				updateActionType,
				updateListProductsRequest: { products },
			}
			return getListApi().updateListProducts(requestParams)
				.then((response: PagedProductListResponse) => mapPagedProductListToProductList(response))
		})
}

/**
 * Handles adding a product to a list
 * @param action the action containing the list id and basic product list item details
 */
function* handleAddProductToList(action: actions.AddProductToListAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.addProductToList,
		(payload: actions.AddProductToListPayload) => {
			const { id, sku, quantity, includePrices, customerId, page, pageSize } = payload
			const product: ProductListItem = { sku, quantity }
			const requestParams: UpdateListProductsOperationRequest = {
				id,
				includePrices,
				customerId,
				page,
				pageSize,
				updateListProductsRequest: { products: [product] },
			}
			return getListApi().updateListProducts(requestParams)
				.then((response: PagedProductListResponse) => mapPagedProductListToProductList(response))
		})
}

/**
 * Handles removing a product from a list
 * @param action the action containing the list id and the product sku to delete
 */
function* handleRemoveProductFromList(action: actions.RemoveProductFromListAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.removeProductFromList, (payload: actions.RemoveProductFromListPayload) => {
		const { id, sku, includePrices, customerId, page, pageSize } = payload
		const quantity = 0
		const product: ProductListItem = { sku, quantity }
		const requestParams: UpdateListProductsOperationRequest = {
			id,
			includePrices,
			customerId,
			page,
			pageSize,
			updateListProductsRequest: { products: [product] },
		}
		return getListApi().updateListProducts(requestParams)
			.then((response: PagedProductListResponse) => mapPagedProductListToProductList(response))
	})
}

/**
 * Handles updating the title of a list
 * @param action the action containing the updated list title
 */
function* handleUpdateListTitle(action: actions.UpdateListTitleAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.updateListTitle, (payload: actions.UpdateListTitlePayload) => {
		const { id, title } = payload
		return getListApi().updateListTitle({ id: id, updateListTitleRequest: { title } })
	})
}

/**
 * Sequentially updates a list's title and products
 * @param action the action containing the updated list details
 */
function* handleUpdateListTitleAndProducts(action: actions.UpdateListTitleAndProductsAction): SagaIterator {
	try {
		const { id, title, products, ...rest } = action.payload

		// 1 - update the list title
		yield call(() => getListApi().updateListTitle({ id, updateListTitleRequest: { title } }))

		// 2 - update the products (if any)
		let updatedProductList: ProductList | undefined
		// if products array is empty, don't attempt to send to server, as it rejects requests made with empty products array
		if (products.length > 0) {
			updatedProductList = yield call(() => getListApi().updateListProducts({ id, updateListProductsRequest: { products }, ...rest })
				.then((response: PagedProductListResponse) => mapPagedProductListToProductList(response)))
		} else {
			// grab the list from the store so we can pass that as the result with the updated title
			const storedList = yield select(listSelector(id))
			if (storedList) {
				updatedProductList = { ...storedList, title }
			}
		}

		// 3 - update the list in the store
		// NOTE: we pass the bare minimum list in the unlikely case that it couldn't be found in the store
		yield put(actions.updateListTitleAndProducts.done({ params: action.payload, result: updatedProductList || { id, title } }))
	} catch (error) {
		yield put(actions.updateListTitleAndProducts.failed({ params: action.payload, error: error as Error }))
	}
}

function* handleUploadList(action: actions.UploadListRequestAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.uploadList,
		(payload: actions.UploadListParams) => {
			const { listId, file } = payload
			return getListApi().uploadList({ id: listId, body: file })
		})
}

function* handleUploadNewList(action: actions.UploadNewListRequestAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.uploadNewList,
		(payload: actions.UploadNewListParams) => {
			const { listId, title, customerId } = payload
			const requestParams: AddListRequest = {
				productList: { id: listId, title, customerId },
				productListType: ProductListType.PRODUCT,
				customerId, // need to provide customerId here too as thats what server is currently using to check user has permission to create account list
			}
			return getListApi().addList(requestParams)
		})
}

function* handleUploadListAfterCreate(action: actions.UploadNewListSuccessAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.uploadList,
		(payload: actions.UploadNewListSuccessPayload) => {
			const { listId, file } = payload.params
			// add the products to newly created list
			return getListApi().uploadList({ id: listId, body: file })
		})
}

function* handleUploadListSuccess(action: actions.UploadListSuccessAction): SagaIterator {
	// Fetch pricing for updated list
	// This was added incase the user is viewing the list details and uses the ProductUploadCSV button, we want to make sure we fetch the pricing for newly added products
	const { params: { fetchPricingAfter }, result: { list } } = action.payload
	if (fetchPricingAfter && list) {
		// get selected account so we can fetch pricing
		const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
		// create mocked action to reuse saga to fetch pricing
		const mockedAction: actions.FetchListSuccessAction = { payload: { params: { id: list.id!, customerId: selectedAccount?.customerId }, result: mapPagedProductListToProductList(list) }, type: action.type }
		yield call(handleFetchListSuccess, mockedAction)
	}
}

function* handlePinList(action: actions.PinListAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.pinList, (listId: string) => {
		return getListApi().pinProductList({ id: listId })
	})
}

function* handleUnpinList(action: actions.PinListAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.unpinList, (listId: string) => {
		return getListApi().unpinProductList({ id: listId })
	})
}

/**
 * Handles fetching pricing for products in a list
 * @param action the action containing the fetched list
 */
function* handleFetchListSuccess(action: actions.FetchListSuccessAction): SagaIterator {
	const { params: { id: listId, includePrices, customerId } } = action.payload
	const products = action.payload.result.productListItems || []
	const skus: ProductSku[] = products.map(product => ({ sku: product.sku }))
	if (skus.length > 0) {
		// check prices were not included in original request (if they were theres not point loading them again)
		if (!includePrices) {
			yield call(handleFetchPricingForList, listId, skus, customerId)
		}
	}
}

function* handleFetchPricingForList(listId: string, skus: ProductSku[], customerId?: number, appendToList?: boolean): SagaIterator {
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	// check selected account matches account used in request
	if (selectedAccount?.customerId === customerId) {
		// check if user can actually view pricing
		const { customerId, includePrices } = getPriceParams(selectedAccount)
		if (includePrices) {
			// do not cancel pending actions if appending to list (eg user adds a new item to a list before all the pricing requests for existing items has finished)
			if (!appendToList) {
				yield put(actions.cancelGetListProductPrices())
			}

			// load prices
			if (skus.length <= MAX_SKU_SEARCH_COUNT) {
				yield put(actions.getListProductsPrices.started({ skus, customerId, listId, appendToList }))
			} else {
				// load the prices for the skus by batch
				for (let i = 0; i <= skus.length - 1; i += MAX_SKU_SEARCH_COUNT) {
					const currentBatch = skus.slice(i, i + MAX_SKU_SEARCH_COUNT)
					// NOTE: we append the results to the current list since we're batching the requests
					// include if last batch to help with hiding loading flag
					const isLastBatch: boolean = i + MAX_SKU_SEARCH_COUNT > skus.length - 1
					yield put(actions.getListProductsPrices.started({ skus: currentBatch, customerId, listId, appendToList: i > 0, isLastBatch }))
				}
			}
		}
	}
}

function* handleProductAddedTolist(action: actions.AddProductToListSuccessAction): SagaIterator {
	const { params: { id: listId, includePrices, sku, customerId } } = action.payload
	const skus: ProductSku[] = [{ sku }]

	// check prices were not included in original request (if they were theres not point loading them again)
	if (!includePrices) {
		// load pricing for single product
		// - appendToList so requests for prices of other items in the list don't get cancelled
		yield call(handleFetchPricingForList, listId, skus, customerId, true)
	}
}

function* handleGetListProductsPrices(action: actions.GetListProductsPricesAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.getListProductsPrices,
		({ skus, customerId }: actions.GetListProductsPricesPayload) => {
			const products: ProductPriceRequestList = { products: skus }
			return getContentApi().getProductPrices({ productPriceRequestList: products, customerId })
		}
	)
}

function* getListProductsPricesChannel() {
	// 1. Create a channel to watch for `getListProductsPrices` actions and queue them
	const channel: Channel<actions.GetListProductsPricesAction> = yield actionChannel(actions.getListProductsPrices.started)

	while (true) {
		// 2. Pop request from the channel
		const action: actions.GetListProductsPricesAction = yield take(channel)

		const { cancel } = yield race({
			// use a blocking call and perform the requests sequentially
			task: call(handleGetListProductsPrices, action),
			cancel: take(actions.cancelGetListProductPrices)
		})

		if (cancel) {
			// clear the channel queue
			yield flush(channel)
		}
	}
}

export default function* (): SagaIterator {
	yield fork(getListChannel)
	yield fork(createOrEditListChannel)
	yield fork(updateProductsInListChannel)
	yield fork(getListProductsPricesChannel)
	yield takeEvery(rootActions.readyAction, handleLoadListsOnReady)
	yield takeEvery(authActions.login.done, handleLoadListsOnLogin)
	yield takeEvery(actions.fetchLists.started, handleGetLists)
	yield takeEvery(actions.deleteList.started, handleDeleteList)
	yield takeEvery(actions.addProductToList.started, handleAddProductToList)
	yield takeEvery(actions.addProductToList.done, handleProductAddedTolist)
	yield takeEvery(actions.removeProductFromList.started, handleRemoveProductFromList)
	yield takeEvery(actions.updateListTitle.started, handleUpdateListTitle)
	yield takeEvery(actions.updateListTitleAndProducts.started, handleUpdateListTitleAndProducts)
	yield takeEvery(actions.uploadList.started, handleUploadList)
	yield takeEvery(actions.uploadList.done, handleUploadListSuccess)
	yield takeEvery(actions.uploadNewList.started, handleUploadNewList)
	yield takeEvery(actions.uploadNewList.done, handleUploadListAfterCreate)
	yield takeEvery(actions.pinList.started, handlePinList)
	yield takeEvery(actions.unpinList.started, handleUnpinList)
	yield takeLatest(actions.fetchList.done, handleFetchListSuccess)
}