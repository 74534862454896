import { ListCategory } from './types'

export const MY_LISTS_ID = 0
export const MY_LISTS_TITLE = 'My Lists'
export const MY_LISTS_URL_ID = 'my-lists'
export const PWGO_LISTS_ID = -1
export const PWGO_LISTS_TITLE = 'co-opNOW Lists'
export const PWGO_LISTS_URL_ID = 'pscoop-lists'

export const API_USER_LISTS_TITLE = 'USER_LISTS'
export const API_PWGO_LISTS_TITLE = 'PWGO_LISTS'

export const UNICODE_LONG_DASH = '\u2014'

export const DEFAULT_LIST_CATEGORY: ListCategory = {
    customerId: undefined,
    name: MY_LISTS_TITLE,
}
