import { RemoteConfig, RemoteConfigFeature, RemoteConfigProperty } from './types'

export const DEFAULT_REMOTE_CONFIG_FEATURE: RemoteConfigFeature = {
	[RemoteConfigProperty.USERS]: [],
	[RemoteConfigProperty.CUSTOMERS]: [],
	[RemoteConfigProperty.AVAILABLE_TO_ALL]: false,
}

// default values for each remote config
// NOTE: we populate these values on firebase as JSON
export const REMOTE_CONFIG_DEFAULTS: Record<RemoteConfig, string> = {
	[RemoteConfig.NOTIFY_ME]: JSON.stringify(DEFAULT_REMOTE_CONFIG_FEATURE),
	[RemoteConfig.STOCK_COUNTS]: JSON.stringify(DEFAULT_REMOTE_CONFIG_FEATURE),
	[RemoteConfig.BARCODE_SCANNER]: JSON.stringify(DEFAULT_REMOTE_CONFIG_FEATURE),
	[RemoteConfig.AROFLO]: JSON.stringify(DEFAULT_REMOTE_CONFIG_FEATURE),
	[RemoteConfig.CLICK_AND_COLLECT]: JSON.stringify(DEFAULT_REMOTE_CONFIG_FEATURE),
}
