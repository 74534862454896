import { SagaIterator } from 'redux-saga'
import { takeEvery, put } from 'redux-saga/effects'
import { CarouselPlatform } from 'typescript-fetch-api'

import { readyAction } from '../../common/root/actions'
import { fetchCarousel } from '../../common/home/actions'

function* handleLoadingCarousel(): SagaIterator {
	yield put(fetchCarousel.started(CarouselPlatform.WEB))
}

export default function* (): SagaIterator {
	yield takeEvery(readyAction, handleLoadingCarousel)
}
