import { Environment } from '../common/api/types'
import { getEnvironment } from '../modules/platform/functions'
import { RangeFilter, SingleValueContainsFilter } from './types'
import * as images from './imagesProvider'
import { MobilePrefixOptions } from '../modules/register/types'

export const DATE_DATA_FORMAT = 'YYYY-MM-DD'
export const DATE_DISPLAY_FORMAT = 'DD/MM/YYYY'

export const ORDER_AND_QUOTES_TYPE_VALUE = 'BOTH'
export const ORDERS_TYPE_VALUE = 'ORDER'
export const QUOTES_TYPE_VALUE = 'QUOTE'

export const NUMBER_REGEX = /^[0-9\b]+$/

export function getDisplayTitleForFilterRadio(radio: 'contains' | 'doesNotContain' | 'equals'): string {
	switch (radio) {
		case 'equals':
			return 'equals'
		case 'contains':
			return 'contains'
		case 'doesNotContain':
			return 'does not contain'
		default:
			throw Error('Unsupported filter radio: ' + radio)
	}
}

export function getDisplayTitleForRange(filter: RangeFilter): string {
	switch (filter.radio) {
		case 'greaterThan':
			return 'greater than ' + filter.lowerValue
		case 'between':
			return 'between ' + filter.lowerValue + ' - ' + filter.higherValue
		case 'lessThan':
			return 'less than ' + filter.higherValue
		default:
			throw Error('Unsupported filter range: ' + filter.radio)
	}
}

export function getDisplayTitleForInvoiceType(invoiceType: 'Invoice' | 'Credit Note' | 'Cash Sale'): string {
	switch (invoiceType) {
		case 'Invoice':
			return 'Invoice'
		case 'Credit Note':
			return 'Credit Note'
		case 'Cash Sale':
			return 'Cash Sale'
		default:
			throw Error('Unsupported filter invoice type: ' + invoiceType)
	}
}

export function checkSingleValueFilter(filter: SingleValueContainsFilter, value: string): boolean {
	if (filter.radio === 'contains') {
		return value.toLowerCase().includes(filter.value.toLowerCase())
	} else {
		return !value.toLowerCase().includes(filter.value.toLowerCase())
	}
}

export function checkRangeFilter(filter: RangeFilter, value: number): boolean {
	if (filter.radio === 'greaterThan') {
		return value > filter.lowerValue!
	} else if (filter.radio === 'lessThan') {
		return value < filter.higherValue!
	} else {
		return value > filter.lowerValue! && value < filter.higherValue!
	}
}

/**
 * Provides a formatted transaction number based on an id and a suffix
 */
export function getTransactionNumber(id: string, suffix: number | undefined): string {
	const fullCustomerOrderNumber: string = id.replace(/^0+/, '') +
		(suffix === undefined || suffix === 0 ? '' :
			suffix < 10 ? '/0' + suffix : // padding zero
				'/' + suffix)
	return fullCustomerOrderNumber
}

/**
 * Removes the last symbol and its trailing characters.
 * @param value string to remove the characters from
 * @param symbol symbol to use as reference
 */
export const removeTrailingCharsAfterLastSymbol = (value: string, symbol: string): string => (
	value.substring(0, value.lastIndexOf(symbol))
)

/**
 * Validates if a string corresponds to a number.
 * Is used for `type=number` inputs to fix the mui textfield error of overlapping placeholders when a non-number input is inputted.
 * https://github.com/mui-org/material-ui/issues/4050
 * @param value string to validate
 */
export const validateNumberInput = (value?: string): boolean => {
	if (!value || (value && NUMBER_REGEX.test(value))) {
		return true
	}
	return false
}

/**
 * Handles downloading a file without a url for web.
 * @param file file to download
 * @param filename the name of the file
 */
export const downloadFile = (file: Blob, filename?: string) => {
	const a = document.createElement('a')
	const blobURL = URL.createObjectURL(file)
	if (filename) {
		a.download = filename
	}
	a.href = blobURL
	document.body.appendChild(a)
	a.click()
	document.body.removeChild(a)
}

/**
 * Handles applying the max length on the number textfields.
 * Logic has to be controlled on our end as the max length attribute for number inputs doesn't play well with mui.
 * https://github.com/mui-org/material-ui/issues/10934#issuecomment-379028522
 */
export const handleNumberInputWithMaxLength = (e: React.FormEvent<HTMLInputElement>) => {
	// cast event to prevent ts error when accessing attributes
	// https://stackoverflow.com/a/44321394/7696864
	const target = e.target as HTMLInputElement
	const { value, maxLength } = target
	if (value.length >= maxLength) {
		target.value = value.slice(0, maxLength)
	}
}

export function getLogo() {
	if (getEnvironment() === Environment.TEST) {
		return images.LogoTest
	}
	return images.Logo
}

/**
 * Handles obtaining the mobile number and prefix given a string/text.
 * @param mobileNumberToSplit the string to get the prefix and mobile number from
 * @returns the split prefix (if available) and mobile number
 */
export const getMobileNumberAndPrefix = (mobileNumberToSplit: string): { prefix: string, mobileNumber: string } => {
	let mobilePrefix = ''
	let mobileNumber
	// grab the first 3 chars from the mobile number
	const prefix = mobileNumberToSplit.substr(0, 3)
	// found a matching prefix
	if (MobilePrefixOptions.some(option => option.value === prefix)) {
		mobilePrefix = prefix
		// grab all the chars after the prefix
		mobileNumber = mobileNumberToSplit.substr(3)
	} else {
		// no valid prefix - add in all chars
		mobileNumber = mobileNumberToSplit
	}
	return { prefix: mobilePrefix, mobileNumber }
}