import { SagaIterator } from 'redux-saga'
import { all, call, select, takeEvery } from 'redux-saga/effects'

import * as authActions from '../../common/auth/actions'
import * as rootActions from '../../common/root/actions'
import { authTokenSelector } from '../../common/auth/selectors'

/**
 * Handles fetching all remote configs from firebase.
 * Note: We keep this platform-specific since we might not need all remote configs for web and vice versa.
 */
function* handleFetchAllRemoteConfigs(): SagaIterator {
	yield all([])
}

function* handleFetchRemoteConfigOnReady(): SagaIterator {
	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	if (hasAuthToken) {
		yield call(handleFetchAllRemoteConfigs)
	}
}

export default function* (): SagaIterator {
	yield takeEvery(authActions.login.done, handleFetchAllRemoteConfigs)
	yield takeEvery(rootActions.readyAction, handleFetchRemoteConfigOnReady)
}