import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { ProductList, ProductPrices, BranchProductDetail, RelatedProduct } from 'typescript-fetch-api'

import * as actions from './actions'
import { PlatformProduct } from '../../modules/platform'
import { getPlatformSupportImplementation } from '../platform'
import { accountSelected } from '../cart/actions'
import { BranchesAvailability } from './types'
import { updateBranchesAvailability } from './functions'

export interface StoreState {
	// this is how the Product container gets hold of the selected product (instead of passing through Navigation params)
	// we only store it if we know its a Realm instance (so either coming from some Search screen or gets loaded by the Product screen itself if coming from the cart/mylist)
	readonly selectedProduct?: PlatformProduct
	readonly selectedProductPrices?: ProductPrices
	readonly loadingProductDetails: boolean // we only use this loading flag to prevent duplicate loading if Product component updates (by some other means) before the DB action completes
	readonly errorLoadingProductDetails?: Error
	readonly selectedProductLists: ProductList[]

	readonly productForBarcode?: PlatformProduct
	readonly productPricesForBarcode?: ProductPrices
	readonly loadingProductForBarcode: boolean
	readonly errorLoadingProductForBarcode?: Error

	readonly branchAvailability?: BranchProductDetail
	readonly branchAvailabilityForBarcode?: BranchProductDetail
	readonly loadingProductAvailability: boolean

	readonly branchesAvailability: BranchesAvailability
	readonly loadingBranchesAvailability: string[] // store the region ids to know which branch availability is being loaded

	readonly relatedProductPrices: ProductPrices[]
	readonly loadingRelatedProductPrices: boolean

	readonly relatedProducts: RelatedProduct[]
	readonly loadingRelatedProducts: boolean
}

const INITIAL_STATE: StoreState = {
	selectedProduct: undefined,
	selectedProductPrices: undefined,
	loadingProductDetails: false,
	errorLoadingProductDetails: undefined,
	selectedProductLists: [],

	productForBarcode: undefined,
	productPricesForBarcode: undefined,
	loadingProductForBarcode: false,
	errorLoadingProductForBarcode: undefined,

	branchAvailability: undefined,
	branchAvailabilityForBarcode: undefined,
	loadingProductAvailability: false,

	branchesAvailability: {
		northern: [],
		central: [],
		lowerCentral: [],
		southern: [],
	},
	loadingBranchesAvailability: [],

	relatedProducts: [],
	loadingRelatedProducts: false,

	relatedProductPrices: [],
	loadingRelatedProductPrices: false,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(actions.navigateToProduct, (state, payload): StoreState => {
	// this is to not reset the contents of the selected product when there are no product details being passed
	if (!payload.productDetails) {
		return {
			...state, errorLoadingProductDetails: undefined, loadingProductDetails: false, branchesAvailability: INITIAL_STATE.branchesAvailability,
		}
	}
	return {
		...state, selectedProduct: payload.productDetails, errorLoadingProductDetails: undefined, loadingProductDetails: false, branchesAvailability: INITIAL_STATE.branchesAvailability,
	}
})

// Loading product details
reducer
	.case(actions.loadProductDetails.started, (state, payload): StoreState => ({
		...state, selectedProduct: undefined, errorLoadingProductDetails: undefined, loadingProductDetails: true,
	}))
	.case(actions.loadProductDetails.done, (state, payload): StoreState => ({
		...state, selectedProduct: payload.result, loadingProductDetails: false,
	}))
	.case(actions.loadProductDetails.failed, (state, payload): StoreState => ({
		...state, errorLoadingProductDetails: payload.error, loadingProductDetails: false,
	}))
	// selected product prices
	.case(actions.loadProductPrices.done, (state, { result }): StoreState => {
		return ({
			...state,
			selectedProductPrices: result,
		})
	})
	// when account changed we should clear product prices as they will be based on previous account
	.case(accountSelected, (state): StoreState => {
		// if we have prices, clear them
		if (state.selectedProductPrices) {
			return ({
				...state,
				selectedProductPrices: undefined,
			})
		}
		return state
	})

// Loading product lists
reducer
	.case(actions.loadProductLists.started, (state, payload): StoreState => {
		if (state.selectedProduct && payload.productSku === state.selectedProduct.sku) {
			return state
		}
		return ({
			...state,
			selectedProductLists: INITIAL_STATE.selectedProductLists,
		})
	})
	.case(actions.loadProductLists.done, (state, { params, result }): StoreState => {
		if (!state.selectedProduct || params.productSku === state.selectedProduct.sku) {
			return ({
				...state,
				selectedProductLists: result,
			})
		}
		return state
	})
	.case(actions.loadProductLists.failed, (state, { params }): StoreState => {
		// failed getting the product lists, reset to initial state
		if (state.selectedProduct && params.productSku === state.selectedProduct.sku) {
			return ({
				...state,
				selectedProductLists: INITIAL_STATE.selectedProductLists,
			})
		}
		return state
	})

reducer
	.cases([actions.loadProductForBarcode.started, actions.searchProductForBarcodeAPI.started], (state, payload): StoreState => ({
		...state, productForBarcode: undefined, errorLoadingProductForBarcode: undefined, loadingProductForBarcode: true,
	}))
	.cases([actions.loadProductForBarcode.failed, actions.searchProductForBarcodeAPI.failed], (state, payload): StoreState => ({
		...state, errorLoadingProductForBarcode: payload.error, loadingProductForBarcode: false,
	}))
	// realm product successfully loaded
	.case(actions.loadProductForBarcode.done, (state, payload): StoreState => ({
		...state, productForBarcode: payload.result, loadingProductForBarcode: false,
	}))
	// api product successfully loaded
	.case(actions.searchProductForBarcodeAPI.done, (state, { result }): StoreState => {
		let productForBarcode: PlatformProduct | undefined = undefined
		if (result) {
			productForBarcode = getPlatformSupportImplementation().mapApiProductToPlatformProduct(result)
		}
		return ({
			...state,
			productForBarcode,
			loadingProductForBarcode: false,
		})
	})
	// barcode product prices
	.case(actions.loadProductPricesForBarcode.done, (state, { result }): StoreState => {
		return ({
			...state,
			productPricesForBarcode: result,
		})
	})
	// clearing barcode flags
	.case(actions.clearBarcodeDetails, (state): StoreState => ({
		...state,
		productForBarcode: undefined, errorLoadingProductForBarcode: undefined, loadingProductForBarcode: false, productPricesForBarcode: undefined, branchAvailabilityForBarcode: undefined,
	}))

// availability
reducer
	.cases([actions.getProductAvailability.started, actions.getProductAvailabilityForBarcode.started], (state): StoreState => ({
		...state, loadingProductAvailability: true,
	}))
	.case(actions.getProductAvailability.done, (state, { result }): StoreState => {
		// combine availability on product details (web only) to make adding to cart easier
		const selectedProduct = state.selectedProduct && getPlatformSupportImplementation().appendAvailabilityToProduct ? getPlatformSupportImplementation().appendAvailabilityToProduct!(state.selectedProduct, result) : state.selectedProduct
		return {
			...state, branchAvailability: result, loadingProductAvailability: false, selectedProduct,
		}
	})
	.case(actions.getProductAvailabilityForBarcode.done, (state, { result }): StoreState => {
		// combine availability on product details (web only) to make adding to cart easier
		const productForBarcode = state.productForBarcode && getPlatformSupportImplementation().appendAvailabilityToProduct ? getPlatformSupportImplementation().appendAvailabilityToProduct!(state.productForBarcode, result) : state.productForBarcode
		return {
			...state, branchAvailabilityForBarcode: result, loadingProductAvailability: false, productForBarcode,
		}
	})
	.cases([actions.getProductAvailability.failed, actions.getProductAvailabilityForBarcode.failed], (state): StoreState => ({
		...state, loadingProductAvailability: false,
	}))
	.case(actions.getBranchesAvailability.started, (state, payload): StoreState => ({
		...state, loadingBranchesAvailability: [...state.loadingBranchesAvailability, payload.regionId],
	}))
	.case(actions.getBranchesAvailability.done, (state, payload): StoreState => {
		const result = updateBranchesAvailability(payload.params.regionId, payload.result.products, state.branchesAvailability)
		if (result) {
			return ({
				...state,
				branchesAvailability: result,
				loadingBranchesAvailability: state.loadingBranchesAvailability.filter(regionId => regionId !== payload.params.regionId),
			})
		}
		return {
			...state,
			loadingBranchesAvailability: state.loadingBranchesAvailability.filter(regionId => regionId !== payload.params.regionId),
		}
	})
	.case(actions.getBranchesAvailability.failed, (state, payload): StoreState => ({
		...state,
		loadingBranchesAvailability: state.loadingBranchesAvailability.filter(regionId => regionId !== payload.params.regionId),
	}))

// related product prices
reducer
	.case(actions.loadRelatedProductPrices.started, (state): StoreState => {
		return {
			...state,
			loadingRelatedProductPrices: true,
			relatedProductPrices: [],
		}
	})
	.case(actions.loadRelatedProductPrices.done, (state, payload): StoreState => {
		return {
			...state,
			loadingRelatedProductPrices: false,
			relatedProductPrices: payload.result,
		}
	})
	.case(actions.loadRelatedProductPrices.failed, (state): StoreState => {
		return {
			...state,
			loadingRelatedProductPrices: false,
		}
	})

// related products
reducer
	.case(actions.loadRelatedProducts.started, (state): StoreState => {
		return {
			...state,
			loadingRelatedProducts: true,
			relatedProducts: [],
		}
	})
	.case(actions.loadRelatedProducts.done, (state, payload): StoreState => {
		return {
			...state,
			loadingRelatedProducts: false,
			relatedProducts: payload.result,
		}
	})
	.case(actions.loadRelatedProducts.failed, (state): StoreState => {
		return {
			...state,
			loadingRelatedProducts: false,
		}
	})