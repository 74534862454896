import { SagaIterator } from 'redux-saga'
import { takeEvery, select, call, takeLatest } from 'redux-saga/effects'
import { CreateOrder, ProductSku } from 'typescript-fetch-api'
import moment from 'moment'
import { Action } from 'redux'

import * as actions from '../../common/order/actions'
import * as cartActions from '../../common/cart/actions'
import { UserAccount } from '../../common/accounts/types'
import { orderItemsSelector, selectedAccountSelector } from '../../common/order/selectors'
import { getContentApi, getOrderApi } from '../../common/api'
import { Order, OrderItem } from '../../common/order/types'
import { callApi } from '../../common/api/functions'
import { createInstructionsForOrder } from '../../common/util/functions'

/**
 * Handles updating the product prices in the cart whenever a new account for the order is selected.
 */
function* updateProductPricesInCart(action: cartActions.AccountSelectedAction): SagaIterator {
	const orderItems: OrderItem[] = yield select(orderItemsSelector)
	if (orderItems.length > 0) {
		const products: ProductSku[] = orderItems.map(item => ({ sku: item.product.sku }))
		const account: UserAccount = action.payload
		yield call(fetchProductPricing, products, account, action)
	}
}

/**
 * Handles fetching pricing for product being added to the cart, if it does not already have that info in the payload
 */
function* handleProductAddedToCart(action: cartActions.AddProductToCartAction): SagaIterator {
	const account: UserAccount | undefined = yield select(selectedAccountSelector)
	// check if item being added has stock availability already
	if (!action.payload.product.recommendedRetailPrice && account) {
		const products: ProductSku[] = [{ sku: action.payload.product.sku }]
		yield call(fetchProductPricing, products, account, action)
	}
}

/**
 * Handles fetching pricing for product being updated in the cart, if it does not already have that info in the payload
 */
function* handleProductUpdatedInCart(action: cartActions.UpdateProductQuantityInCartAction): SagaIterator {
	const account: UserAccount | undefined = yield select(selectedAccountSelector)
	// check if item being added has stock availability already & item being added not removed
	if (!action.payload.product.recommendedRetailPrice && action.payload.quantity > 0 && account) {
		const products: ProductSku[] = [{ sku: action.payload.product.sku }]
		yield call(fetchProductPricing, products, account, action)
	}
}

// reusable method for fetching pricing for cart item(s)
function* fetchProductPricing(products: ProductSku[], account: UserAccount, action: Action<any>): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		cartActions.updateProductPricesInCart,
		() => getContentApi().getProductPrices({ productPriceRequestList: { products }, customerId: account.customerId })
	)
}

function* handleSubmitOrder(action: actions.SubmitOrderOnlineAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.submitOrderOnline,
		(payload: Order) => {
			const uuidv4 = require('uuid/v4')
			const orderId = uuidv4()
			// convert to api request format
			const request: CreateOrder = {
				id: orderId,
				customerId: payload.customerId!,
				clientName: payload.recipientName,
				branchId: Number.parseInt(payload.branchId, undefined),
				email: payload.email,
				mobileNumber: payload.mobileNumber,
				clientAddress: payload.address,
				clientCity: payload.city,
				clientSuburb: payload.suburb,
				clientState: payload.state,
				clientPostcode: payload.postCode,
				orderReference: payload.orderNumber,
				builderContact: payload.onSiteContactNumber,
				shippingInstructions: createInstructionsForOrder(payload.despatchMethod, payload.despatchSlot),
				notes: payload.notes,
				despatchSlot: payload.despatchSlot?.toString(),
				despatchMethod: payload.despatchMethod,
				dateRequired: moment(payload.despatchDate).format('YYYY-MM-DD'),
				deliveryLatitude: payload.latitude ? parseFloat(payload.latitude) : undefined,
				deliveryLongitude: payload.longitude ? parseFloat(payload.longitude) : undefined,
				orderItems: payload.orderItems.map((item) => {
					return {
						productId: item.product.sku,
						quantity: item.quantity,
						productDescription: item.product.longDescription || item.product.shortDescription,
						productImage: item.product.primaryImageTitle,
					}
				})
			}
			return getOrderApi().createOrder({ createOrder: request })
		}
	)
}

export default function* (): SagaIterator {
	yield takeEvery(cartActions.accountSelected, updateProductPricesInCart)
	yield takeEvery(cartActions.addProductToCart, handleProductAddedToCart)
	yield takeEvery(cartActions.updateProductQuantityInCart, handleProductUpdatedInCart)
	yield takeLatest(actions.submitOrderOnline.started, handleSubmitOrder)
}
