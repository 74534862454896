import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from './actions'
import * as authActions from '../auth/actions'
import { readyAction } from '../root/actions'
import { UserAccount } from './types'

export interface StoreState {
	readonly accounts?: Array<UserAccount>
	readonly errorGetAccounts?: Error
	readonly errorAddAccount?: Error
	readonly loadingMessageGetAccounts?: string
	readonly loadingMessageAddAccount?: string
	readonly successAddAccount: boolean

	readonly adminAccounts?: Array<UserAccount>
	readonly errorGetAdminAccounts?: Error
	readonly loadingMessageGetAdminAccounts?: string
	readonly errorAddOrEditAdminAccount?: Error
	readonly loadingMessageAddOrEditAdminAccount?: string
	readonly successAddOrEditAdminAccount: boolean

	readonly updatingAccountsEmailOptIn: boolean
	readonly updateAccountsEmailOptInSuccess: boolean
	readonly updateAccountsEmailOptInError?: Error

	readonly accountPrincipalEmail?: string
}

const INITIAL_STATE: StoreState = {
	accounts: undefined,
	errorGetAccounts: undefined,
	errorAddAccount: undefined,
	loadingMessageGetAccounts: undefined,
	loadingMessageAddAccount: undefined,
	successAddAccount: false,

	adminAccounts: undefined,
	errorGetAdminAccounts: undefined,
	loadingMessageGetAdminAccounts: undefined,
	errorAddOrEditAdminAccount: undefined,
	loadingMessageAddOrEditAdminAccount: undefined,
	successAddOrEditAdminAccount: false,

	updatingAccountsEmailOptIn: false,
	updateAccountsEmailOptInSuccess: false,
	updateAccountsEmailOptInError: undefined,

	accountPrincipalEmail: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	.case(actions.getAccounts.done, (state, { result }): StoreState => {
		// updates the admin accounts (if any)
		let adminAccounts = undefined
		if (result.accounts && state.adminAccounts) {
			adminAccounts = state.adminAccounts.map(adminAccount => {
				const matchingAccount = result.accounts!.find(account => adminAccount.id === account.id)
				// we found an updated account that matches, use that. if not, retain account details
				return matchingAccount || adminAccount
			})
		}
		return {
			...state,
			accounts: result.accounts,
			adminAccounts,
			errorGetAccounts: undefined,
			loadingMessageGetAccounts: undefined
		}
	})
	.case(actions.getAccounts.failed, (state, { error }): StoreState => {
		return {
			...state,
			errorGetAccounts: error,
			loadingMessageGetAccounts: undefined
		}
	})
	.case(actions.getAccounts.started, (state): StoreState => {
		return {
			...state,
			errorGetAccounts: undefined,
			loadingMessageGetAccounts: 'Loading accounts…'
		}
	})
	.case(actions.addAccount.done, (state, { result }): StoreState => {
		return {
			...state,
			accounts: result.accounts,
			errorAddAccount: undefined,
			loadingMessageAddAccount: undefined,
			successAddAccount: true,
		}
	})
	.case(actions.addAccount.failed, (state, { error }): StoreState => {
		return {
			...state,
			errorAddAccount: error,
			loadingMessageAddAccount: undefined,
			successAddAccount: false,
		}
	})
	.case(actions.addAccount.started, (state): StoreState => {
		return {
			...state,
			errorAddAccount: undefined,
			loadingMessageAddAccount: 'Adding account…',
			successAddAccount: false,
		}
	})
	.case(actions.addAccountSuccess, (state): StoreState => {
		return {
			...state,
			errorGetAccounts: undefined,
			errorAddAccount: undefined,
			loadingMessageGetAccounts: undefined,
			loadingMessageAddAccount: undefined,
			successAddAccount: false,
		}
	})
	.cases([readyAction, authActions.refreshAuthTokenFailed], (state): StoreState => {
		return {
			...state,
			errorGetAccounts: undefined,
			errorAddAccount: undefined,
			loadingMessageGetAccounts: undefined,
			loadingMessageAddAccount: undefined,
			successAddAccount: false,

			errorGetAdminAccounts: undefined,
			loadingMessageGetAdminAccounts: undefined,
			errorAddOrEditAdminAccount: undefined,
			loadingMessageAddOrEditAdminAccount: undefined,
			successAddOrEditAdminAccount: false,

			updatingAccountsEmailOptIn: false,
			updateAccountsEmailOptInSuccess: false,
			updateAccountsEmailOptInError: undefined,
		}
	})

	// EMAIL OPT-IN
	.case(actions.updateAccountsEmailOptIn.started, (state): StoreState => {
		return {
			...state,
			updatingAccountsEmailOptIn: true,
			updateAccountsEmailOptInSuccess: false,
			updateAccountsEmailOptInError: undefined,
		}
	})
	.case(actions.updateAccountsEmailOptIn.done, (state): StoreState => {
		return {
			...state,
			updatingAccountsEmailOptIn: false,
			updateAccountsEmailOptInSuccess: true,
		}
	})
	.case(actions.updateAccountsEmailOptIn.failed, (state, { error }): StoreState => {
		return {
			...state,
			updatingAccountsEmailOptIn: false,
			updateAccountsEmailOptInError: error,
		}
	})
	.case(actions.clearAccountsEmailOptInState, (state): StoreState => {
		return {
			...state,
			updatingAccountsEmailOptIn: false,
			updateAccountsEmailOptInSuccess: false,
			updateAccountsEmailOptInError: undefined,
		}
	})

	// ADMIN ACCOUNTS
	// GET
	.case(actions.getAdminAccounts.done, (state, { result }): StoreState => {
		return {
			...state,
			adminAccounts: result.accounts,
			errorGetAdminAccounts: undefined,
			loadingMessageGetAdminAccounts: undefined
		}
	})
	.case(actions.getAdminAccounts.failed, (state, { error }): StoreState => {
		return {
			...state,
			adminAccounts: undefined,
			errorGetAdminAccounts: error,
			loadingMessageGetAdminAccounts: undefined
		}
	})
	.case(actions.getAdminAccounts.started, (state): StoreState => {
		return {
			...state,
			adminAccounts: undefined,
			errorGetAdminAccounts: undefined,
			loadingMessageGetAdminAccounts: 'Loading accounts…'
		}
	})
	// EDIT/CREATE/DELETE
	.cases([actions.editAccount.done, actions.createAccount.done], (state, { result }): StoreState => {
		// update edited account in our array of admin accounts
		const updatedAccount = result
		// clone array
		let updatedAccounts = state.adminAccounts ? [...state.adminAccounts] : []
		let accountFound = false
		updatedAccounts = updatedAccounts.map(account => {
			if (account.id === updatedAccount.id) {
				accountFound = true
				return updatedAccount
			}
			return account
		})
		// if account not found, add to end of array
		if (!accountFound) {
			updatedAccounts.push(updatedAccount)
		}
		return {
			...state,
			adminAccounts: updatedAccounts,
			errorAddOrEditAdminAccount: undefined,
			loadingMessageAddOrEditAdminAccount: undefined,
			successAddOrEditAdminAccount: true,
		}
	})
	.case(actions.deleteAccount.done, (state, { params }): StoreState => {
		const deletedAccountId = params.id
		// clone array
		let updatedAccounts = state.adminAccounts ? [...state.adminAccounts] : []
		// filter out the deleted account
		updatedAccounts = updatedAccounts.filter(account => account.id !== deletedAccountId)
		return {
			...state,
			adminAccounts: updatedAccounts,
			errorAddOrEditAdminAccount: undefined,
			loadingMessageAddOrEditAdminAccount: undefined,
			successAddOrEditAdminAccount: true,
		}
	})
	.cases([actions.editAccount.failed, actions.createAccount.failed, actions.deleteAccount.failed], (state, { error }): StoreState => {
		return {
			...state,
			errorAddOrEditAdminAccount: error,
			loadingMessageAddOrEditAdminAccount: undefined,
			successAddOrEditAdminAccount: false,
		}
	})
	.cases([actions.editAccount.started, actions.createAccount.started, actions.deleteAccount.started, actions.editLoginId], (state): StoreState => {
		return {
			...state,
			errorAddOrEditAdminAccount: undefined,
			loadingMessageAddOrEditAdminAccount: 'Updating account…',
			successAddOrEditAdminAccount: false,
		}
	})
	.case(actions.clearEditAccountState, (state): StoreState => {
		// clear any messages regarding editing a previous account
		return {
			...state,
			errorAddOrEditAdminAccount: undefined,
			loadingMessageAddOrEditAdminAccount: undefined,
			successAddOrEditAdminAccount: false,
		}
	})

	// GET ACCOUNT PRINCIPAL EMAIL
	.case(actions.getAccountPrincipalEmail.started, (state): StoreState => {
		return {
			...state,
			accountPrincipalEmail: undefined,
		}
	})
	.case(actions.getAccountPrincipalEmail.done, (state, { result }): StoreState => {
		return {
			...state,
			accountPrincipalEmail: result,
		}
	})

	/* The user has been logged out remove accounts from the state. */
	.case(authActions.loggedOut, (): StoreState => {
		return INITIAL_STATE
	})
