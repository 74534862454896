import '../styles/Root.scss'

import React from 'react'
import { Route } from 'react-router-dom'
import objectFillImages from 'object-fit-images'
import { BrowserRouter, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import * as Container from '../containers/Root'
import ConsumerHeader from './ConsumerHeader'
import ConsumerFooter from './ConsumerFooter'
import LazySwitch from '../../navigation/components/LazySwitch'
import Routes from '../../../utils/routeProvider'
import ScrollToTop from '../../navigation/components/ScrollToTop'
import NavigateSetter from '../../navigation/components/NavigateSetter'
import { RootStoreState } from '../../../common/root'
import { Paths } from '../../navigation/types'

function PublicRoute({ children }: { children: React.ReactElement }) {
	const isLoggedIn = useSelector<RootStoreState, boolean>(state => !!state.auth.authToken)
	return !isLoggedIn ? children : <Navigate to={Paths.HOME} />
}

function App({ children }: { children: React.ReactElement[] }) {
	const isLoggedIn = useSelector<RootStoreState, boolean>(state => !!state.auth.authToken)
	return (
		<div className={`app${isLoggedIn ? ' app-auth' : ''}`}>
			{children}
		</div>
	)
}

/**
 * Defines the routes for the app and listens to any router location changes.
 */
export default class ConsumerRoot extends React.PureComponent<Container.Props> {

	componentDidMount() {
		// Adds the object-fit polyfill for images and fixes the problem for IE.
		// We must update the css to add object-fit normally for browsers that support it and font-family for those that don't.
		// https://github.com/fregante/object-fit-images#usage
		objectFillImages(null)
	}

	render() {
		const { isLoggedIn } = this.props

		return (
			<BrowserRouter>
				<ScrollToTop />
				<NavigateSetter />
				<App>
					<ConsumerHeader isLoggedIn={isLoggedIn} />

					<div className="app-main">
						<LazySwitch>
							{/* Private Routes */}
							<Route
								path={Paths.HOME}
								element={
									<PublicRoute>
										<Routes.ConsumerHome />
									</PublicRoute>
								}
							/>

							{/* Public Routes */}
							<Route
								path={Paths.REGISTER_CONFIRM_EMAIL}
								element={
									<PublicRoute>
										<Routes.EmailVerification />
									</PublicRoute>
								}
							/>
							<Route
								path={Paths.REGISTER_CONFIRM_PHONE}
								element={
									<PublicRoute>
										<Routes.MobileVerification />
									</PublicRoute>
								}
							/>
							<Route
								path={Paths.APPROVE_ACCOUNT}
								element={
									<PublicRoute>
										<Routes.ApproveAccount />
									</PublicRoute>
								}
							/>
							<Route
								path={Paths.RESET_PASSWORD}
								element={
									<PublicRoute>
										<Routes.ForgotPassword />
									</PublicRoute>
								}
							/>
							{/* needed different url for third case of resetting password so we can display unique title message */}
							<Route
								path={Paths.VERIFY_ACCOUNT}
								element={
									<PublicRoute>
										<Routes.ForgotPassword />
									</PublicRoute>
								}
							/>

							{/* General Routes */}
							<Route
								path={Paths.TERMS_AND_CONDITIONS}
								element={
									<Routes.GeneralNavigator />
								}
							/>
							<Route
								path={Paths.PRIVACY_POLICY}
								element={
									<Routes.GeneralNavigator />
								}
							/>
							<Route
								path={Paths.SUPPORT}
								element={
									<Routes.SupportNavigator />
								}
							/>
							<Route
								path={Paths.TRADE}
								element={
									<Routes.TradeNavigator />
								}
							/>
							<Route
								path={Paths.PSCOOP}
								element={
									<Routes.PSCoopNavigator />
								}
							/>

							{/* Other Routes */}
							{/* This is a generic Route since it's being used by both Change password (private) and Reset password (public) */}
							<Route
								path={Paths.CONFIRM_RESET_PASSWORD}
								element={
									<Routes.ChangePassword />
								}
							/>
							<Route
								path={Paths.REGIONS}
								element={
									<Routes.Regions />
								}
							/>
							<Route
								path={Paths.CONTACT}
								element={
									<Routes.ContactUs />
								}
							/>

							<Route path="*" element={<Routes.PageUnknown />} />
						</LazySwitch>
					</div>

					<ConsumerFooter />
				</App>
			</BrowserRouter>
		)
	}
}
