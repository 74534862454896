import { ClickAndCollectTimeslot, ClosureType, CreateOrderDespatchMethodEnum } from 'typescript-fetch-api'

export interface DeliverySlot {
	value: string
}

export const DELIVERY_TIME_RANGES: Array<DeliverySlot> = [
	{ value: 'AM' },
	{ value: 'PM' },
]

export enum PIN_TYPE {
	SET, RESET, CHECKOUT, DELETE_SELF
}

export interface DeliveryDetails {
	recipientName?: string
	onSiteContactNumber?: string
}

export interface DespatchDetails {
	despatchDate: string
	despatchSlot?: ClosureType
	despatchClickAndCollectTimeslot?: ClickAndCollectTimeslot
	despatchMethod: CreateOrderDespatchMethodEnum
	address: string
	suburb: string
	city: string
	state: string
	postCode: string
	email: string
	mobileNumber: string
	latitude: string
	longitude: string
}

export enum ProductAvailability {
	IN_STOCK = 'In-Stock',
	LIMITED_STOCK = 'Limited Stock',
	AVAILABLE_TO_ORDER = 'Not In-Stock',
}

export enum MaxOrderInputLength {
	ORDER_NUMBER = 16,
	NOTES = 90,
	RECIPIENT_NAME = 17,
	ON_SITE_CONTACT_NUMBER = 12,
	ADDRESS = 30,
	UNIT = 4,
}

export enum OrderInputs {
	UNIT = 'unit',
	ADDRESS = 'address',
	SUBURB = 'suburb',
	CITY = 'city',
	STATE = 'state',
	POSTCODE = 'postCode',
	LATITUDE = 'latitude',
	LONGITUDE = 'longitude',
}

export enum PriceType {
	RRP = 'recommendedRetailPrice',
	TRD = 'tradePrice',
	CST = 'accountPrice',
}