export const PW_DETAILS = {
	contact: '02 8118 6000',
	url: 'tel:+02 8118 6000',
}

export const PW_OFFICES: { name: string, contact: string, url: string, address: string }[] = [
	{
		name: 'Clyde Street Support Office',
		contact: '02 8118 6000',
		url: 'tel:02 8118 6000',
		address: '1 Clyde Street, Silverwater NSW 2128',
	},
]

export const PW_WEBSITES: { name: string, description: string, url: string }[] = [
]

export const PW_SOCIALS: { icon: string, url: string }[] = [
	{ icon: 'icon-facebook', url: 'https://www.facebook.com/plumberscooperative' },
	{ icon: 'icon-instagram', url: 'https://www.instagram.com/plumbers.coop/' },
]

export const NZPM_DETAILS = {
	name: 'PSCL',
	contact: '02 8118 6000',
	url: 'tel:02 8118 6000',
	address: '1 Clyde Street, Silverwater NSW 2128',
	postalAddress: 'PO Box 7116 Silverwater BC NSW 1811',
	web: 'www.pscoop.com.au',
}