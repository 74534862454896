import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { CustomerOrdersResponse, CustomerOrderResponse, OrderStatus, OrderMetaData, OrderType, DownloadOrderFileType } from 'typescript-fetch-api'
import { Filter } from '../util/types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('CustomerOrders')

// nav
export interface ViewCustomerOrderDetailsPayload {
	orderId: string
	orderSuffixId: number
	orderReference?: string
}
export type ViewCustomerOrderDetailsAction = Action<ViewCustomerOrderDetailsPayload>
export const viewCustomerOrderDetailAction = actionCreator<ViewCustomerOrderDetailsPayload>('VIEW_CUSTOMER_ORDER_DETAILS')

// get orders
export interface GetCustomerOrdersPayload {
	customerId: number
	type: string // orders or quotes or both
	status?: OrderStatus
	orderedBy?: string
	branchId?: number
	reference?: string
	pwgoOnly?: boolean
	page?: number
	pageSize?: number
	appendToList?: boolean
}
export type GetCustomerOrdersAction = Action<GetCustomerOrdersPayload>
export type GetCustomerOrdersSuccessAction = Action<Success<GetCustomerOrdersPayload, CustomerOrdersResponse>>
export const fetchCustomerOrders = actionCreator.async<GetCustomerOrdersPayload, CustomerOrdersResponse, Error>('FETCH_CUSTOMER_ORDERS')

// get order
export interface GetCustomerOrderPayload {
	customerId: number
	orderId: string
	orderSuffixId: number
	orderType: OrderType
}
export type GetCustomerOrderAction = Action<GetCustomerOrderPayload>
export type GetCustomerOrderSuccessAction = Action<Success<GetCustomerOrderPayload, CustomerOrderResponse>>
export const fetchCustomerOrder = actionCreator.async<GetCustomerOrderPayload, CustomerOrderResponse, Error>('FETCH_CUSTOMER_ORDER_DETAILS')

// download quote
export interface DownloadOrderPayload {
	customerId: number
	orderId: string
	orderSuffixId: number
	type: DownloadOrderFileType
	orderType: OrderType
	view?: boolean // opens the downloaded file
}
export type DownloadOrderRequestAction = Action<DownloadOrderPayload>
export const downloadCustomerOrder = actionCreator.async<DownloadOrderPayload, CustomerOrdersResponse, Error>('DOWNLOAD_CUSTOMER_ORDER')

export interface DownloadCustomerOrderAsZipPayload {
	customerId: number
	orderType: OrderType
	type?: DownloadOrderFileType
	orders?: OrderMetaData[]
}
export type DownloadCustomerOrderAsZipAction = Action<DownloadCustomerOrderAsZipPayload>
export const downloadCustomerOrderAsZip = actionCreator.async<DownloadCustomerOrderAsZipPayload, Blob, Error>('DOWNLOAD_CUSTOMER_ORDERS_AS_ZIP')

// set filters
export interface SetCustomerOrdersFilters {
	filters: Filter[]
}
export const setCustomerOrdersFiltersAction = actionCreator<SetCustomerOrdersFilters>('SET_CUSTOMER_ORDERS_FILTERS')

// set from date
export interface SetCustomerOrdersFromDate {
	date: string
}
export const setCustomerOrdersFromDateAction = actionCreator<SetCustomerOrdersFromDate>('SET_CUSTOMER_ORDERS_FROM_DATE')

// set to date
export interface SetCustomerOrdersToDate {
	date: string
}
export const setCustomerOrdersToDateAction = actionCreator<SetCustomerOrdersToDate>('SET_CUSTOMER_ORDERS_TO_DATE')

export const navigateToSignIn = actionCreator('NAVIGATE_TO_SIGN_IN')