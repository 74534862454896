import { OwnPropInfo } from '../../common/register/containers/Register'
import { Environment } from '../../common/api/types'
import { getEnvironment } from '../../modules/platform/functions'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RegisterNavigationProps { }

export function extractRegisterNavProps(props: RegisterNavigationProps): OwnPropInfo {
	return {
		goCheckoutAfter: false
	}
}

export const MobilePrefixOptions: { label: string, value: string }[] = (getEnvironment() === Environment.DEV || getEnvironment() === Environment.LOCAL) ?
	[
		{ label: '020', value: '020' },
		{ label: '021', value: '021' },
		{ label: '022', value: '022' },
		{ label: '027', value: '027' },
		{ label: '029', value: '029' },
	] :
	[
		{ label: '04', value: '04' },
	]

export type ApproveAccountParams = {
	id: string
}