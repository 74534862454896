import { all, takeEvery, call, put, select } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { ApproveAccountRequest, CategoryResponse, GetCategoryRequest, RegisterDevice, RegisterDeviceOstypeEnum } from 'typescript-fetch-api'
import * as firebase from 'firebase/app'
import 'firebase/messaging'
import moment from 'moment'

import analyticsSaga from '../analytics/sagas'
import authSaga from '../auth/sagas'
import customerordersSaga from '../customerorders/sagas'
import financialsSaga from '../financials/sagas'
import homeSaga from '../home/sagas'
import regionsSaga from '../showrooms/sagas'
import productSaga from '../product/sagas'
import orderSaga from '../order/sagas'
import mylistsSaga from '../mylists/sagas'
import remoteConfigSaga from '../remoteConfig/sagas'
import profileSaga from '../profile/sagas'
import searchSaga from '../search/sagas'
import accountsSaga from '../accounts/sagas'
import verifySaga from '../mobileVerification/sagas'
import ordersSaga from '../myorders/sagas'
import categoriesSaga from '../categories/sagas'
import cartSaga from '../cart/sagas'
import registerSaga from '../register/sagas'
import forgotPasswordSaga from '../forgotpassword/sagas'
import contactUsSaga from '../contactus/sagas'
import productsSaga from '../products/sagas'
import versionUpdatesSaga from '../versionUpdates/sagas'
import generalSaga from '../general/sagas'

import { callApi } from '../../common/api/functions'
import { getContentApi, getRegisterApi, getUserApi } from '../../common/api'
import { store } from '../../common/root'
import * as actions from './actions'
import * as authActions from '../../common/auth/actions'
import * as accountsActions from '../../common/accounts/actions'
import { readyAction } from '../../common/root/actions'
import { authTokenSelector } from '../../common/auth/selectors'
import { detectPlatform } from './functions'
import { INITIAL_PAGE } from './content'
import { formatCategoryResponse } from '../categories/functions'
import * as NavigationManager from '../navigation/NavigationManager'

/**
 * NOTIFICATION
 */

function* handleSetupNotificationOnReady(): SagaIterator {
	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	if (hasAuthToken) {
		yield call(setupNotification)
	}
}

function* setupNotification(): SagaIterator {
	if (!firebase.messaging.isSupported()) {
		const error = Error('Firebase messaging is not supported')
		yield put(actions.registerNotificationToken.failed({ params: '', error }))
		return
	}

	// retrieve an instance of Firebase Messaging
	const messaging = firebase.messaging()

	messaging.getToken()
		.then((token: string) => {
			store.dispatch(actions.registerNotificationToken.started(token))
		})
		.catch((error: Error) => {
			store.dispatch(actions.registerNotificationToken.failed({ params: '', error }))
		})

	messaging.onMessage(notification => {
		// if the notification data has a statusId it must be an account updated push message
		if (notification.data.statusId !== undefined) {
			let params: accountsActions.GetAccountsPayload = {}

			// we grab the customer id from the notification data and use this as reference so we can show the hnz new feature bottomsheet on approval of a new hnz account
			const customerId: string | undefined = notification.data.customerId
			if (customerId) {
				// we pass through the account id
				params = { accountId: parseInt(customerId, 10) }
			}

			// load accounts
			store.dispatch(accountsActions.getAccounts.started(params))
		}
	})
}

function* handleRegisterNotificationToken(action: actions.RegisterNotificationTokenAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.registerNotificationToken, (payload: string) => {
		const { browser, os } = detectPlatform()
		const osType = os.name === 'unknown'
			? RegisterDeviceOstypeEnum.OTHER
			: os.name as RegisterDeviceOstypeEnum

		const requestPayload: RegisterDevice = {
			make: browser.name,
			model: browser.version,
			ostype: osType,
			pushToken: payload,
		}

		return getUserApi().registerDevice({ registerDevice: requestPayload })
	})
}

function* deregisterNotification(): SagaIterator {
	if (!firebase.messaging.isSupported()) {
		const error = Error('Firebase messaging is not supported')
		yield put(actions.deregisterNotificationToken.failed({ params: '', error }))
		return
	}

	// retrieve an instance of Firebase Messaging
	const messaging = firebase.messaging()

	messaging.getToken()
		.then((token: string) => {
			store.dispatch(actions.deregisterNotificationToken.started(token))
		})
		.catch((error: Error) => {
			store.dispatch(actions.deregisterNotificationToken.failed({ params: '', error }))
		})
}

function* handleDeregisterNotificationToken(action: actions.DeregisterNotificationTokenAction): SagaIterator {
	const token: string = action.payload

	// first check user has an auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		const error = Error('You are not logged in')
		yield put(actions.deregisterNotificationToken.failed({ params: token, error }))
		return
	}

	// @ts-ignore API
	yield call(callApi, action, actions.deregisterNotificationToken, (payload: string) => {
		return getUserApi().deregisterDevice({ id: payload })
	})
}

/**
 * OTHER CONFIGS
 */

function* handleUpdateLastDisplayedFeatureDialog(): SagaIterator {
	// @ts-ignore API
	yield call(callApi, {}, actions.updateLastDisplayedFeatureDialog, (payload: void, options: RequestInit) => {
		// get the current date
		const currentDate = moment().format('YYYY-MM-DD')
		return getUserApi().updateFeatureDialog({ featureDialogUpdate: { lastDisplayedDate: currentDate } })
			.then(() => currentDate)
	})
}

function handleNavigateToTab(action: actions.NavigateToTabAction) {
	const { tab, userId } = action.payload
	NavigationManager.navigateToTab(tab, userId)
}

function* loadSubcategoriesForMenu(action: actions.LoadSubcategoriesForMenuAction): SagaIterator {
	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	// Since we only really want to load the categories, pass through the minimum amount for the page size to save the request
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.loadSubcategoriesForMenu,
		(payload: actions.LoadSubcategoriesPayload) => {
			const requestParameters: GetCategoryRequest = {
				id: payload.categoryId,
				page: INITIAL_PAGE,
				pageSize: 1,
			}
			const request: Promise<CategoryResponse> = hasAuthToken ? getContentApi().getCategory(requestParameters) : getContentApi().getCategoryPublic(requestParameters)
			return request.then(response => (
				response.categories ? formatCategoryResponse(response.categories) : undefined
			))
		})
}

function* handleApproveAccount(action: actions.ApproveAccountAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.approveAccount,
		(payload: ApproveAccountRequest, options: RequestInit) => getRegisterApi().approveAccount(payload, options)
	)
}

export default function* platformSaga() {
	// notification
	yield takeEvery(readyAction, handleSetupNotificationOnReady)
	yield takeEvery(authActions.login.done, setupNotification)
	yield takeEvery(authActions.preloggedOut, deregisterNotification)
	yield takeEvery(actions.registerNotificationToken.started, handleRegisterNotificationToken)
	yield takeEvery(actions.deregisterNotificationToken.started, handleDeregisterNotificationToken)

	// other configs
	yield takeEvery(actions.updateLastDisplayedFeatureDialog.started, handleUpdateLastDisplayedFeatureDialog)
	yield takeEvery(actions.navigateToTab, handleNavigateToTab)
	yield takeEvery(actions.loadSubcategoriesForMenu.started, loadSubcategoriesForMenu)
	yield takeEvery(actions.approveAccount.started, handleApproveAccount)

	yield all([
		analyticsSaga(),
		authSaga(),
		customerordersSaga(),
		financialsSaga(),
		homeSaga(),
		regionsSaga(),
		productSaga(),
		orderSaga(),
		mylistsSaga(),
		remoteConfigSaga(),
		profileSaga(),
		searchSaga(),
		accountsSaga(),
		verifySaga(),
		ordersSaga(),
		categoriesSaga(),
		cartSaga(),
		registerSaga(),
		forgotPasswordSaga(),
		contactUsSaga(),
		productsSaga(),
		versionUpdatesSaga(),
		generalSaga(),
	])
}
