import '../styles/ConsumerHeader.scss'
import '../styles/Header.scss'

// import { Paper } from '@mui/material'
import { useDispatch } from 'react-redux'

import * as images from '../../../utils/imagesProvider'
import { navigateToTab } from '../actions'
// import { navigateToContactUs } from '../../../common/contactus/actions'
// import { navigateToSignIn } from '../../../common/profile/actions'
// import { logoutRequest } from '../../../common/auth/actions'

type Props = {
	isLoggedIn: boolean
}

const ConsumerHeader = (props: Props): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	return (
		<div className="consumer-header header__container">
			<div className="header__content">
				{/* <div className="header__content--row">
					<ul className="header-nav-top header-nav-top--bold">
						{!props.isLoggedIn ? (
							<li onClick={() => { dispatch(navigateToSignIn()) }}>
								<span className="header-nav-top--regular">Sign in / Register </span>
								<span className="header-nav-top--arrow">▶</span>
							</li>
						) : (
							<li onClick={() => { dispatch(logoutRequest({})) }}>
								<span className="header-nav-top--regular">Sign out </span>
								<span className="header-nav-top--arrow">▶</span>
							</li>
						)}
					</ul>

					<ul className="header-nav-top">
						<li>Find us</li>
						<li>About us</li>
						<li>Work with us</li>
						<li onClick={() => { dispatch(navigateToContactUs()) }}>Contact us</li>
					</ul>
				</div> */}

				<div className="header__content--row">
					<div className="logo-container">
						<img
							src={images.PSCLogo}
							className="logo"
							alt="Plumbers’ Co-op"
							onClick={() => {
								dispatch(navigateToTab({ tab: 'home' }))
							}}
						/>
					</div>

					{/* <div className="header-nav-bottom">
						<Paper elevation={2} className="consumer-header__tile">
							<span className="icon-mui_search consumer-header__icon--tile" />
							<span className="consumer-header__text--tile">Search</span>
						</Paper>

						<Paper elevation={2} className="consumer-header__tile">
							<span className="icon-shopping-cart consumer-header__icon--tile" />
							<span className="consumer-header__text--tile">Cart</span>
						</Paper>

						<Paper elevation={2} className="consumer-header__tile">
							<img
								src={images.CoopNowLogo}
								className="logo"
								alt="Co-op Now"
							/>
						</Paper>
					</div> */}
				</div>
			</div>
		</div>
	)
}

export default ConsumerHeader