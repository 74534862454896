import { SagaIterator } from 'redux-saga'
import { takeEvery, select, call, put, takeLatest } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'
import { Product as ApiProduct, ProductPricesResponse, ProductList, ProductSku, SearchProductsRequest } from 'typescript-fetch-api'

import * as actions from './actions'
import * as mylistsActions from '../mylists/actions'
import { UserAccount } from '../accounts/types'
import { getPriceParams } from '../auth/functions'
import { getContentApi, getListApi } from '../api'
import { callApi } from '../api/functions'
import { selectedAccountSelector } from '../order/selectors'
import { productSelector } from './selectors'
import { PlatformProduct } from '../../modules/platform'

function searchProducts(query: string, selectedAccount: UserAccount | undefined): Promise<ApiProduct> {
	const { customerId, includePrices } = getPriceParams(selectedAccount)
	const requestParams: SearchProductsRequest = {
		query,
		page: 0,
		customerId,
		includePrices,
	}
	return getContentApi().searchProducts(requestParams)
		.then(response => {
			if (response.count && response.products) {
				// we found a product, return that
				return response.products[0]
			} else {
				// no product found, thrown error instead so the user can re-scan
				throw new Error('Product not found')
			}
		})
}

// TODO The `searchProducts` endpoint is being reused for Mobile to search product by barcode (the query string is the barcode).
// This is because mobile does not currently use this endpoint (could make a separate one later to simplify it and the paged response).
function* searchProductAPI(action: Action<string>): SagaIterator {
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const barcode = action.payload
	try {
		const searchProductsResponse = yield call(searchProducts, barcode, selectedAccount)
		yield put(actions.searchProductForBarcodeAPI.done({
			params: barcode,
			result: searchProductsResponse,
		}))
	} catch (error) {
		yield put(actions.searchProductForBarcodeAPI.failed({
			params: barcode,
			error: error as Error,
		}))
	}
}

function* loadProductPrices(action: Action<string>): SagaIterator {
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const customerId = selectedAccount && selectedAccount.customerId
	// @ts-ignore API
	yield call(callApi, action, actions.loadProductPrices, (sku: string) => {
		const products: ProductSku[] = [{ sku }]
		return getContentApi().getProductPrices({ productPriceRequestList: { products }, customerId })
			.then((result: ProductPricesResponse) => {
				if (result && result.prices) {
					return result.prices.find(item => item.sku === sku)
				}
				return undefined
			})
	})
}

function* loadProductPricesForBarcode(action: Action<string>): SagaIterator {
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const customerId = selectedAccount && selectedAccount.customerId
	// @ts-ignore API
	yield call(callApi, action, actions.loadProductPricesForBarcode, (sku: string) => {
		const products: ProductSku[] = [{ sku }]
		return getContentApi().getProductPrices({ productPriceRequestList: { products }, customerId })
			.then((result: ProductPricesResponse) => {
				if (result && result.prices) {
					return result.prices.find(item => item.sku === sku)
				}
				return undefined
			})
	})
}

function* loadProductLists(action: actions.LoadProductListsAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.loadProductLists, (payload: actions.LoadProductListsPayload) => {
		return getListApi().getListsForProduct({ sku: payload.productSku, customerId: payload.customerId })
			.then(result => {
				if (result.lists && result.lists.length > 0) {
					// merge all lists from the response
					return result.lists.reduce<ProductList[]>((data, parentList) => (
						parentList.lists ? [...data, ...parentList.lists] : data
					), [])
				}
				return []
			})
	})
}

/**
 * Handles reloading the current product's lists after successfully adding/removing it from a list
 * @param action the success action
 */
function* reloadProductListsOnUpdate(action: mylistsActions.AddProductToListSuccessAction | mylistsActions.RemoveProductFromListSuccessAction): SagaIterator {
	if (action.payload.params.screen === 'Product') {
		yield put(actions.loadProductLists.started({ productSku: action.payload.params.sku, customerId: action.payload.params.customerId }))
	}
}

/**
 * Handles reloading a product's lists on successful create/edit (only if the product is also included in the list items)
 * @param action the success action
 */
function* reloadProductListsOnCreateOrEdit(action: mylistsActions.CreateOrEditListActionSuccess): SagaIterator {
	const { params } = action.payload
	if (params.screen === 'Product') {
		// check if the user is viewing a product
		const selectedProduct: PlatformProduct | undefined = yield select(productSelector)
		if (selectedProduct) {
			// check if the product being viewed is included in the list items
			const isProductInList = params.list.productListItems ? params.list.productListItems.some(item => item.sku === selectedProduct.sku) : false
			if (isProductInList) {
				// reload the product lists so we display updated items on the UI
				yield put(actions.loadProductLists.started({ productSku: selectedProduct.sku, customerId: params.customerId }))
			}
		}
	}
}

function* loadRelatedProductPrices(action: actions.LoadRelatedProductPricesAction): SagaIterator {
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const customerId = selectedAccount && selectedAccount.customerId
	// @ts-ignore API
	yield call(callApi, action, actions.loadRelatedProductPrices, (payload: ProductSku[]) => {
		return getContentApi().getProductPrices({ productPriceRequestList: { products: payload }, customerId })
			.then((result: ProductPricesResponse) => result.prices || [])
	})
}

function* loadRelatedProducts(action: actions.LoadRelatedProductsAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.loadRelatedProducts, (payload: actions.LoadRelatedProductsPayload) => {
		return getContentApi().getRelatedProducts({ id: payload.productSku })
			.then(result => {
				if (result.products && result.products.length > 0) {
					return result.products
				}
				return []
			})
	})

}

export default function* (): SagaIterator {
	yield takeEvery(actions.searchProductForBarcodeAPI.started, searchProductAPI)
	yield takeEvery(actions.loadProductPrices.started, loadProductPrices)
	yield takeEvery(actions.loadRelatedProducts.started, loadRelatedProducts)
	yield takeEvery(actions.loadProductPricesForBarcode.started, loadProductPricesForBarcode)
	yield takeEvery(actions.loadProductLists.started, loadProductLists)
	yield takeLatest(actions.loadRelatedProductPrices.started, loadRelatedProductPrices)
	yield takeEvery([mylistsActions.addProductToList.done, mylistsActions.removeProductFromList.done], reloadProductListsOnUpdate)
	yield takeEvery(mylistsActions.createOrEditList.done, reloadProductListsOnCreateOrEdit)
}
