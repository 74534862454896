import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { ProfileResponse, AccountsEmailOptIn, PasswordUpdate, NewAccountRegistrationAccountRegistrationTypeEnum, GetAccountPrincipalEmailRequest } from 'typescript-fetch-api'

import { OrderId } from '../order/types'
import { UserAccount, Role, AccountStatus } from './types'

const actionCreator = actionCreatorFactory('Accounts')

export interface GetAccountsPayload {
	goCheckoutAfter?: boolean
	fromLogin?: boolean
	failedOrderId?: OrderId
	accountId?: number
}
export type GetAccountsRequestAction = Action<GetAccountsPayload>
export type GetAccountsRequestSuccessAction = Action<Success<GetAccountsPayload, ProfileResponse>>
export const getAccounts = actionCreator.async<GetAccountsPayload, ProfileResponse, Error>('GET_ACCOUNTS')

export interface GetAdminAccountsPayload {
	accountId: number
}
export type GetAdminAccountsRequestAction = Action<GetAdminAccountsPayload>
export type GetAdminAccountsRequestSuccessAction = Action<Success<GetAdminAccountsPayload, ProfileResponse>>
export const getAdminAccounts = actionCreator.async<GetAdminAccountsPayload, ProfileResponse, Error>('GET_ADMIN_ACCOUNTS')

export interface AddAccountRequestPayload {
	customerId: number
	nickname: string
	branchId: number
	accountRegistrationType: NewAccountRegistrationAccountRegistrationTypeEnum
}
export type AddAccountRequestAction = Action<AddAccountRequestPayload>
export type AddAccountRequestSuccessAction = Action<Success<AddAccountRequestPayload, ProfileResponse>>
export const addAccount = actionCreator.async<AddAccountRequestPayload, ProfileResponse, Error>('ADD_ACCOUNT')

export interface EditAccountRequestPayload {
	id: number
	customerId: number
	orderEmailOptIn?: boolean
	userId?: number
	defaultBranchId?: number
	name?: string
	accountRoleName?: Role
	registrationState?: AccountStatus.Approved | AccountStatus.Disabled | AccountStatus.Pending | AccountStatus.Deleted
	mobileNumber?: string
	email?: string
	firstName?: string
	lastName?: string
	isCodAccount: boolean
}
export type EditAccountRequestAction = Action<EditAccountRequestPayload>
export type EditAccountRequestSuccessAction = Action<Success<EditAccountRequestPayload, UserAccount>>
export const editAccount = actionCreator.async<EditAccountRequestPayload, UserAccount, Error>('EDIT_ADMIN_ACCOUNT')

export interface EditLoginIdPayload extends EditAccountRequestPayload {
	password?: PasswordUpdate
}
export type EditLoginIdAction = Action<EditLoginIdPayload>
// handles updating the account, including the login ID and password.
// created new action to directly log user out on success since tokens get invalidated.
// NOTE: this is a non-async action since we'll just hook into the success/failure to our existing actions (ie `editAccount` or `updateUserPassword`)
export const editLoginId = actionCreator<EditLoginIdPayload>('EDIT_LOGIN_ID')

export interface CreateAccountRequestPayload {
	customerId: number
	defaultBranchId: number
	name: string
	accountRoleName: Role
	// NB: registration state not needed as gets set to APPROVED by default
	mobileNumber?: string
	email?: string
	password?: string
	firstName: string
	lastName: string
}
export type CreateAccountRequestAction = Action<CreateAccountRequestPayload>
export const createAccount = actionCreator.async<CreateAccountRequestPayload, UserAccount, Error>('CREATE_ADMIN_ACCOUNT')

export type DeleteAccountRequestAction = Action<UserAccount>
export const deleteAccount = actionCreator.async<UserAccount, Response, Error>('DELETE_ADMIN_ACCOUNT')

export const addAccountSuccess = actionCreator('ADD_ACCOUNT_SUCCESS')

// clears any previous success/error message on redux when viewing a different account details
export const clearEditAccountState = actionCreator('CLEAR_EDIT_ACCOUNT_STATE')

export const navigateToAddAccount = actionCreator('NAVIGATE_TO_ADD_ACCOUNT')

export type UpdateAccountsEmailOptInAction = Action<AccountsEmailOptIn>
export const updateAccountsEmailOptIn = actionCreator.async<AccountsEmailOptIn, Response, Error>('UPDATE_ACCOUNTS_EMAIL_OPT_IN')

export const clearAccountsEmailOptInState = actionCreator('CLEAR_ACCOUNTS_EMAIL_OPT_IN_STATE')

export type GetAccountPrincipalEmailAction = Action<GetAccountPrincipalEmailRequest>
export const getAccountPrincipalEmail = actionCreator.async<GetAccountPrincipalEmailRequest, string, Error>('GET_ACCOUNT_PRINCIPAL_EMAIL')