/**
 * Action creators for authentication.
 */
import actionCreatorFactory, { Action, Success, Failure } from 'typescript-fsa'
import { PIN_TYPE } from '../cart/types'
import { LoginRequest, AuthToken } from './types'

const actionCreator = actionCreatorFactory('Auth')

export type LoginRequestSuccessAction = Action<Success<LoginRequest, AuthToken>>
export type LoginRequestFailureAction = Action<Failure<LoginRequest, Error>>
export const login = actionCreator.async<LoginRequest, AuthToken, Error>('LOGIN')

export const refreshedAuthToken = actionCreator<AuthToken>('REFRESHED_AUTH_TOKEN')

/** Signals that refreshing failed. The payload is the time that it failed. */
export const refreshAuthTokenFailed = actionCreator('REFRESH_AUTH_TOKEN_FAILED')

/** Action creator for the logout request. */
export interface LogoutRequest {
	fromPin?: boolean
}
export type LogoutRequestAction = Action<LogoutRequest>
export const logoutRequest = actionCreator<LogoutRequest>('LOGOUT_REQUEST')

/** The user has been logged out. */
export const loggedOut = actionCreator('LOGGED_OUT')

/** The user has started to log out but still has an auth token. */
export const preloggedOut = actionCreator('PRE_LOGGED_OUT')

/** An error has occurred while the user is logged in, either logging out or refreshing the token. */
export const loggedInError = actionCreator<Error>('LOGGED_IN_ERROR')

export interface PinCompletePayload {
	pin: string,
	type: PIN_TYPE,
	goCheckoutAfter: boolean,
}

export type PinCompleteAction = Action<PinCompletePayload>

export const setPinComplete = actionCreator<PinCompletePayload>('SET_PIN_COMPLETE')

// payload determines whether to take the user to checkout screen afterwards
export interface SetPinPayload {
	goCheckoutAfter: boolean
}

export type SetPinAction = Action<SetPinPayload>
export const navigateToSetPin = actionCreator<SetPinPayload>('NAVIGATE_TO_SET_PIN')

export const navigateToResetPin = actionCreator('NAVIGATE_TO_RESET_PIN')

// payload determines whether to take the user to checkout screen afterwards
export interface RegisterPayload {
	mobileNumber?: string
	goCheckoutAfter: boolean
}
export type RegisterAction = Action<RegisterPayload>
export const navigateToRegister = actionCreator<RegisterPayload>('NAVIGATE_TO_REGISTER')

// payload determines whether to take the user to checkout screen afterwards
export interface ForgotPasswordPayload {
	mobileNumber?: string
	goCheckoutAfter: boolean
}
export type ForgotPasswordAction = Action<ForgotPasswordPayload>
export const navigateToForgotPassword = actionCreator<ForgotPasswordPayload>('NAVIGATE_TO_FORGOT_PASSWORD')

export const backSignInStack = actionCreator('BACK_SIGN_IN_STACK')
export const popTopSignInStack = actionCreator('POP_TOP_SIGN_IN_STACK')

export const clearAuthError = actionCreator('CLEAR_AUTH_ERROR')

export type SetBranchFilterPreferenceAction = Action<boolean>
export const setBranchFilterPreference = actionCreator.async<boolean, void, Error>('SET_BRANCH_FILTER_PREFERENCE')

export type DeleteSelfRequestSuccessAction = Action<Success<void, void>>
export const deleteSelf = actionCreator.async<void, void, Error>('DELETE_SELF')

export const navigateToTradeAccountApplication = actionCreator('NAVIGATE_TO_TRADE_ACCOUNT_APPLICATION')