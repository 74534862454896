import { SagaIterator } from 'redux-saga'
import { put, select, takeEvery } from 'redux-saga/effects'

import * as accountActions from '../../common/accounts/actions'
import * as cartActions from '../../common/cart/actions'
import { AccountStatus, UserAccount } from '../accounts/types'
import { orderBranchSelector, selectedAccountSelector } from './selectors'

function* handleUpdateSelectedAccountForOrder(action: accountActions.GetAccountsRequestSuccessAction): SagaIterator {
	const accounts = action.payload.result.accounts
	// we will only allow approved accounts to be selected, therefore, we need to re-validate the selected account when accounts are updated
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	let selectedAccountValidated: UserAccount | undefined

	if (accounts && accounts.length > 0) {
		// find the current account selected so we can check if it's still approved
		const newSelectedAccount: UserAccount | undefined = selectedAccount && accounts.find(item => item.id === selectedAccount.id)
		if (newSelectedAccount && newSelectedAccount.registrationState === AccountStatus.Approved) {
			selectedAccountValidated = newSelectedAccount
		} else {
			// assign an approved account as the selected account
			selectedAccountValidated = accounts.find(item => item.registrationState === AccountStatus.Approved)
		}
	}

	if (selectedAccountValidated) {
		// only update the selected account in the store if different from the current one
		if (!selectedAccount || selectedAccount.id !== selectedAccountValidated.id) {
			yield put(cartActions.accountSelected(selectedAccountValidated))
		}
	} else {
		yield put(cartActions.clearAccountSelected())
	}
}

/**
 * Handles populating the order branch, if empty, when a new account gets selected.
 * @param action the action containing the selected account
 */
function* handlePopulateBranch(action: cartActions.AccountSelectedAction): SagaIterator {
	const orderBranch = yield select(orderBranchSelector)
	if (!orderBranch) {
		const accountDefaultBranch = action.payload.defaultBranchId
		if (accountDefaultBranch) {
			yield put(cartActions.branchSelected(accountDefaultBranch.toString()))
		}
	}
}

export default function* (): SagaIterator {
	yield takeEvery(accountActions.getAccounts.done, handleUpdateSelectedAccountForOrder)
	yield takeEvery(cartActions.accountSelected, handlePopulateBranch)
}